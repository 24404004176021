import React, { useEffect, useState } from 'react';
import Bugsnag from '@bugsnag/js';
import {
    AlertBox,
    AlertBoxDismissButton,
    Box,
    FileInputLabel,
    FlexBox,
    Icon,
    Spinner,
    FileInput as SwanFileInput,
    Typography,
    tokens,
} from '@vp/swan';
import styled from 'styled-components';
import { useFileUploader, useIdentityContext } from '@99designs/design-services-common';
import { CollaborationFile } from '@99designs/graph-utils/types';
import { __ } from '@99designs/i18n';
import { useCreateRevisionRequestFileMutation } from '../RequestRevision.generated';

export interface UploadAreaProps {
    revisionRequestId: number;
    addUploadedFile: (file: CollaborationFile) => void;
    uploadStarted: () => void;
    uploadCompleted: () => void;
    uploadWithDropzone?: boolean;
}

const MAX_FILE_SIZE = 1000000000; // 1GB

const allowedFileTypes = [
    '.jpg',
    '.jpeg',
    '.jfif',
    '.bmp',
    '.png',
    '.gif',
    '.heic',
    '.svg',
    '.pdf',
];

const DropZone = styled(FlexBox)`
    background-color: ${tokens.SwanBaseColorGrey100};
    border-radius: ${tokens.SwanSemSpace3};
    border: 1px solid ${tokens.SwanSemColorBorderStandard};
    padding: ${tokens.SwanSemSpace5};
    cursor: pointer;
    transition: background-color 0.3s ease;
    &:hover {
        background-color: ${tokens.SwanBaseColorGrey300};
    }
`;

const FileInput = styled.input`
    display: none;
`;

const buildErrorMessage = () => {
    const allowedTypes = [...allowedFileTypes].map((ext) => ext.replace('.', ''));
    const lastFileType = allowedTypes.pop();
    const fileTypes = allowedTypes.join(', ');

    return __('Only {{fileTypes}} and {{lastFileType}} files under 1GB are supported', {
        fileTypes,
        lastFileType,
    });
};

const filterFiles = (files: FileList | null) => {
    if (!files) {
        return [];
    }

    return Array.from(files).filter((file) => {
        const fileExtension = file.name.split('.').pop()?.toLowerCase();
        return allowedFileTypes.includes(`.${fileExtension}`) && file.size < MAX_FILE_SIZE;
    });
};

export function UploadArea({
    revisionRequestId,
    addUploadedFile,
    uploadStarted,
    uploadCompleted,
    uploadWithDropzone = true,
}: UploadAreaProps) {
    const { accessToken } = useIdentityContext();
    const { error: uploadError, uploadFiles, loading } = useFileUploader(accessToken);
    const [error, setError] = useState<Error | null>(null);
    const [createRevisionRequestFile] = useCreateRevisionRequestFileMutation();

    useEffect(() => {
        if (uploadError) {
            setError(new Error(__(`File upload failed. Please try again.`)));
        }
    }, [uploadError]);

    useEffect(() => {
        if (loading) {
            uploadStarted();
        } else {
            uploadCompleted();
        }
    }, [loading, uploadCompleted, uploadStarted]);

    const handleFileChange = async (
        event: React.ChangeEvent<HTMLInputElement> | React.DragEvent<HTMLDivElement>
    ) => {
        event.preventDefault();
        event.stopPropagation();

        const files = 'dataTransfer' in event ? event.dataTransfer.files : event.target.files;

        const filteredFiles = filterFiles(files);

        if (files && files.length > 0) {
            // Check if some files were filtered out due to file type or size restrictions
            if (filteredFiles.length !== files.length) {
                setError(new Error(buildErrorMessage()));
                return;
            }

            // Clear any previous error if file size is within the limit
            setError(null);

            const uploadedFiles = await uploadFiles(filteredFiles);

            uploadedFiles.map((uploadedFile) => {
                createRevisionRequestFile({
                    variables: {
                        revisionRequestId: revisionRequestId,
                        externalUploadId: uploadedFile.id,
                        fileName: uploadedFile.name,
                    },
                })
                    .then((res) => {
                        if (res.data?.createRevisionRequestFile) {
                            addUploadedFile(res.data?.createRevisionRequestFile);
                        }
                    })
                    .catch((error) => {
                        Bugsnag.notify(error, (event) => {
                            event.addMetadata('createRevisionRequestFile', {
                                revisionRequestId: revisionRequestId,
                                externalUploadId: uploadedFile.id,
                            });
                        });
                        setError(new Error(__(`File upload failed. Please try again.`)));
                    });
            });
        }
    };

    return (
        <>
            {uploadWithDropzone ? (
                <UploadAsDropzone handleFileChange={handleFileChange} loading={loading} />
            ) : (
                <UploadAsButton handleFileChange={handleFileChange} loading={loading} />
            )}

            {error && (
                <AlertBox skin="error" marginBottom={'4'}>
                    <span data-testid="error-alert">{error.message}</span>
                    <AlertBoxDismissButton
                        accessibleText={__('Dismiss alert')}
                        onClick={() => setError(null)}
                    />
                </AlertBox>
            )}
        </>
    );
}

const UploadAsButton = ({
    handleFileChange,
    loading,
}: {
    handleFileChange: (
        event: React.ChangeEvent<HTMLInputElement> | React.DragEvent<HTMLDivElement>
    ) => Promise<void>;
    loading: boolean;
}) => {
    return (
        <SwanFileInput
            onChange={handleFileChange}
            type="file"
            accept={allowedFileTypes ? allowedFileTypes.join(', ') : ''}
            multiple
            data-testid="file-input-button"
        >
            <FileInputLabel skin="tertiary" iconPosition="left">
                <FlexBox gap="3" alignItems="center">
                    {loading ? (
                        <Spinner
                            size="tiny"
                            accessibleText={'Loading…'}
                            data-testid="upload-loading-spinner"
                        />
                    ) : (
                        <Icon iconType="upload" />
                    )}

                    <>{__('Upload image')}</>
                </FlexBox>
            </FileInputLabel>
        </SwanFileInput>
    );
};

const UploadAsDropzone = ({
    handleFileChange,
    loading,
}: {
    handleFileChange: (
        event: React.ChangeEvent<HTMLInputElement> | React.DragEvent<HTMLDivElement>
    ) => Promise<void>;
    loading: boolean;
}) => {
    return (
        <Box
            marginBottom={'5'}
            onDrop={handleFileChange}
            onDragOver={(event: React.DragEvent<HTMLDivElement>) => event.preventDefault()} // Necessary to allow drop
            data-testid="file-dropzone"
        >
            <label>
                <DropZone
                    justifyContent="center"
                    alignItems="center"
                    flexDirection="column"
                    gap={'3'}
                    paddingY={8}
                >
                    <FileInput
                        onChange={handleFileChange}
                        type="file"
                        accept={allowedFileTypes ? allowedFileTypes.join(', ') : ''}
                        multiple
                        data-testid="file-input"
                    />
                    {loading ? (
                        <Spinner
                            size="super"
                            accessibleText={'Loading…'}
                            data-testid="upload-loading-spinner"
                        />
                    ) : (
                        <>
                            <Icon iconType="upload" />
                            <Typography fontSize={'xsmall'} fontWeight="bold">
                                {__('Upload a file or image')}
                            </Typography>
                        </>
                    )}
                </DropZone>
                <Typography
                    fontSize="xsmall"
                    textColor="subtle"
                    paddingTop={'3'}
                    marginBottom={'5'}
                >
                    {__(
                        'Accepted file formats: jpg, jfif, bmp, png, gif, heic, svg and pdf under 1GB'
                    )}
                </Typography>
            </label>
        </Box>
    );
};
