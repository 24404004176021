import React from 'react';
import { Typography } from '@vp/swan';
import { FormattedReactMessage } from '@99designs/design-services-common';
import { __ } from '@99designs/i18n';

export function RemainingRevisions({ remainingRevisions }: { remainingRevisions: number }) {
    const singular = __('<Typography> {remainingRevisions} revision </Typography> left');
    const plural = __('<Typography> {remainingRevisions} revisions </Typography> left');
    return (
        <Typography fontSkin="body-small-bold" textColor="subtle">
            <FormattedReactMessage
                message={remainingRevisions === 1 ? singular : plural}
                replacements={{
                    Typography: (
                        <Typography as="span" fontSkin="body-small-bold" textColor="subtle" />
                    ),
                }}
                values={{ remainingRevisions }}
            />
        </Typography>
    );
}
