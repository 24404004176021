import { tokensRaw } from '@vp/swan';
import styled from 'styled-components';

const StyledSvg = styled.svg`
    width: 16px;
    height: 16px;
    path {
        fill: ${tokensRaw.SwanBaseColorGrey600};
    }
`;

/**
 * Inline SVG for the "Save as copy" icon.
 * This is required as the swan component loads the svg
 * in an image tag and the color cannot be modified.
 */
export const SaveAsCopy = () => (
    <StyledSvg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M5 5C5 4.44772 5.44772 4 6 4H12C12.5523 4 13 4.44772 13 5V13C13 13.5523 12.5523 14 12 14H6C5.44772 14 5 13.5523 5 13V5ZM12 5H6V13H12V5Z"
        />
        <path d="M10 2.5H4C3.72386 2.5 3.5 2.72386 3.5 3V11C3.5 11.2761 3.72386 11.5 4 11.5H10C10.2761 11.5 10.5 11.2761 10.5 11V3C10.5 2.72386 10.2761 2.5 10 2.5Z" />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M3 3C3 2.44772 3.44772 2 4 2H10C10.5523 2 11 2.44772 11 3V11C11 11.5523 10.5523 12 10 12H4C3.44772 12 3 11.5523 3 11V3ZM10 3H4V11H10V3Z"
        />
    </StyledSvg>
);
