import React, { useRef } from 'react';
import {
    Box,
    Button,
    FlexBox,
    Icon,
    Link,
    PipeSeparator,
    Section,
    Typography,
    tokens,
    tokensRaw,
    useScreenClass,
} from '@vp/swan';
import styled from 'styled-components';
import { __ } from '@99designs/i18n';
import { Drawer, DrawerContent, useDefaultDrawerProps } from '../Drawer';
import { LinkMenu } from '../LinkMenu/LinkMenu';
import { Status } from '../Status/Status';
import { VistaLogo } from '../VistaLogo/VistaLogo';

export interface TopbarProps {
    status: React.ComponentProps<typeof Status>;
    navUrl: string;
    navLabel: string;
    onLinkMenuChange?: () => void;
    menuProps: React.ComponentProps<typeof LinkMenu>;
    children?: React.ReactNode;
}

const TopbarOuter = styled.div`
    background-color: ${tokensRaw.SwanBaseColorWhite};
    border-bottom: 1px solid ${tokensRaw.SwanBaseColorGrey300};
    padding: ${tokens.SwanSemSpace6} ${tokens.SwanSemSpace7} ${tokens.SwanSemSpace6}
        ${tokens.SwanSemSpace5};
    width: 100%;
`;

const TopbarInner = styled.div`
    display: flex;
    justify-content: space-between;
`;

export const TopbarDesktop: React.FC<TopbarProps> = ({
    status,
    onLinkMenuChange,
    menuProps,
    navLabel,
    navUrl,
    children,
}) => {
    return (
        <Box compactMode>
            <TopbarOuter>
                <TopbarInner>
                    <FlexBox
                        alignItems="center"
                        gap="2"
                        flexWrap="wrap"
                        justifyContent="flex-start"
                    >
                        <Button
                            skin="tertiary"
                            render={(props) => (
                                <Link to={navUrl} className={props.className}>
                                    {props.children}
                                </Link>
                            )}
                        >
                            {navLabel}
                        </Button>
                        <Typography textColor={'subtle'}>—</Typography>
                        <LinkMenu
                            label={menuProps.label}
                            links={menuProps.links}
                            onChange={onLinkMenuChange}
                        />
                        <PipeSeparator />
                        <Status {...status} />
                    </FlexBox>
                    {children && <Section>{children}</Section>}
                </TopbarInner>
            </TopbarOuter>
        </Box>
    );
};

const ButtonContainer = styled.div`
    position: absolute;
    left: ${tokens.SwanSemSpace5};
    top: 50%;
    transform: translateY(-50%);
`;

const LogoContainer = styled.div`
    display: flex;
    align-items: center;
    padding: ${tokens.SwanSemSpace3};
`;

export const TopbarMobile: React.FC<TopbarProps> = ({ onLinkMenuChange, menuProps }) => {
    const drawerRef = useRef<HTMLDivElement>(null);
    const drawerProps = useDefaultDrawerProps(drawerRef);

    return (
        <>
            <FlexBox
                compactMode
                padding="5"
                justifyContent="center"
                style={{
                    position: 'relative',
                    borderBottomStyle: 'solid',
                    borderBottomWidth: tokens.SwanSemBorderWidthStandard,
                    borderBottomColor: tokens.SwanSemColorBorderStandard,
                }}
            >
                <ButtonContainer>
                    <Button
                        skin="tertiary"
                        buttonShape="round"
                        onClick={() => drawerProps.handleOpen?.()}
                        aria-label={__('Open main menu')}
                    >
                        <Icon iconType="menu" />
                    </Button>
                </ButtonContainer>
                <LogoContainer>
                    <Link
                        to="/"
                        skin="unstyled"
                        style={{ lineHeight: 0 }}
                        aria-label={__('Return to Home Page')}
                    >
                        <VistaLogo width="29" height="24" />
                    </Link>
                </LogoContainer>
            </FlexBox>
            <Drawer {...drawerProps} ref={drawerRef} isDraggable asPageOverlay zIndex={2000}>
                <DrawerContent aria-label={__('Main menu')} aria-hidden={!drawerProps.open}>
                    <FlexBox flexDirection="column" gap="2">
                        <Typography fontSkin="title-item">{__('Main menu')}</Typography>
                        <Box
                            as="ul"
                            margin="0"
                            paddingX="0"
                            paddingY="3"
                            style={{ listStyleType: 'none' }}
                        >
                            {menuProps.links.map((link) => (
                                <Box as="li" key={link.label} paddingY="3">
                                    <Button
                                        skin="unstyled"
                                        onClick={() => {
                                            onLinkMenuChange?.();
                                            window.location.pathname = link.path;
                                        }}
                                        width="full-width"
                                        iconPosition="left"
                                    >
                                        <FlexBox alignItems="center" gap="3">
                                            {link.iconType && <Icon iconType={link.iconType} />}
                                            {link.label}
                                        </FlexBox>
                                    </Button>
                                </Box>
                            ))}
                        </Box>
                    </FlexBox>
                </DrawerContent>
            </Drawer>
        </>
    );
};

export const Topbar: React.FC<TopbarProps> = (props) => {
    const screenClass = useScreenClass();
    if (screenClass === 'xs') {
        return <TopbarMobile {...props} />;
    }
    return <TopbarDesktop {...props} />;
};
