import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';
import { ErrorLoadingState } from '@99designs/collaborate-views';
import {
    ActivityPanel,
    useActionContext,
    useActivityPanelData,
    useHandleRequestRevision,
} from '@99designs/collaborate-views';
import { useCollaborationContext } from '@99designs/collaborate-views';
import { __ } from '@99designs/i18n';

export type ActivityRouteParams = {
    deliveryVersionId: string;
};

export const Activity = () => {
    const { collaborationId, latestVersion, revisionRequests, hasDesignLiveDelivery } =
        useCollaborationContext();
    const { actionOptions } = useActionContext();

    const {
        chatEvents,
        loading: activityLoading,
        error: activityError,
    } = useActivityPanelData({
        collaborationPublicId: collaborationId,
        maxRevisionRequests: revisionRequests.max,
        isDesignLive: hasDesignLiveDelivery,
    });

    const {
        handleRequestRevision,
        loading: updateRevisionLoading,
        error: updateRevisionError,
    } = useHandleRequestRevision(collaborationId, latestVersion.toString());

    const { pathname } = useLocation();
    const isActivityRoute = pathname.includes('/activity');

    return (
        <>
            <Helmet>
                <title>
                    {isActivityRoute
                        ? __('Vistaprint | Design Services Project Activity')
                        : __('Vistaprint | Design Services Project Design')}
                </title>
            </Helmet>
            <ErrorLoadingState
                loading={activityLoading || updateRevisionLoading}
                errors={activityError || updateRevisionError}
                data={chatEvents}
            >
                {(chatEvents) => (
                    <ActivityPanel
                        events={chatEvents}
                        requestRevision={actionOptions.requestRevision}
                        onRequestRevisionClick={handleRequestRevision}
                        approve={actionOptions.approve}
                        isActivityRoute={isActivityRoute}
                    />
                )}
            </ErrorLoadingState>
        </>
    );
};
