import { PropsWithChildren, useState } from 'react';
import { Box, Column, GridContainer, Icon, ResponsiveImage, Row, Typography } from '@vp/swan';
import styled from 'styled-components';
import { PreviewModal, PreviewModalVariant, download } from '@99designs/design-services-common';
import { StructuredBriefFile } from '@99designs/graph-utils/types';
import { __ } from '@99designs/i18n';
import { FileCTA, IconContainer, ImageContainer } from './FilesField';

const StyledResponsiveImage = styled(ResponsiveImage)`
    object-fit: contain;
    cursor: zoom-in;
    width: 100%;

    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    max-width: 100%;
    max-height: 100%;
`;

interface FilesFieldViewProps {
    filesValue: StructuredBriefFile[];
}

export function View(props: FilesFieldViewProps) {
    const [isOpen, setIsOpen] = useState(false);
    const [currentSlide, setCurrentSlide] = useState(0);

    const referenceFiles = props.filesValue.map((value) => {
        return {
            url: value.downloadUrl,
            filename: value.name,
            previewable: value.previewable,
        };
    });
    const previewableFiles = referenceFiles.filter((file) => file.previewable);
    const unpreviewableFiles = referenceFiles.filter((file) => !file.previewable);

    if (referenceFiles.length === 0) {
        return null;
    }

    return (
        <>
            <PreviewModal
                isOpen={isOpen}
                setIsOpen={setIsOpen}
                currentSlide={currentSlide}
                setCurrentSlide={setCurrentSlide}
                previewFiles={previewableFiles}
                variant={PreviewModalVariant.REFERENCE_FILE}
                onDownload={(file) => download(file.url, file.filename ?? 'file')}
            />
            <GridContainer>
                <Row>
                    {previewableFiles.map((previewableFile, i) => (
                        <FileDisplayWrapper
                            name={previewableFile.filename}
                            url={previewableFile.url}
                        >
                            <StyledResponsiveImage
                                src={previewableFile.url}
                                role="button"
                                tabIndex={0}
                                onClick={() => {
                                    setCurrentSlide(i);
                                    setIsOpen(!isOpen);
                                }}
                            />
                        </FileDisplayWrapper>
                    ))}
                    {unpreviewableFiles.map((unpreviewableFile) => (
                        <FileDisplayWrapper
                            name={unpreviewableFile.filename}
                            url={unpreviewableFile.url}
                        >
                            <IconContainer>
                                <Icon iconType="fileGeneric" size="32p" marginBottom={'2'} />

                                <Typography fontSize="xsmall" fontWeight="bold" marginBottom={'2'}>
                                    {__('Preview unavailable')}
                                </Typography>
                            </IconContainer>
                        </FileDisplayWrapper>
                    ))}
                </Row>
            </GridContainer>
        </>
    );
}

interface FileDisplayWrapperProps extends PropsWithChildren {
    name?: string;
    url: string;
}

export function FileDisplayWrapper(props: FileDisplayWrapperProps) {
    return (
        <Column span={4} padding={'4'}>
            <ImageContainer bgc="strong">
                <FileCTA
                    onClick={() => download(props.url, props.name ?? 'file')}
                    buttonShape="round"
                    compactMode
                    skin="secondary"
                    aria-label="download"
                >
                    <Icon iconType="download" size="20p" />
                </FileCTA>

                {props.children}
            </ImageContainer>
            {props.name && (
                <Box paddingY={'3'}>
                    <Typography fontSize="xsmall">{props.name}</Typography>
                </Box>
            )}
        </Column>
    );
}
