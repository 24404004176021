/* eslint-disable */

import * as Apollo from '@apollo/client';
import { DocumentNode } from 'graphql';
import * as Types from '@99designs/graph-utils/types';

export type SubmitRevisionRequestMutationVariables = Types.Exact<{
    revisionRequestId: Types.Scalars['Int'];
}>;

export type SubmitRevisionRequestMutation = {
    __typename: 'Mutation';
    submitRevisionRequest: {
        __typename: 'RevisionRequest';
        id: number;
        state: Types.RevisionRequestState;
    };
};

export const SubmitRevisionRequestDocument: DocumentNode = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'SubmitRevisionRequest' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'revisionRequestId' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'submitRevisionRequest' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'revisionRequestId' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'revisionRequestId' },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'state' } },
                            ],
                        },
                    },
                ],
            },
        },
    ],
};
export type SubmitRevisionRequestMutationFn = Apollo.MutationFunction<
    SubmitRevisionRequestMutation,
    SubmitRevisionRequestMutationVariables
>;

/**
 * __useSubmitRevisionRequestMutation__
 *
 * To run a mutation, you first call `useSubmitRevisionRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitRevisionRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitRevisionRequestMutation, { data, loading, error }] = useSubmitRevisionRequestMutation({
 *   variables: {
 *      revisionRequestId: // value for 'revisionRequestId'
 *   },
 * });
 */
export function useSubmitRevisionRequestMutation(
    baseOptions?: Apollo.MutationHookOptions<
        SubmitRevisionRequestMutation,
        SubmitRevisionRequestMutationVariables
    >
) {
    return Apollo.useMutation<
        SubmitRevisionRequestMutation,
        SubmitRevisionRequestMutationVariables
    >(SubmitRevisionRequestDocument, baseOptions);
}
export type SubmitRevisionRequestMutationHookResult = ReturnType<
    typeof useSubmitRevisionRequestMutation
>;
export type SubmitRevisionRequestMutationResult =
    Apollo.MutationResult<SubmitRevisionRequestMutation>;
export type SubmitRevisionRequestMutationOptions = Apollo.BaseMutationOptions<
    SubmitRevisionRequestMutation,
    SubmitRevisionRequestMutationVariables
>;
