import React, { forwardRef } from 'react';

/**
 * `DrawerFoldLine` is a component to be used within a `Drawer` to define a dynamic fold line.
 * The fold line serves as a reference point for draggable behavior in the drawer.
 *
 * Content positioned above the fold line remains always visible, while content
 * positioned below the fold line can be hidden or shown based on the drawer's state.
 * This allows for a seamless user experience where certain content is always
 * accessible, while additional content is revealed or concealed as the drawer
 * is dragged up or down.
 */
export const DrawerFoldLine = forwardRef<HTMLDivElement, React.HTMLProps<HTMLDivElement>>(
    (props, ref) => {
        return <div ref={ref} {...props} />;
    }
);
