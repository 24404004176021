import React, { useRef } from 'react';
import { Box, Button, Typography } from '@vp/swan';
import { Drawer, DrawerContent, DrawerProps } from '@99designs/design-services-layouts';
import { __ } from '@99designs/i18n';

export interface VersionDrawerProps extends Omit<DrawerProps, 'children'> {
    revisionsTotal: number;
    setVersion: (version: number) => void;
    version: number;
}

export const VersionDrawer: React.FC<VersionDrawerProps> = ({
    revisionsTotal,
    setVersion,
    version,
    ...props
}) => {
    const drawerRef = useRef<HTMLDivElement>(null);
    const versions = Array.from({ length: revisionsTotal }, (_, index) => 1 + index);

    return (
        <Drawer {...props} ref={drawerRef} isDraggable asPageOverlay zIndex={2000}>
            <DrawerContent aria-label="Select a version">
                <Box paddingY="3">
                    <Typography fontSkin="body-small-bold">{__('Versions')}</Typography>
                </Box>
                <Box as="ul" margin="0" padding={0} style={{ listStyleType: 'none' }}>
                    {versions.map((version) => (
                        <Box as="li" key={version} paddingY="3">
                            <Button
                                skin="unstyled"
                                onClick={() => {
                                    setVersion(version);
                                    props.handleClose?.();
                                }}
                                data-testid={`version-button-${version}`}
                                width="full-width"
                            >
                                {__('Version {{version}}', { version })}
                            </Button>
                        </Box>
                    ))}
                </Box>
            </DrawerContent>
        </Drawer>
    );
};
