import { Typography } from '@vp/swan';
import styled from 'styled-components';

export const TruncateText = styled(Typography)<{ $maxLines?: number; $maxHeight?: string }>`
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: ${({ $maxLines }) => $maxLines ?? 1}; // Limit to specified lines
    max-height: ${({ $maxHeight }) =>
        $maxHeight ? `${$maxHeight}px` : '24px'}; // Line-height value * number of lines
`;
