import * as React from 'react';
import { useState } from 'react';
import { GridContainer, Row } from '@vp/swan';
import {
    PreviewModal,
    PreviewModalVariant,
    getVariantUrl,
} from '@99designs/design-services-common';
import { CollaborationFile } from '@99designs/graph-utils/types';
import { getFileDownload } from '../../../util/getFileDownload';
import { UploadedFile } from './UploadedFile';

export const UploadedFileGrid = ({
    files,
    onDelete,
    showDownload,
}: {
    files: CollaborationFile[];
    onDelete?: (file: CollaborationFile) => void;
    showDownload?: boolean;
}) => {
    const [isOpen, setIsOpen] = useState(false);
    const [currentSlide, setCurrentSlide] = useState(0);

    return (
        <>
            <PreviewModal
                isOpen={isOpen}
                setIsOpen={setIsOpen}
                currentSlide={currentSlide}
                setCurrentSlide={setCurrentSlide}
                previewFiles={files.map((file) => {
                    return {
                        url: getVariantUrl(file.externalUploadId, 'preview'),
                        filename: getVariantUrl(file.externalUploadId, 'original'),
                    };
                })}
                variant={PreviewModalVariant.REFERENCE_FILE}
                onDownload={() => getFileDownload(files[currentSlide])}
            />
            <GridContainer>
                <Row>
                    {files.map((file) => (
                        <UploadedFile
                            key={file.id}
                            data-testid={'file-' + file.id}
                            file={file}
                            onDelete={onDelete}
                            showDownload={showDownload}
                            onClick={(file) => {
                                setCurrentSlide(files.indexOf(file));
                                setIsOpen(true);
                            }}
                        />
                    ))}
                </Row>
            </GridContainer>
        </>
    );
};
