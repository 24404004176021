import { createContext, useContext } from 'react';
import { StyleFontSize } from '@vp/swan';

export type FieldViewSkinType = 'small' | 'standard';

interface FieldViewSkin {
    fontSize: StyleFontSize;
}

export const SmallSkin: FieldViewSkin = {
    fontSize: 'small',
};

export const StandardSkin: FieldViewSkin = {
    fontSize: 'standard',
};

export const FieldViewSkins: Record<FieldViewSkinType, FieldViewSkin> = {
    small: SmallSkin,
    standard: StandardSkin,
};

export const FieldViewSkinContext = createContext<FieldViewSkin>(SmallSkin);

export const useFieldViewSkinContext = () => {
    const context = useContext(FieldViewSkinContext);
    return context ? context : SmallSkin;
};

export const useFieldViewSkin = (type?: FieldViewSkinType): FieldViewSkin => {
    return type ? FieldViewSkins[type] : SmallSkin; // By default, use the small skin; existing callers that don't pass in a skin prop will not be changed.
};
