import React from 'react';
import formatHTMLMessage from './formatHTMLMessage';
import { Values } from './formatMessage';

interface FormattedHTMLMessageProps {
    message: string;
    values?: Values;
}
export function FormattedHTMLMessage(props: FormattedHTMLMessageProps) {
    const html = { __html: formatHTMLMessage(props.message, props.values) };
    return <span dangerouslySetInnerHTML={html} />;
}
