import React, { ReactNode, createContext, useContext, useState } from 'react';
import { BriefProductFragment } from '../BriefForm/brief.generated';

export type NormalisedBriefProduct = Omit<
    BriefProductFragment,
    'fulfilmentStrategy' | 'name' | '__typename'
>;

interface BriefContextProps {
    product?: NormalisedBriefProduct;
    updateProduct: (product?: NormalisedBriefProduct) => void;
}

export const BriefContext = createContext<BriefContextProps | undefined>(undefined);

interface BriefProviderProps {
    children: ReactNode;
}

export const BriefProvider: React.FC<BriefProviderProps> = ({ children }) => {
    const [product, setProduct] = useState<NormalisedBriefProduct>();

    const updateProduct = (newProduct?: NormalisedBriefProduct) => {
        setProduct(newProduct);
    };

    return (
        <BriefContext.Provider value={{ product, updateProduct }}>{children}</BriefContext.Provider>
    );
};

export const useBriefContext = () => {
    const context = useContext(BriefContext);

    if (!context) {
        throw new Error('useBriefContext must be used within a BriefProvider');
    }

    return context;
};
