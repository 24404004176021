/* eslint-disable */

import * as Apollo from '@apollo/client';
import { DocumentNode } from 'graphql';
import * as Types from '@99designs/graph-utils/types';

export type AvailableDatesQueryVariables = Types.Exact<{
    locale: Types.Scalars['String'];
    timezone: Types.Scalars['String'];
    calendarId?: Types.Maybe<Types.Scalars['Int']>;
}>;

export type AvailableDatesQuery = {
    __typename: 'Query';
    designLiveAvailableDates: {
        __typename: 'Dates';
        dates: Array<{ __typename: 'AppointmentDate'; date: any; isAvailable: boolean }>;
    };
};

export const AvailableDatesDocument: DocumentNode = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'AvailableDates' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'locale' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'timezone' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'calendarId' } },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'designLiveAvailableDates' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'locale' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'locale' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'timezone' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'timezone' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'calendarId' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'calendarId' },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'dates' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'date' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'isAvailable' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
};

/**
 * __useAvailableDatesQuery__
 *
 * To run a query within a React component, call `useAvailableDatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAvailableDatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAvailableDatesQuery({
 *   variables: {
 *      locale: // value for 'locale'
 *      timezone: // value for 'timezone'
 *      calendarId: // value for 'calendarId'
 *   },
 * });
 */
export function useAvailableDatesQuery(
    baseOptions: Apollo.QueryHookOptions<AvailableDatesQuery, AvailableDatesQueryVariables>
) {
    return Apollo.useQuery<AvailableDatesQuery, AvailableDatesQueryVariables>(
        AvailableDatesDocument,
        baseOptions
    );
}
export function useAvailableDatesLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<AvailableDatesQuery, AvailableDatesQueryVariables>
) {
    return Apollo.useLazyQuery<AvailableDatesQuery, AvailableDatesQueryVariables>(
        AvailableDatesDocument,
        baseOptions
    );
}
export type AvailableDatesQueryHookResult = ReturnType<typeof useAvailableDatesQuery>;
export type AvailableDatesLazyQueryHookResult = ReturnType<typeof useAvailableDatesLazyQuery>;
export type AvailableDatesQueryResult = Apollo.QueryResult<
    AvailableDatesQuery,
    AvailableDatesQueryVariables
>;
