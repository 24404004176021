import React from 'react';
import { tokensRaw } from '@vp/swan';
import styled, { CSSProperties } from 'styled-components';
import { NavigationLink } from '../Sidebar/Sidebar';
import { ToolbarButton } from '../ToolbarButton/ToolbarButton';

export const BottomBarContainer = styled.div<{ $zIndex?: CSSProperties['zIndex'] }>`
    display: flex;
    justify-content: space-around;
    align-items: center;
    background-color: #ffffff;
    border-top: 1px solid ${tokensRaw.SwanBaseColorGrey300};
    position: fixed;
    bottom: 0;
    width: 100%;
    padding: ${tokensRaw.SwanSemSpace3} 0;
    ${({ $zIndex }) => $zIndex && `z-index: ${$zIndex};`}
`;

export interface MenuLinks {
    menuButtonLabel: string;
    label: string;
    links: {
        label: string;
        path: string;
    }[];
}

export interface BottomBarProps {
    bottomBarLinks: NavigationLink[];
    onMenuLinkClick?: () => void;
    zIndex?: CSSProperties['zIndex'];
}

export const BottomBar: React.FC<BottomBarProps> = ({ bottomBarLinks, zIndex }: BottomBarProps) => {
    return (
        <BottomBarContainer $zIndex={zIndex}>
            {bottomBarLinks.map((link) => (
                <ToolbarButton
                    key={link.label}
                    onClick={link.onClick}
                    href={link.path}
                    icon={link.icon}
                    isActive={link.isActive}
                    openInNewTab={link.openInNewTab}
                >
                    {link.label}
                </ToolbarButton>
            ))}
        </BottomBarContainer>
    );
};
