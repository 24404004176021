import { Typography, tokens } from '@vp/swan';
import { __ } from '@99designs/i18n';
import { CollaborationEvent } from './CollaborationEvent';

export const CancelCollaborationEvent: React.FC = () => {
    return (
        <CollaborationEvent showAvatar={true} color={tokens.SwanSemColorBgError}>
            <Typography margin={'3'}>{__('This design service has been cancelled.')}</Typography>
        </CollaborationEvent>
    );
};
