import { FlexBox, P, StyleTextColor } from '@vp/swan';
import styled from 'styled-components';

interface AttributionProps {
    src: string;
    label: string;
    textColor?: StyleTextColor;
    justifyContent?: 'flex-start' | 'center' | 'flex-end';
    alignSelf?: 'flex-start' | 'center' | 'flex-end';
    darkMode?: boolean;
}

const Avatar = styled.img`
    border: 0.23em solid white;
    border-radius: 50%;
    margin-right: 0.8em;
    height: 32px;
    width: 32px;
`;

export function Attribution({
    src,
    label,
    textColor = 'standard',
    darkMode = false,
    justifyContent,
    alignSelf,
}: AttributionProps) {
    return (
        <FlexBox
            alignItems={'center'}
            fontSize="xsmall"
            justifyContent={justifyContent}
            darkMode={darkMode}
            style={{
                alignSelf: alignSelf,
            }}
        >
            <Avatar src={src} alt="" role="presentation" />
            <P textColor={textColor} margin={0} fontSkin="body-small-bold">
                {label}
            </P>
        </FlexBox>
    );
}
