import { Dropdown, DropdownOption } from '@vp/swan';
import { __ } from '@99designs/i18n';

export const DesignerCalendarSelector = ({
    designerCalendarId,
    setDesignerCalendarId,
    initialDesignerName,
    initialDesignerCalendarId,
}: {
    designerCalendarId: number;
    setDesignerCalendarId: (calendarId: number) => void;
    initialDesignerName: string;
    initialDesignerCalendarId: number;
}) => {
    return (
        <Dropdown
            fullWidth
            value={designerCalendarId}
            onChange={(e) => setDesignerCalendarId(parseInt(e.target.value, 10))}
        >
            <DropdownOption value={initialDesignerCalendarId}>{initialDesignerName}</DropdownOption>
            <DropdownOption value={0}>{__('Any designer')}</DropdownOption>
        </Dropdown>
    );
};
