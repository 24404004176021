import CryptoJS from 'crypto-js';

// Create a key from a string
export function createKey(s: string): string {
    const hash = generateHash(s);
    if (!hash) {
        return hash;
    }

    const slug = createSlug(s);
    return `${slug}-${hash}`; // Combine the slug and the hash to ensure uniqueness
}

// Generate a SHA-1 hash from the original string
export function generateHash(s: string): string {
    if (!s) {
        return '';
    }

    const hash = CryptoJS.SHA1(s).toString(CryptoJS.enc.Hex);
    return hash.slice(0, 8); // Use the first 8 characters of the hash
}

// Create a slug from a normalized string
export function createSlug(s: string): string {
    const normalized = normalizeString(s);
    let slug = '';

    for (const r of normalized) {
        if (isLetter(r) || isDigit(r)) {
            slug += r.toLowerCase();
        } else {
            slug += '-';
        }
    }

    slug = slug.replace(/-+/g, '-'); // Replace multiple hyphens with a single hyphen
    return slug.replace(/^-+|-+$/g, ''); // Remove the leading and trailing hyphens
}

/* 
    Normalize a string
    https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/String/normalize#:~:text=When%20applying%20compatibility,NFKC%22%2C%20respectively.
*/
function normalizeString(s: string): string {
    return s.normalize('NFKD');
}

// Check if a character is a letter
function isLetter(char: string): boolean {
    const code = char.charCodeAt(0);
    return (code >= 65 && code <= 90) || (code >= 97 && code <= 122); // A-Z and a-z
}

// Check if a character is a digit
function isDigit(char: string): boolean {
    const code = char.charCodeAt(0);
    return code >= 48 && code <= 57; // 0-9
}
