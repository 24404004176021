import React from 'react';
import { useSearchParams } from 'react-router-dom';
import { Box, Button, FlexBox, H3, Typography, useScreenClass } from '@vp/swan';
import { __ } from '@99designs/i18n';
import { PinComment as Comment } from '../PinComment/PinComment';
import { PinComment } from './PinComment';
import { PinCommentsEmptyState } from './PinCommentsEmptyState';

export interface PinCommentFormComponentProps {
    comments: Comment[];
}

export function PinCommentsFormComponent({ comments }: PinCommentFormComponentProps) {
    const screenClass = useScreenClass();
    const [_, setSearchParams] = useSearchParams();

    return (
        <FlexBox flexDirection="column" gap={'3'}>
            <Box>
                {screenClass === 'xs' ? (
                    <Button
                        onClick={() => {
                            setSearchParams({ activePanel: 'designs' });
                            window.scrollTo({ top: 0, behavior: 'smooth' });
                        }}
                        width="full-width"
                        skin="tertiary"
                    >
                        {__('Leave a comment on the design')}
                    </Button>
                ) : (
                    <FlexBox flexDirection="column" gap={'3'}>
                        <H3 fontSkin="title-subsection">{__('Leave comments on the design')}</H3>
                        {comments.length === 0 && <PinCommentsEmptyState />}
                    </FlexBox>
                )}
            </Box>
            {comments.length > 0 && (
                <Box>
                    {comments.map((comment, index) => (
                        <PinComment key={index} comment={comment} count={index + 1} />
                    ))}
                </Box>
            )}
        </FlexBox>
    );
}
