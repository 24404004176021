/* eslint-disable */

import * as Apollo from '@apollo/client';
import { DocumentNode } from 'graphql';
import * as Types from '@99designs/graph-utils/types';

export type CollaborationDeleteFileMutationVariables = Types.Exact<{
    fileId: Types.Scalars['Int'];
}>;

export type CollaborationDeleteFileMutation = {
    __typename: 'Mutation';
    collaborationDeleteFile: { __typename: 'GenericRepsonse'; success: boolean };
};

export const CollaborationDeleteFileDocument: DocumentNode = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'collaborationDeleteFile' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'fileId' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'collaborationDeleteFile' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'fileId' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'fileId' },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'success' } },
                            ],
                        },
                    },
                ],
            },
        },
    ],
};
export type CollaborationDeleteFileMutationFn = Apollo.MutationFunction<
    CollaborationDeleteFileMutation,
    CollaborationDeleteFileMutationVariables
>;

/**
 * __useCollaborationDeleteFileMutation__
 *
 * To run a mutation, you first call `useCollaborationDeleteFileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCollaborationDeleteFileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [collaborationDeleteFileMutation, { data, loading, error }] = useCollaborationDeleteFileMutation({
 *   variables: {
 *      fileId: // value for 'fileId'
 *   },
 * });
 */
export function useCollaborationDeleteFileMutation(
    baseOptions?: Apollo.MutationHookOptions<
        CollaborationDeleteFileMutation,
        CollaborationDeleteFileMutationVariables
    >
) {
    return Apollo.useMutation<
        CollaborationDeleteFileMutation,
        CollaborationDeleteFileMutationVariables
    >(CollaborationDeleteFileDocument, baseOptions);
}
export type CollaborationDeleteFileMutationHookResult = ReturnType<
    typeof useCollaborationDeleteFileMutation
>;
export type CollaborationDeleteFileMutationResult =
    Apollo.MutationResult<CollaborationDeleteFileMutation>;
export type CollaborationDeleteFileMutationOptions = Apollo.BaseMutationOptions<
    CollaborationDeleteFileMutation,
    CollaborationDeleteFileMutationVariables
>;
