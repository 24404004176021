/* eslint-disable */

export interface PossibleTypesResultData {
    possibleTypes: {
        [key: string]: string[];
    };
}
const result: PossibleTypesResultData = {
    possibleTypes: {
        EventContent: ['CreateDeliveryContent', 'RequestRevisionContent', 'AppointmentContent'],
        ContestOverviewBriefElement: [
            'ContestOverviewTextElement',
            'ContestOverviewTextAreaElement',
            'ContestOverviewMultipleChoiceImageElement',
            'ContestOverviewSlidersElement',
            'ContestOverviewFilesElement',
            'ContestOverviewChoiceElement',
        ],
        DynamicField: [
            'SelectChoiceField',
            'MultiSelectChoiceField',
            'GridChoiceField',
            'RadioChoiceField',
            'MultiChoiceField',
            'BudgetField',
            'DateField',
            'FilesField',
            'TextAreaField',
            'SquarespacePackageChoiceField',
            'LogoContestTierChoiceField',
            'ContestUpgradesChoiceField',
            'PrintServiceOptionsField',
            'InputField',
            'MultiInputField',
            'MultiGridChoiceField',
            'ClientConfiguredField',
            'WorkEntityField',
        ],
        RequestDataUnion: ['RevisionRequestData', 'PostRevisionRequestData'],
        ChatEventData: [
            'ChatEventMessageContent',
            'ChatEventFileMessageContent',
            'ChatEventExternalEventContent',
            'ChatEventMessageWithFilesContent',
        ],
        ExternalEvent: ['ProjectEvent'],
        IProduct: ['ProductFixedPriceProject', 'ProductContest', 'ProductQuotedProject'],
        ProjectEventContent: [
            'AuthoriseAvailableForProfileContent',
            'RevokeAvailableForProfileContent',
            'StartAutoFinishProjectTimerContent',
            'CancelAutoFinishProjectTimerContent',
            'AutoFinishProjectReminderContent',
            'DesignTransferAgreementSignedContent',
            'ProjectDescriptionCreatedContent',
            'ProjectCreatedContent',
            'ProjectMessageContent',
            'ProjectCancelledContent',
            'ProjectUncancelledContent',
            'ProjectUnfinishedContent',
            'ProjectFinishedContent',
            'ProjectLockedContent',
            'ProjectUnlockedContent',
            'ProjectTitleUpdatedContent',
            'ProjectDetailsEditedContent',
            'ProjectFileMessageCreatedContent',
            'ProjectFolderCreatedContent',
            'ProjectFolderUpdatedContent',
            'ProjectFolderDeletedContent',
            'ProjectFileUploadedContent',
            'ProjectFileStageChangesContent',
            'ProjectDeliverableFileUploadedContent',
            'ProjectMarkDeliverableFileContent',
            'ProjectUnmarkDeliverableFileContent',
            'NoDesignerResponseContent',
            'InvoiceCreatedContent',
            'InvoiceRevisionRequestedContent',
            'InvoiceUpdatedContent',
            'InvoiceRevisedContent',
            'InvoicePaidContent',
            'InvoicePaymentHeldContent',
            'InvoiceResentContent',
            'InvoiceExpiredContent',
            'InvoiceExpirationReminderContent',
            'InvoiceCancelledContent',
            'InvoiceRefundedContent',
            'InvoiceAutoReleaseTimerStartedContent',
            'InvoiceAutoReleaseTimerCancelledContent',
            'InvoiceItemReleaseRequestDeclinedContent',
            'SuggestProjectCompletionContent',
            'ProjectEventUnknown',
            'InvoiceItemReleaseRequestedContent',
            'InvoiceItemPaymentReleasedContent',
            'InvoicePaymentReleasedContent',
            'ProjectStageUpdatedContent',
            'ProjectBookInVistaCareConsultationContent',
            'ProjectDeliverableFinalFileUploadedContent',
        ],
        YourProjectsItem: ['YourProjectsItemProjectDraft', 'YourProjectsItemProject'],
        YourProjectsProjectDraftContextMessageActions: [
            'YourProjectsProjectDraftContextMessageActionsProjectDraft',
        ],
        YourProjectsProjectContextMessageActions: [
            'YourProjectsProjectContextMessageActionsCompletedProject',
            'YourProjectsProjectContextMessageActionsInvitedProject',
            'YourProjectsProjectContextMessageActionsStartedProject',
            'YourProjectsProjectContextMessageActionsLockedProject',
            'YourProjectsProjectContextMessageActionsCanceledProject',
        ],
        YourWorkItem: ['StandardWorkItem', 'OrderWorkItem'],
        ProjectContextMessageAndActions: [
            'ContextMessageAndActionsCompletedProject',
            'ContextMessageAndActionsLockedProject',
            'ContextMessageAndActionsStartedProject',
            'ContextMessageAndActionsInvitedProject',
        ],
        ContestContextMessageAndActions: [
            'ContextMessageAndActionsOnHoldContest',
            'ContextMessageAndActionsRefundedContest',
            'ContextMessageAndActionsLockedContest',
            'ContextMessageAndActionsOpenContest',
            'ContextMessageAndActionsSelectingFinalistContest',
            'ContextMessageAndActionsFinalRoundContest',
            'ContextMessageAndActionsSelectingWinnerContest',
            'ContextMessageAndActionsWonContestMultipleWinners',
            'ContextMessageAndActionsWonContestSingleWinner',
        ],
        OrderWorkSubItem: ['OrderWorkSubItemProject', 'OrderWorkSubItemContest'],
    },
};
export default result;
