import React from 'react';
import { Box, Typography, VisuallyHidden, tokens } from '@vp/swan';
import styled from 'styled-components';
import { __ } from '@99designs/i18n';

export const LOCAL_STORAGE_PINNED_COMMENTS_ONBOARDING = 'hasSeenPinnedCommentsOnboarding';
const Z_INDEX_ONBOARDING_OVERLAY = 5000;

const ClickAnywhere = styled(Box)`
    height: 100%;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: ${Z_INDEX_ONBOARDING_OVERLAY};
`;

const Tooltip = styled(Box)`
    background-color: ${tokens.SwanSemColorBgAccentStrongPaired};
    border-radius: ${tokens.SwanSemBorderRadiusAction};
    border: 1px solid ${tokens.SwanBaseColorWhite};
    left: 50%;
    min-width: 240px;
    position: absolute;
    top: 40%;
    transform: translate(-50%, -50%);

    // Circular pin
    &:after {
        background-color: ${tokens.SwanSemColorBgAccentStrongPaired};
        border-radius: 50%;
        border: solid 1px ${tokens.SwanBaseColorWhite};
        content: '';
        display: block;
        height: 30px;
        left: 50%;
        margin-top: ${tokens.SwanBaseSpace300};
        position: absolute;
        top: 100%;
        transform: translateX(-50%);
        width: 30px;
    }
`;

const TooltipArrow = styled.span`
    position: absolute;
    bottom: 100%;
    left: 50%;
    transform: translateX(-50%);
    margin-top: -2px; // Attach to body of tooltip
    top: 100%;
`;

const Backdrop = styled(Box)`
    background-color: rgba(0, 0, 0, 0.5);
    height: 100%;
    position: absolute;
    width: 100%;
`;

// Arrow is needed as an SVG as it has a border and a background fill.
const ArrowIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="26" height="14" viewBox="0 0 26 14" fill="none">
        <path
            d="M13.1707 12.5L1.17065 0.5H0.670654V0H25.6707V0.5H25.1707L13.1707 12.5Z"
            fill={tokens.SwanSemColorBgAccentStrongPaired}
        />
        <path
            d="M25.6707 0.5H25.1707L13.1707 12.5L1.17065 0.5H0.670654"
            stroke={tokens.SwanBaseColorWhite}
        />
    </svg>
);

export const PinnedCommentsOnboardingOverlay: React.FC = () => {
    const [hideOverlay, setHideOverlay] = React.useState(false);

    const handleClose = () => {
        localStorage.setItem(LOCAL_STORAGE_PINNED_COMMENTS_ONBOARDING, 'true');
        setHideOverlay(true);
    };

    if (hideOverlay) {
        return null;
    }

    return (
        <>
            <ClickAnywhere onClick={() => handleClose()}>
                <Backdrop />
                <Tooltip padding="6">
                    <Typography fontSkin="body-standard" textAlign="center">
                        {__('Tap anywhere on the design to leave a comment')}
                    </Typography>
                    <TooltipArrow>
                        <ArrowIcon />
                    </TooltipArrow>
                </Tooltip>
            </ClickAnywhere>
            <VisuallyHidden>
                <button onClick={() => handleClose()}>{__('Close overlay')}</button>
            </VisuallyHidden>
        </>
    );
};
