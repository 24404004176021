import React from 'react';
import { CollaborationFile } from '@99designs/graph-utils/types';
import { __ } from '@99designs/i18n';

export const useUploadFiles = ({ files }: { files: CollaborationFile[] }) => {
    const [uploadedFiles, setUploadedFiles] = React.useState<CollaborationFile[]>(files);
    const [isSubmitEnabled, setIsSubmitEnabled] = React.useState<boolean>(true);

    const addUploadedFile = (file: CollaborationFile) => {
        setUploadedFiles((uploadedFiles) => [file, ...uploadedFiles]);
    };
    const removeUploadedFile = (file: CollaborationFile) => {
        setUploadedFiles(
            uploadedFiles.filter((fileItem) => {
                return fileItem.id !== file.id;
            })
        );
    };
    const uploadStarted = () => {
        setIsSubmitEnabled(false);
    };
    const uploadCompleted = () => {
        setIsSubmitEnabled(true);
    };

    return {
        uploadedFiles,
        addUploadedFile,
        removeUploadedFile,
        uploadStarted,
        uploadCompleted,
        isSubmitEnabled,
    };
};
