import React from 'react';
import { Box, FlexBox, Typography } from '@vp/swan';
import {
    AppointmentEvent,
    CancelCollaborationEvent,
    RevisionDeliveryEvent,
    RevisionRequestEvent,
    StartProjectEvent,
} from '../ui';
import type { Event } from '../ui';

const DateAndMessageBubble: React.FC<{ date: string; children: React.ReactNode }> = ({
    date,
    children,
}) => {
    return (
        <FlexBox flexDirection={'column'} gap={'between-subsections'}>
            <Typography fontSkin="body-small-bold" textColor={'subtle'} textAlign={'center'}>
                {date}
            </Typography>
            {children}
        </FlexBox>
    );
};

export interface ChatAreaProps {
    events: Array<{ date: string; events: Event[] }>;
}

const EventComponent = ({ event }: { event: Event }) => {
    switch (event.type) {
        case 'startProject':
            return <StartProjectEvent {...event.data} />;
        case 'cancelCollaboration':
            return <CancelCollaborationEvent />;
        case 'revisionRequest':
            return <RevisionRequestEvent {...event.data} />;
        case 'revisionDelivery':
            return <RevisionDeliveryEvent {...event.data} />;
        case 'appointment':
            return <AppointmentEvent {...event.data} />;
        default:
            return null;
    }
};

export const ChatArea: React.FC<ChatAreaProps> = ({ events }) => {
    return (
        <FlexBox flexDirection={'column'} gap={'between-subsections'}>
            {events.map(({ date, events }) => (
                <DateAndMessageBubble key={date} date={date}>
                    {events.map((event, index) => {
                        return (
                            <Box key={index}>
                                <EventComponent event={event} />
                            </Box>
                        );
                    })}
                </DateAndMessageBubble>
            ))}
        </FlexBox>
    );
};
