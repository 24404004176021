import React, { useCallback } from 'react';
import { AlertBox, AlertBoxDismissButton, FlexBox } from '@vp/swan';
import { CollaborationFile } from '@99designs/graph-utils/types';
import { __ } from '@99designs/i18n';
import { UploadedFileGrid } from './UploadedFileGrid';
import { useCollaborationDeleteFileMutation } from './UploadedFiles.generated';

interface UploadedFilesProps {
    files: CollaborationFile[];
    removeUploadedFile: (file: CollaborationFile) => void;
}

export function UploadedFiles(props: UploadedFilesProps) {
    const { files, removeUploadedFile } = props;
    const [fileDeletionError, setFileDeletionError] = React.useState<Error | undefined>();

    // file deletion
    const [collaborationDeleteFile] = useCollaborationDeleteFileMutation();
    const handleDelete = useCallback(
        (file: CollaborationFile) => {
            collaborationDeleteFile({
                variables: {
                    fileId: file.id,
                },
            })
                .then(() => {
                    removeUploadedFile(file);
                })
                .catch(() => {
                    setFileDeletionError(new Error(__(`File deletion failed.`)));
                });
        },
        [collaborationDeleteFile, removeUploadedFile]
    );

    if (files.length <= 0) {
        return null;
    }

    return (
        <FlexBox flexDirection="column">
            {fileDeletionError && (
                <AlertBox skin="error" marginBottom={'4'}>
                    <span data-testid="error-alert">{fileDeletionError.message}</span>
                    <AlertBoxDismissButton
                        accessibleText={__('Dismiss alert')}
                        onClick={() => setFileDeletionError(undefined)}
                    />
                </AlertBox>
            )}
            <UploadedFileGrid files={files} onDelete={handleDelete} />
        </FlexBox>
    );
}
