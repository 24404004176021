import { useCallback, useState } from 'react';

type Position = {
    x: number;
    y: number;
};

// Hook that determines if an element was clicked without dragging
export function useClickWithoutDrag(threshold = 5) {
    const [dragging, setDragging] = useState(false);
    const [startPosition, setStartPosition] = useState<Position>({ x: 0, y: 0 });

    const handleMouseDown = useCallback((event: React.MouseEvent) => {
        setStartPosition({ x: event.clientX, y: event.clientY });
        setDragging(false);
    }, []);

    const handleMouseMove = useCallback(
        (event: React.MouseEvent) => {
            const movementX = Math.abs(startPosition.x - event.clientX);
            const movementY = Math.abs(startPosition.y - event.clientY);
            if (movementX > threshold || movementY > threshold) {
                setDragging(true);
            }
        },
        [startPosition, threshold]
    );

    return { handleMouseDown, handleMouseMove, dragging };
}
