import { useCallback, useEffect, useState } from 'react';
import { useMotionValue } from 'framer-motion';
import { DrawerProps } from './Drawer';

export const ANIMATION_DURATION_DELAY = 300; // in milliseconds

interface DrawerOptions {
    initialOpenState?: boolean; // drawer's default state when it renders
    collapsedStateOffset?: number; // this value will show how much of the drawer will always be visible when collapsed. If no value is set, the drawer will be fully dismissed from viewport
    foldLineReference?: React.RefObject<HTMLDivElement>; // to collapse at a certain point in the content. Ideal option if the drawer content does not have any conditional rendering of components
    onOpenCallback?: () => void; // callback function to be executed when drawer is opened
    onCloseCallback?: () => void; // callback function to be executed when drawer is closed
}

export const useDefaultDrawerProps = (
    drawerRef: React.RefObject<HTMLDivElement>,
    options?: DrawerOptions
): Omit<DrawerProps, 'children'> => {
    const [open, setOpen] = useState(options?.initialOpenState || false);
    const [pin, setPin] = useState({
        currentPin: open ? 0 : window.innerHeight, // current position of the drawer
        ceiling: 0, // the upper limit (top position) to which the drawer can be dragged
        floor: 0, // the lower limit (bottom position) to which the drawer can be dragged
        elasticTop: 0, // the elasticity factor when dragging the drawer upwards - 0 meaning no elasticity
        elasticBottom: 0.5, // the elasticity factor when dragging the drawer downwards - 0 meaning no elasticity
    });
    const y = useMotionValue(0);
    const { onOpenCallback, onCloseCallback } = options || {};

    const calculateHeight = useCallback(() => {
        if (drawerRef && 'current' in drawerRef && drawerRef.current) {
            const drawerBounds = drawerRef.current.getBoundingClientRect();
            const drawerHeight = drawerBounds.height;

            const dismissedDrawerOffset = drawerHeight - (options?.collapsedStateOffset || 0);

            let floor: number;

            if (options?.foldLineReference && options?.foldLineReference.current) {
                const markerHeightFromTop =
                    options?.foldLineReference.current.getBoundingClientRect().top;

                floor = window.innerHeight - markerHeightFromTop;
            } else {
                floor = dismissedDrawerOffset;
            }

            setPin((prev) => ({
                ...prev,
                floor,
                currentPin: open ? prev.ceiling : drawerHeight,
            }));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleDragging = () => {
        // for Dragging down
        if (y.get() - pin.currentPin >= 30) {
            setPin((prev) => ({
                ...prev,
                currentPin: pin.floor,
                elasticTop: 0,
                elasticBottom: 0,
            }));

            setOpen(false);

            setTimeout(() => {
                onCloseCallback?.();
            }, ANIMATION_DURATION_DELAY);
        }

        // for Dragging up
        if (y.get() - pin.currentPin <= -30) {
            setPin((prev) => ({
                ...prev,
                currentPin: pin.ceiling,
                elasticTop: 0,
                elasticBottom: 0.5,
            }));

            setOpen(true);

            setTimeout(() => {
                onOpenCallback?.();
            }, ANIMATION_DURATION_DELAY);
        }
    };

    const handleOpen = useCallback(() => {
        calculateHeight(); // Makes sure that drawer height is up to date before it opens (this is useful when the drawer content changes dynamically)

        setPin((prev) => ({
            ...prev,
            currentPin: pin.ceiling,
            elasticTop: 0,
            elasticBottom: 0.5,
        }));

        setOpen(true);

        setTimeout(() => {
            onOpenCallback?.();
        }, ANIMATION_DURATION_DELAY);
    }, [pin.ceiling, setOpen, onOpenCallback, calculateHeight]);

    const handleClose = useCallback(() => {
        calculateHeight();

        setPin((prev) => ({
            ...prev,
            currentPin: pin.floor,
            elasticTop: 0.5,
            elasticBottom: 0,
        }));

        setOpen(false);

        setTimeout(() => {
            onCloseCallback?.();
        }, ANIMATION_DURATION_DELAY);
    }, [pin.floor, setOpen, onCloseCallback, calculateHeight]);

    useEffect(() => {
        const drawer = drawerRef.current;

        if (!drawer) return;

        if (typeof ResizeObserver !== 'undefined') {
            const resizeObserver = new ResizeObserver(calculateHeight);

            resizeObserver.observe(drawer);

            calculateHeight();

            return () => {
                resizeObserver.unobserve(drawer);
            };
        } else {
            calculateHeight();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [calculateHeight, drawerRef.current]);

    return {
        pin,
        y,
        handleDragging,
        handleOpen,
        handleClose,
        refresherDrawerHeight: calculateHeight,
        open,
    };
};
