import { Dispatch, SetStateAction, createContext, useCallback, useContext, useMemo } from 'react';
import { CollaborationContextPermissions } from '../CollaborationContext/CollaborationContext';

export type ActionOptions = {
    approve: 'enabled' | 'disabled' | 'hidden';
    edit: 'enabled' | 'disabled' | 'hidden';
    requestRevision: 'enabled' | 'disabled' | 'hidden';
};

export const ActionContext = createContext<IActionContext | null>(null);

export type IActionContext = {
    actionOptions: ActionOptions;
    setOverriddenActionOptions: Dispatch<SetStateAction<ActionOptions | null>>;
    disableActions: () => void;
    enableActions: () => void;
};

type ActionProviderProps = {
    permissions: CollaborationContextPermissions;
    overriddenActionOptions: ActionOptions | null;
    setOverriddenActionOptions: Dispatch<SetStateAction<ActionOptions | null>>;
    children?: React.ReactNode;
};

export const ActionProvider = ({
    permissions,
    overriddenActionOptions,
    setOverriddenActionOptions,
    children,
}: ActionProviderProps) => {
    const defaultOptions: ActionOptions = useMemo(() => {
        return {
            approve: permissions.canApproveDesign ? 'enabled' : 'hidden',
            edit: permissions.canEditDesign ? 'enabled' : 'hidden',
            requestRevision: permissions.canRequestRevision ? 'enabled' : 'hidden',
        };
    }, [permissions]);

    const disableActions = useCallback(() => {
        setOverriddenActionOptions({
            approve: permissions.canApproveDesign ? 'disabled' : 'hidden',
            edit: permissions.canEditDesign ? 'disabled' : 'hidden',
            requestRevision: permissions.canRequestRevision ? 'disabled' : 'hidden',
        });
    }, [permissions, setOverriddenActionOptions]);

    const enableActions = useCallback(() => {
        setOverriddenActionOptions(null);
    }, [setOverriddenActionOptions]);

    const context: IActionContext = {
        actionOptions: overriddenActionOptions || defaultOptions,
        setOverriddenActionOptions: setOverriddenActionOptions,
        disableActions: disableActions,
        enableActions: enableActions,
    };

    return <ActionContext.Provider value={context}>{children}</ActionContext.Provider>;
};

export const useActionContext = () => {
    const context = useContext(ActionContext);
    if (!context) {
        throw new Error('useActionContext must be used within an ActionProvider');
    }
    return context;
};
