import { useTracking } from '@vp/react-tracking';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, FlexBox } from '@vp/swan';
import { __ } from '@99designs/i18n';
import { useActionContext, useCollaborationContext } from '../../../context';
import { useHandleRequestRevision, useVersion } from '../../../hooks';
import { useEditAction } from '../../../hooks/useEditAction';
import { useOrderAction } from '../../../hooks/useOrderAction';
import { useWorkEntity } from '../../../hooks/useWorkEntity';
import { ProductPreview } from '../ProductPreview';
import { COLLABORATE_BASE_PATH } from '../constants';

export const DrawerActionButtons = ({
    deliveryHasRevisionRequest,
}: {
    deliveryHasRevisionRequest?: boolean;
}) => {
    const { tracking } = useTracking();

    const [isPreviewOpen, setIsPreviewOpen] = useState(false);
    const { collaborationId, latestVersion, trackingProperties, locale } =
        useCollaborationContext();
    const { actionOptions } = useActionContext();
    const { onClickOrder } = useOrderAction();
    const { workRevisionId } = useWorkEntity();
    const editUrl = useEditAction();
    const { version } = useVersion(null);

    const {
        handleRequestRevision,
        loading: updateRevisionLoading,
        error: updateRevisionError,
    } = useHandleRequestRevision(collaborationId, latestVersion.toString());

    const navigate = useNavigate();

    return (
        <>
            <FlexBox gap="3" justifyContent="center" alignItems="center">
                {actionOptions.approve !== 'hidden' && (
                    <Button
                        width="full-width"
                        skin="primary"
                        disabled={actionOptions.approve === 'disabled'}
                        onClick={onClickOrder}
                    >
                        {__('Approve & order')}
                    </Button>
                )}

                {deliveryHasRevisionRequest ? (
                    <Button
                        width="full-width"
                        skin="secondary"
                        onClick={() => {
                            tracking.track('Collaboration Action Taken', {
                                type: 'View feedback',
                                ...trackingProperties,
                            });
                            navigate(
                                `${COLLABORATE_BASE_PATH}/${collaborationId}/delivery/${version}/revision`
                            );
                        }}
                    >
                        {__('View feedback')}
                    </Button>
                ) : (
                    actionOptions.requestRevision !== 'hidden' && (
                        <Button
                            width="full-width"
                            skin="secondary"
                            disabled={
                                actionOptions.requestRevision === 'disabled' ||
                                !!updateRevisionError
                            }
                            onClick={handleRequestRevision}
                            loadingShimmer={updateRevisionLoading}
                        >
                            {__('Request a revision')}
                        </Button>
                    )
                )}
            </FlexBox>

            <FlexBox gap="3" justifyContent="center" alignItems="center" marginTop="3">
                <>
                    {actionOptions.edit !== 'hidden' && (
                        <Button
                            width="full-width"
                            skin="secondary"
                            onClick={async () => {
                                tracking.track('Collaboration Action Taken', {
                                    type: 'Edit yourself',
                                    ...trackingProperties,
                                });
                                window.location.href = editUrl;
                            }}
                        >
                            {__('Edit yourself')}
                        </Button>
                    )}

                    {workRevisionId && (
                        <Button
                            width="full-width"
                            skin="secondary"
                            onClick={async () => {
                                tracking.track('Collaboration Action Taken', {
                                    type: 'Viewed preview',
                                    ...trackingProperties,
                                });
                                setIsPreviewOpen(true);
                            }}
                        >
                            {__('Preview')}
                        </Button>
                    )}
                </>
            </FlexBox>
            {isPreviewOpen && workRevisionId && (
                <ProductPreview
                    locale={locale}
                    workRevisionId={workRevisionId}
                    setIsPreviewOpen={setIsPreviewOpen}
                />
            )}
        </>
    );
};
