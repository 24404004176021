import { useTracking } from '@vp/react-tracking';
import { Link } from 'react-router-dom';
import { Divider, Link as SwanLink, Typography, tokens } from '@vp/swan';
import { FormattedReactMessage } from '@99designs/design-services-common';
import { __ } from '@99designs/i18n';
import { useCollaborationContext } from '../../../context';
import { getAnalyticsData } from '../../../util/getAnalyticsData';
import { COLLABORATE_BASE_PATH } from '../constants';
import { CollaborationEvent } from './CollaborationEvent';

export interface RevisionDeliveryEventProps {
    message: string;
    deliveryVersionId: number;
    maxRevisionRequests: number;
    collaborationPublicId: string;
}

export const RevisionDeliveryEvent = ({
    message,
    collaborationPublicId,
    deliveryVersionId,
    maxRevisionRequests,
}: RevisionDeliveryEventProps) => {
    const { trackingProperties } = useCollaborationContext();
    const { tracking } = useTracking();

    const remainingRevisions = maxRevisionRequests + 1 - deliveryVersionId;
    const hasRemainingRevisions = remainingRevisions > 0;
    const copyWithRemainingRevisions = __(
        'A new design is ready for review! Check out <RevisionLink>Version {deliveryVersionId}</RevisionLink>. 👉 Request a revision or approve and order to print your design.'
    );
    const copyWithoutRemainingRevisions = __(
        'A new design is ready for review! Check out <RevisionLink>Version {deliveryVersionId}</RevisionLink>. You have no revisions left. Approve and order to print your design or purchase a <NewDesignLink>new design service.</NewDesignLink>'
    );

    return (
        <CollaborationEvent
            alignmentDirection="left"
            color={tokens.SwanSemColorBgHelp}
            showAvatar={true}
        >
            {message && (
                <>
                    <Typography style={{ whiteSpace: 'pre-wrap' }}>
                        <i>{message}</i>
                    </Typography>
                    <Divider my={'5'} />
                </>
            )}
            <Typography>
                {hasRemainingRevisions ? (
                    <FormattedReactMessage
                        message={copyWithRemainingRevisions}
                        replacements={{
                            RevisionLink: (
                                <Link
                                    to={`${COLLABORATE_BASE_PATH}/${collaborationPublicId}/delivery/${deliveryVersionId}?activePanel=designs`}
                                />
                            ),
                        }}
                        values={{ deliveryVersionId }}
                    />
                ) : (
                    <FormattedReactMessage
                        message={copyWithoutRemainingRevisions}
                        replacements={{
                            NewDesignLink: (
                                <SwanLink
                                    onClick={() => {
                                        tracking.track('Navigation Clicked', {
                                            ...getAnalyticsData({
                                                collaborationPublicId,
                                                label: 'Content:Collaboration Activity event last revision',
                                                destinationPath:
                                                    '/graphic-design/brief/printed-product',
                                                ctaType: 'purchase-new-design-service',
                                                ctaValue: 'purchase a new design service',
                                            }),
                                            ...trackingProperties,
                                        });
                                        window.location.pathname =
                                            '/graphic-design/brief/printed-product';
                                    }}
                                />
                            ),
                            RevisionLink: (
                                <Link
                                    to={`${COLLABORATE_BASE_PATH}/${collaborationPublicId}/delivery/${deliveryVersionId}?activePanel=designs`}
                                />
                            ),
                        }}
                        values={{ deliveryVersionId }}
                    />
                )}
            </Typography>
        </CollaborationEvent>
    );
};
