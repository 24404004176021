import React from 'react';
import { tokens } from '@vp/swan';
import styled from 'styled-components';
import { FormattedHTMLMessage } from '@99designs/design-services-common';
import { __ } from '@99designs/i18n';
import { Landscape } from '../icons/Landscape';

// Serialize an SVG string to a data URL for CSS
const circleSvg = encodeURIComponent(
    `<svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle
          cx="6"
          cy="6"
          r="5.44809"
          fill="white"
          stroke="#151515"
          strokeWidth="1.10381"
      />
  </svg>`
);

const EmptyStatePanel = styled.div`
    width: 80%;
    height: 40%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 1px solid ${tokens.SwanBaseColorGrey900};
    position: relative;
    padding: ${tokens.SwanSemSpace5};

    &::before,
    &::after,
    & > div::before,
    & > div::after {
        content: '';
        position: absolute;
        width: 12px;
        height: 12px;
        background-image: url('data:image/svg+xml;charset=utf-8,${circleSvg}');
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
    }

    &::before {
        top: 0;
        left: 0;
        transform: translate(-50%, -50%);
    }

    &::after {
        top: 0;
        right: 0;
        transform: translate(50%, -50%);
    }

    & > div::before {
        bottom: 0;
        left: 0;
        transform: translate(-50%, 50%);
    }

    & > div::after {
        bottom: 0;
        right: 0;
        transform: translate(50%, 50%);
    }
`;

const CornerDiv = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
`;

const Message = styled.p`
    text-align: center;
    margin-top: ${tokens.SwanSemSpace5};
`;

const Layout = styled.div`
    display: flex;
    background-color: rgba(248, 248, 248, 0.8);
    flex-direction: column;
    height: 100%;
    align-items: center;
    justify-content: center;
    width: 100%;
`;

export interface EmptyCanvasProps {
    isDesignLive: boolean;
}

export const EmptyCanvas: React.FC<EmptyCanvasProps> = ({ isDesignLive }) => {
    const message = isDesignLive
        ? __(
              'Your designer is preparing for the DesignLive video session.<br /> Your delivered design will appear here after the session.'
          )
        : __("Your designer is preparing a design.<br />It will appear here when it's ready.");
    return (
        <Layout>
            <EmptyStatePanel>
                <CornerDiv />
                <Landscape />
                <Message>
                    <FormattedHTMLMessage message={message} values={{}} />
                </Message>
            </EmptyStatePanel>
        </Layout>
    );
};
