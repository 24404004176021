import React from 'react';
import { IconProps, Link, tokens } from '@vp/swan';
import styled from 'styled-components';
import { ToolbarButton } from '../ToolbarButton/ToolbarButton';
import { VistaLogo } from '../VistaLogo/VistaLogo';

export type NavigationLink = {
    icon: IconProps['iconType'];
    label: string;
    path?: string;
    onClick?: () => void;
    isActive?: boolean;
    openInNewTab?: boolean;
};
export interface SidebarProps {
    links: NavigationLink[];
}

const SidebarOuter = styled.div`
    border-right: 1px solid ${tokens.SwanBaseColorGrey300};
    height: 100%;
    width: 90px;
`;

const SidebarInner = styled.div`
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: ${tokens.SwanSemSpaceToActions};
    justify-content: center;
    padding: ${tokens.SwanSemSpace5};
`;

const LogoLink = styled(Link)`
    line-height: 0;
    margin-bottom: ${tokens.SwanSemSpace3};
`;

export const Sidebar: React.FC<SidebarProps> = ({ links }) => {
    return (
        <SidebarOuter>
            <SidebarInner>
                <LogoLink href="/" aria-label="Vistaprint">
                    <VistaLogo width="40" height="36" />
                </LogoLink>
                {links.map((link, index) => (
                    <ToolbarButton
                        href={link.path}
                        icon={link.icon}
                        isActive={link.isActive}
                        openInNewTab={link.openInNewTab}
                        onClick={link.onClick}
                        key={index}
                    >
                        {link.label}
                    </ToolbarButton>
                ))}
            </SidebarInner>
        </SidebarOuter>
    );
};
