import React from 'react';
import { Button, FlexBox, tokensRaw } from '@vp/swan';
import styled from 'styled-components';
import { __ } from '@99designs/i18n';
import { useStepContext } from '../context/StepContext';

const BottomBarContainer = styled.div`
    display: flex;
    flex-direction: column;
    background-color: ${tokensRaw.SwanBaseColorWhite};
    border-top: 1px solid ${tokensRaw.SwanBaseColorGrey100};
    box-shadow: 0 -1px 3px ${tokensRaw.SwanBaseColorBlackOpacity10};
    position: fixed;
    bottom: 0;
    z-index: ${tokensRaw.SwanSemZIndexOverlay};
    width: 100%;
`;

export const BottomBar = ({
    NextAction,
    PreviousAction,
}: {
    NextAction?: React.ComponentType;
    PreviousAction?: React.ComponentType;
}) => {
    const { currentStep, previous, next, totalSteps, validate } = useStepContext();
    return (
        <BottomBarContainer>
            <FlexBox alignItems="center" justifyContent="space-between" paddingY={4} paddingX={6}>
                {PreviousAction != null ? (
                    <PreviousAction />
                ) : (
                    <Button onClick={previous} disabled={currentStep === 0}>
                        {__('Back')}
                    </Button>
                )}
                {NextAction != null ? (
                    <NextAction />
                ) : (
                    <Button
                        onClick={async () => {
                            const isValid = await validate(currentStep);
                            if (currentStep === totalSteps - 1 || !isValid) {
                                return;
                            }

                            next();
                        }}
                        skin="primary"
                    >
                        {__('Next')}
                    </Button>
                )}
            </FlexBox>
        </BottomBarContainer>
    );
};
