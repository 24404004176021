import formatMessage, { Values } from './formatMessage';

const ESCAPED_CHARS: { [key: string]: string } = {
    '&': '&amp;',
    '>': '&gt;',
    '<': '&lt;',
    '"': '&quot;',
    "'": '&#x27;',
};

const UNSAFE_CHARS_REGEX = /[&><"']/g;

function escape(str: string) {
    return ('' + str).replace(UNSAFE_CHARS_REGEX, (match) => ESCAPED_CHARS[match]);
}

export default function formatHTMLMessage(message: string, values: Values = {}) {
    // Process all the values before they are used when formatting the ICU
    // Message string. Since the formatted message might be injected via
    // `innerHTML`, all String-based values need to be HTML-escaped.
    const escapedValues = Object.keys(values).reduce((escaped: Values, name) => {
        const value = values[name];
        escaped[name] = typeof value === 'string' ? escape(value) : value;
        return escaped;
    }, {});

    return formatMessage(message, escapedValues);
}
