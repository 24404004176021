import React from 'react';
import { FlexBox, Typography, tokens, tokensRaw } from '@vp/swan';
import styled from 'styled-components';
import { PinComment as Comment } from '../PinComment/PinComment';
import { useRevisionRequestQueryParams } from './useRevisionRequestQueryParams';

export interface PinCommentProps {
    comment: Comment;
    count: number;
}

export function PinComment(props: PinCommentProps) {
    const { pinClickHandler, commentId } = useRevisionRequestQueryParams(props.comment);

    return (
        <PinCommentContainer
            onClick={(e) => {
                pinClickHandler();
                // This prevents the "clickOutside" handler on the popover from firing.
                e.stopPropagation();
            }}
            active={props.comment.commentId === commentId}
        >
            <FlexBox gap="between-actions">
                <PinIndicator>{props.count}</PinIndicator>
                <Typography fontSkin="body-standard">{props.comment.commentText}</Typography>
            </FlexBox>
        </PinCommentContainer>
    );
}

const PinIndicator = styled.div`
    width: 20px;
    height: 20px;
    flex: none;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: ${tokensRaw.SwanSemFontSizeXsmall};
    font-weight: ${tokensRaw.SwanBaseFontWeightBold};
    background: ${tokensRaw.SwanSemColorBgStrong};
    border-radius: ${tokensRaw.SwanSemSpace6};
    outline: 4px solid transparent;
    transition: outline-color 0.15s ease-in, background-color 0.15s ease-in;
`;

const PinCommentContainer = styled.button<{ active: boolean }>`
    width: 100%;
    background: none;
    border: none;
    text-align: left;
    padding: ${tokens.SwanSemSpace5} 0;
    user-select: text;

    & ${PinIndicator} {
        outline-color: ${(p) => (p.active ? tokens.SwanBaseColorBlue100 : 'transparent')};
        background-color: ${(p) => (p.active ? tokensRaw.SwanSemColorBrandVistaBlue : undefined)};
    }

    &:hover ${PinIndicator} {
        background-color: ${(p) =>
            p.active ? tokens.SwanSemColorBrandVistaBlue : tokensRaw.SwanSemColorBgStrongHover};
        outline-color: ${(p) =>
            p.active ? tokens.SwanBaseColorBlue100 : tokensRaw.SwanSemColorBgStrong};
    }
`;
