import { __ } from '@99designs/i18n';
import { useWorkEntity } from './useWorkEntity';

export function useEditAction() {
    const { workEntity, workId } = useWorkEntity();
    if (workEntity != null) {
        return workEntity?.design.editURL;
    }
    return `/studio/?workId=${workId}`;
}
