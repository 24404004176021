import { createContext, useContext } from 'react';
import { VistaExperiment } from '../../types/VistaExperiment';

const VistaExperimentsContext = createContext<VistaExperiment[]>([]);

export const VistaExperimentsProvider = VistaExperimentsContext.Provider;
export const VistaExperimentsConsumer = VistaExperimentsContext.Consumer;

export function useGetVistaExperimentVariant(experimentKey: string): string | null {
    const experiments: VistaExperiment[] = useContext(VistaExperimentsContext);
    if (!experiments || experiments.length === 0) {
        return null;
    }
    const experiment = experiments.find((exp) => exp.experimentKey === experimentKey);
    if (!experiment) {
        console.warn(`Experiment with key ${experimentKey} not found`);
        return null;
    }
    return experiment.variationKey;
}
