import { FlexBox, StyleTextAlign, Typography, tokensRaw } from '@vp/swan';
import styled from 'styled-components';

interface IconTextPairProps {
    text: React.ReactNode;
    icon: React.ReactNode;
    textAlign?: StyleTextAlign;
}

const ResponsiveFlexBox = styled(FlexBox)`
    flex-direction: column;
    align-items: center;

    @media (max-width: ${tokensRaw.SwanBaseBreakpointXsEnd}) {
        flex-direction: row;
    }
`;

const IconWrapper = styled.div`
    @media (max-width: ${tokensRaw.SwanBaseBreakpointXsEnd}) {
        width: 80px;
        svg {
            width: inherit;
        }
    }
`;

export const IconTextPair = ({ text, icon, textAlign = 'center' }: IconTextPairProps) => {
    return (
        <ResponsiveFlexBox>
            <IconWrapper>{icon}</IconWrapper>
            <Typography
                mt={{ xs: 0, md: '6' }}
                ml={{ xs: 7, sm: '5', md: '5', lg: '5' }}
                textAlign={{ xs: 'left', md: textAlign }}
            >
                {text}
            </Typography>
        </ResponsiveFlexBox>
    );
};
