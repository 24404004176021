import React, { createContext, useContext, useReducer } from 'react';

type View<T extends string, D> = {
    view: T;
    data?: D;
};

interface State<T extends View<string, object>> {
    current: () => T | undefined;
    viewStack: T[];
}
type Action<T extends View<string, object>> =
    | { type: 'push'; view: T }
    | { type: 'pop' }
    | { type: 'update'; viewData: T['data'] };

const reducer = <T extends View<string, object>>(state: State<T>, action: Action<T>): State<T> => {
    switch (action.type) {
        case 'push':
            // if the new view is the same as the current view, we can just replace it
            if (state.viewStack[state.viewStack.length - 1].view === action.view.view) {
                state.viewStack.pop();
            }
            return { ...state, viewStack: [...state.viewStack, action.view] };
        case 'pop':
            return { ...state, viewStack: state.viewStack.slice(0, -1) };
        case 'update':
            state.viewStack[state.viewStack.length - 1].data = {
                ...state.viewStack[state.viewStack.length - 1].data,
                ...action.viewData,
            };
            return { ...state };
        default:
            return state;
    }
};

export const createViewContext = <T extends View<string, object>>() => {
    const ViewContext = createContext<State<T> | undefined>(undefined);
    const ViewDispatchContext = createContext<React.Dispatch<Action<T>> | undefined>(undefined);

    const ViewProvider = (props: { initialView: T; children?: React.ReactNode }) => {
        const [state, dispatch] = useReducer<React.Reducer<State<T>, Action<T>>>(reducer, {
            viewStack: [props.initialView],
            current: () => undefined,
        });

        return (
            <ViewContext.Provider
                value={{ ...state, current: () => state.viewStack[state.viewStack.length - 1] }}
            >
                <ViewDispatchContext.Provider value={dispatch}>
                    {props.children}
                </ViewDispatchContext.Provider>
            </ViewContext.Provider>
        );
    };

    const useViewNavigation = () => {
        const state = useContext(ViewContext);
        const dispatch = useContext(ViewDispatchContext);
        if (!state || !dispatch) {
            throw new Error('useViewNavigation must be used within a ViewProvider');
        }
        type CurrentViewDataType = T extends { data?: infer D } ? D : never;
        return {
            // gets the current view
            current: state.current(),
            // navigates to a new view, adding it to the stack
            navigateTo: (view: T) => dispatch({ type: 'push', view }),
            // navigates to the previous view, removing the current view from the stack
            goBack: () => dispatch({ type: 'pop' }),
            // updates the data of the current view
            updateCurrent: (viewData: Partial<CurrentViewDataType>) =>
                dispatch({ type: 'update', viewData }),
        };
    };

    return { ViewProvider, useViewNavigation };
};
