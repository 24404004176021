import React from 'react';
import { P, SwanStyleProps } from '@vp/swan';
import styled from 'styled-components';

const StyledP = styled(P)`
    white-space: pre-wrap;
`;

interface SubTextProps extends SwanStyleProps {
    children: React.ReactNode;
}

export function SubText({ children, ...props }: SubTextProps) {
    return (
        <StyledP {...props} fontSize="small" textColor="subtle">
            {children}
        </StyledP>
    );
}
