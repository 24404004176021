import { useTracking } from '@vp/react-tracking';
import { useNavigate } from 'react-router-dom';
import Bugsnag from '@bugsnag/js';
import { useCollaborationContext } from '../context';
import { COLLABORATE_BASE_PATH } from '../ui';
import { useGetOrCreateRevisionRequestMutation } from '../views';

export function useHandleRequestRevision(collaborationId: string, deliveryVersionId: string) {
    const { tracking } = useTracking();
    const { trackingProperties } = useCollaborationContext();
    const navigate = useNavigate();
    const [getOrCreateRevisionRequestMutation, { loading, error }] =
        useGetOrCreateRevisionRequestMutation();

    const handleRequestRevision = () => {
        getOrCreateRevisionRequestMutation({
            variables: {
                collaborationPublicId: collaborationId,
                deliveryVersionId: Number(deliveryVersionId),
            },
            onCompleted: () => {
                tracking.track('Collaboration Action Taken', {
                    type: 'Request a revision',
                    ...trackingProperties,
                });
                navigate(
                    `${COLLABORATE_BASE_PATH}/${collaborationId}/delivery/${deliveryVersionId}/revision?activePanel=designs`
                );
            },
            onError: (error) => {
                Bugsnag.notify(error, (event) => {
                    event.addMetadata('createRevisionRequest', {
                        collaborationId,
                    });
                });
            },
        });
    };

    return { handleRequestRevision, loading, error };
}
