import {
    Box,
    FlexBox,
    SelectionSet,
    SelectionSetInput,
    SelectionSetLabel,
    Typography,
    tokens,
    tokensRaw,
} from '@vp/swan';
import styled from 'styled-components';
import { __ } from '@99designs/i18n';
import { useWorkEntity } from '../../../hooks/useWorkEntity';
import { PinComment } from '../PinComment/PinComment';
import { ImageRef } from './ImageRef';
import { Thumbnail } from './ThumbnailImage';
import { useDeliveryQueryParams } from './useDeliveryQueryParams';

const Container = styled.div`
    position: absolute;
    bottom: 0;
    width: 100%;
    z-index: 1;
    backdrop-filter: blur(10px);
    background: ${tokens.SwanBaseColorGrey100};
`;

const Variations = styled.div`
    display: flex;
    gap: ${tokens.SwanSemSpaceBetweenActions};
    overflow-x: auto;

    padding: ${(props: { hasHeading: boolean }) =>
        props.hasHeading
            ? `${tokens.SwanSemSpace4} ${tokens.SwanSemSpace7} ${tokens.SwanSemSpace7}`
            : `${tokens.SwanSemSpace7}`};
`;

interface ThumbnailBarProps {
    images: { [key: number]: ImageRef[] };
    pins: PinComment[];
    deliveryVariation: number;
    setDeliveryVariationQueryParams: ReturnType<
        typeof useDeliveryQueryParams
    >['setDeliveryVariationQueryParams'];
}

export const ThumbnailBar = ({
    images,
    pins,
    setDeliveryVariationQueryParams,
    deliveryVariation,
}: ThumbnailBarProps) => {
    const { workEntity } = useWorkEntity();

    return (
        <ThumbnailBarUI
            images={images}
            pins={pins}
            setDeliveryVariationQueryParams={setDeliveryVariationQueryParams}
            deliveryVariation={deliveryVariation}
            workEntity={workEntity}
        />
    );
};

export const ThumbnailBarUI = ({
    images,
    pins,
    setDeliveryVariationQueryParams,
    deliveryVariation,
    workEntity,
}: ThumbnailBarProps & {
    workEntity: ReturnType<typeof useWorkEntity>['workEntity'];
}) => {
    const handleImageChange = (deliveryVariation: number) => {
        setDeliveryVariationQueryParams({ deliveryVariation });
    };
    const index = Object.keys(images).findIndex((key) => Number(key) === deliveryVariation);
    return (
        <SelectionSet variant="single-select">
            <Container>
                {workEntity?.product.name && (
                    <Box paddingX="7" paddingTop="7">
                        <Typography fontSkin="body-small">
                            <b>{__('Designs')}</b> — {`${workEntity.product.name} #${index + 1}`}
                        </Typography>
                    </Box>
                )}

                <Variations hasHeading={workEntity?.product.name != null}>
                    {Object.keys(images).map((key) => {
                        const id = Number(key);
                        const imgArr = images[id];
                        const hasComments =
                            pins.filter((p) => p.deliveryVariationId === id).length > 0;
                        return (
                            <Box key={id}>
                                <SelectionSetInput
                                    value={`${id}`}
                                    onClick={() => handleImageChange(id)}
                                >
                                    <SelectionSetLabel
                                        style={{
                                            padding: 0,
                                            margin: 0,
                                            height: 'auto',
                                        }}
                                    >
                                        <Thumbnail
                                            hasComments={hasComments}
                                            src={imgArr[0].href}
                                            alt={__(`Image {{title}}`, { title: imgArr[0].title })}
                                            size="74px"
                                        />
                                    </SelectionSetLabel>
                                </SelectionSetInput>
                            </Box>
                        );
                    })}
                </Variations>
            </Container>
        </SelectionSet>
    );
};

export const ThumbnailBarCompact = ({
    images,
    pins,
    setDeliveryVariationQueryParams,
}: ThumbnailBarProps) => {
    const handleImageChange = (deliveryVariation: number) => {
        setDeliveryVariationQueryParams({ deliveryVariation });
    };
    return (
        <SelectionSet variant="single-select">
            <FlexBox
                gap="between-actions"
                justifyContent="center"
                style={{
                    overflow: 'auto',
                    marginTop: `-${tokensRaw.SwanSemSpace3}`, // allow comment badge to overflow in container
                    paddingTop: tokensRaw.SwanSemSpace3, // allow comment badge to overflow in container
                }}
            >
                {Object.keys(images).map((key) => {
                    const id = Number(key);
                    const imgArr = images[id];
                    const hasComments = pins.filter((p) => p.deliveryVariationId === id).length > 0;
                    return (
                        <Box key={id}>
                            <SelectionSetInput
                                value={`${id}`}
                                onClick={() => handleImageChange(id)}
                            >
                                <SelectionSetLabel
                                    style={{
                                        padding: 0,
                                        margin: 0,
                                        height: 'auto',
                                    }}
                                >
                                    <Thumbnail
                                        hasComments={hasComments}
                                        src={imgArr[0].href}
                                        alt={__(`Image {{title}}`, { title: imgArr[0].title })}
                                        size="48px"
                                    />
                                </SelectionSetLabel>
                            </SelectionSetInput>
                        </Box>
                    );
                })}
            </FlexBox>
        </SelectionSet>
    );
};
