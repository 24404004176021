import React, { ReactNode, createContext, useContext } from 'react';
import { useSteps } from '../hooks/useSteps';

interface StepContextType {
    currentStep: number;
    totalSteps: number;
    validate: (step: number) => Promise<boolean>;
    next: () => void;
    previous: () => void;
}

const StepContext = createContext<StepContextType | undefined>(undefined);

interface StepProviderProps {
    children: ReactNode;
    totalSteps: number;
    validate: (step: number) => Promise<boolean>;
}

export const StepProvider: React.FC<StepProviderProps> = ({ children, totalSteps, validate }) => {
    const { currentStep, goToNextStep, goToPreviousStep } = useSteps(totalSteps, false);

    const value = {
        currentStep,
        next: goToNextStep,
        previous: goToPreviousStep,
        totalSteps,
        validate,
    };

    return <StepContext.Provider value={value}>{children}</StepContext.Provider>;
};

export const useStepContext = (): StepContextType => {
    const context = useContext(StepContext);
    if (context === undefined) {
        throw new Error('useStepContext must be used within a StepProvider');
    }
    return context;
};
