import { Helmet } from 'react-helmet';
import { Column, GridContainer, Row, Typography } from '@vp/swan';
import {
    BriefQuery,
    FieldDisplayWrapper,
    FieldView,
    fieldGroups,
} from '@99designs/feature-design-services-brief';
import { __ } from '@99designs/i18n';
import { BriefLayout } from './BriefLayout';

export const Brief = () => {
    return (
        <>
            <Helmet>
                <title>{__('Vistaprint | Design Services Project Brief')}</title>
            </Helmet>
            <BriefLayout>{(data) => <BriefUI {...data} />}</BriefLayout>
        </>
    );
};

/**
 * Brief UI component
 * This is a placeholder used for testing the brief data fetching
 */
export const BriefUI = ({
    data,
    collaborationId,
    groups,
}: {
    data: BriefQuery;
    collaborationId: string;
    groups: ReturnType<typeof fieldGroups>;
}) => {
    return (
        <GridContainer mt={9} mb={9}>
            <Row>
                <Column span={12} px={'5'}>
                    <Typography fontSkin="title-section" px={'5'} mb={'7'}>
                        {__('Your design project details')}
                    </Typography>
                    {collaborationId && (
                        <FieldDisplayWrapper skin="standard" title={__('Request ID')}>
                            <Typography>{collaborationId}</Typography>
                        </FieldDisplayWrapper>
                    )}
                    {data.brief.fulfilment && (
                        <FieldDisplayWrapper
                            skin="standard"
                            title={__('The product you selected for us to design')}
                        >
                            <Typography>{data.brief.fulfilment.name}</Typography>
                        </FieldDisplayWrapper>
                    )}
                    {data.brief.workName && (
                        <FieldDisplayWrapper
                            skin="standard"
                            title={__("The template you've started with")}
                        >
                            <Typography fontSize="small">{data.brief.workName}</Typography>
                        </FieldDisplayWrapper>
                    )}
                    {groups.map((group) => {
                        return group.fields.map((field) => {
                            return (
                                <FieldView
                                    skin="standard"
                                    key={field.id}
                                    field={field}
                                    colWidthOverride={6}
                                />
                            );
                        });
                    })}
                </Column>
            </Row>
        </GridContainer>
    );
};
