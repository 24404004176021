import { useTracking } from '@vp/react-tracking';
import React from 'react';
import { AlertBox, Dropdown, DropdownOption, FlexBox, Spinner } from '@vp/swan';
import { bugtracker } from '@99designs/design-services-common';
import { __ } from '@99designs/i18n';
import { useCollaborationContext } from '../../../context';
import { useDesignLiveAvailableTimesQuery } from './TimeSelector.generated';

export function TimeSelector({
    time,
    setTime,
    date,
    locale,
    timezone,
    calendarId,
}: {
    time: string;
    setTime: (time: string) => void;
    date: string;
    locale: string;
    timezone: string;
    calendarId?: number;
}) {
    const { tracking } = useTracking();
    const { trackingProperties, environmentForScheduler } = useCollaborationContext();

    const { data, loading, error } = useDesignLiveAvailableTimesQuery({
        variables: {
            date,
            locale,
            timezone,
            calendarId,
        },
        context: {
            headers: {
                Environment: environmentForScheduler,
            },
        },
        onError: (e) => {
            bugtracker.notify(`Failed fetching design live available times: ${e.message}`);
        },
    });

    if (loading) {
        return (
            <FlexBox alignItems="center" justifyContent="center" padding="3">
                <Spinner accessibleText={__('Loading')} />
            </FlexBox>
        );
    }

    if (!data || error) {
        return (
            <AlertBox skin="error">
                {__(
                    'There was an error fetching available times. Please refresh the page to try again.'
                )}
            </AlertBox>
        );
    }

    if (data.designLiveAvailableTimes.times.length === 0) {
        return (
            <AlertBox>{__('There are no available times. Please select another date.')}</AlertBox>
        );
    }

    return (
        <Dropdown
            data-testid="time-selector"
            fullWidth
            value={time}
            onChange={(e) => {
                setTime(e.target.value);
                tracking.track('DesignLive Rescheduler Edited', {
                    ...trackingProperties,
                    label: 'Appointment Time',
                    time: e.target.value,
                });
            }}
        >
            <DropdownOption key="" value="">
                {__('Select a time')}
            </DropdownOption>
            {data.designLiveAvailableTimes.times.map((t) => (
                <DropdownOption key={t.startTimeUTC} value={t.startTimeUTC}>
                    {formatTimeString(t.startTimeUTC)} - {formatTimeString(t.endTimeUTC)}
                </DropdownOption>
            ))}
        </Dropdown>
    );
}

function formatTimeString(datetime: string) {
    return new Date(datetime).toLocaleTimeString('default', {
        hour: 'numeric',
        minute: '2-digit',
        hourCycle: 'h12',
    });
}
