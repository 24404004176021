import { useCallback, useState } from 'react';
import { buildErrorAndNotify } from '../../lib/errors/buildError';

const CIMPRESS_DOCUMENTS_URL = 'https://uploads.documents.cimpress.io/v1/uploads';
const MCP_UPLOAD_URL = 'https://uploads.design.vpsvc.com/api/v1/uploads';
const TENANT = 'designtechprod';

type FileVariant = 'preview' | 'original';

export interface UploadedFile {
    id: string;
    name: string;
    originalFileSize: string;
    previewUrl: string;
    downloadUrl: string;
}

interface FileMetadata {
    uploadedFileName: string;
    uploadId: string;
    originalFileSize: string;
}

interface useFileUploaderHook {
    uploadFiles: (files: File[]) => Promise<UploadedFile[]>;
    deleteFile: (uploadId: string) => Promise<void>;
    getVariantUrl: (uploadId: string, fileVariant: FileVariant) => string;
    loading: boolean;
    error: Error | null;
}

// Get the URL for a file variant in the MCP bucket.
export function getVariantUrl(uploadId: string, fileVariant: FileVariant): string {
    return `${CIMPRESS_DOCUMENTS_URL}/${uploadId}/${fileVariant}?tenant=${TENANT}`;
}

export function useFileUploader(accessToken: string): useFileUploaderHook {
    const [uploadUrl, setUploadUrl] = useState<string | null>(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<Error | null>(null);

    const transformMetadataToUploadedFile = useCallback((file: FileMetadata): UploadedFile => {
        return {
            id: file.uploadId,
            name: file.uploadedFileName,
            originalFileSize: file.originalFileSize,
            previewUrl: getVariantUrl(file.uploadId, 'preview'),
            downloadUrl: getVariantUrl(file.uploadId, 'original'),
        };
    }, []);

    // Generate a signed URL for uploading files.
    const generateSignedUploadUrl = useCallback(
        async (fileType: 'pdf' | 'image'): Promise<string | null> => {
            const url = `${MCP_UPLOAD_URL}/postUrl${
                fileType === 'pdf' ? '?process=storeAsPdf' : ''
            }`;

            const response = await fetch(url, {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            });

            if (!response.ok) {
                throw new Error(
                    `Invalid response getting the upload URL: ${await response.text()}`
                );
            }

            const json = await response.json();
            setUploadUrl(json.postUrl);
            return json.postUrl;
        },
        [accessToken]
    );

    const sendFilesToUpload = async (url: string, files: File[]) => {
        const data = new FormData();
        files.forEach((file) => {
            data.append('file', file, file.name);
        });

        const response = await fetch(url, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${accessToken}`,
            },
            body: data,
        });

        if (!response.ok) {
            throw new Error(`Invalid response uploading file: ${await response.text()}`);
        }

        return response;
    };
    // Upload files to the signed URL.
    const uploadFiles = useCallback(
        async (files: File[]): Promise<UploadedFile[]> => {
            setLoading(true);

            let pdfUploadUrl, localUploadUrl;
            let pdfResponseFiles: FileMetadata[] = [];
            let nonPDFRespFiles: FileMetadata[] = [];

            // Separate PDF and non-PDF files
            const [pdfFiles, otherFiles] = files.reduce(
                ([pdfFiles, otherFiles], file) =>
                    file.name.toLowerCase().endsWith('.pdf')
                        ? [[...pdfFiles, file], otherFiles]
                        : [pdfFiles, [...otherFiles, file]],
                [[], []] as [File[], File[]]
            );

            try {
                // Fetch upload URLs
                if (pdfFiles.length > 0) {
                    pdfUploadUrl = await generateSignedUploadUrl('pdf');
                }
                if (otherFiles.length > 0) {
                    localUploadUrl = await generateSignedUploadUrl('image');
                }
            } catch (error) {
                const err = buildErrorAndNotify(
                    error,
                    'Failed to fetch upload URL for file upload'
                );
                setError(err);
                setLoading(false);
                return [];
            }

            try {
                // Upload PDF files
                if (pdfFiles.length > 0) {
                    if (!pdfUploadUrl) {
                        throw new Error(`Failed to fetch upload URL: '${pdfUploadUrl}'`);
                    }
                    const pdfResponse = await sendFilesToUpload(pdfUploadUrl, pdfFiles);
                    pdfResponseFiles = await pdfResponse.json();
                }

                // Upload other files
                if (otherFiles.length > 0) {
                    if (!localUploadUrl) {
                        throw new Error(`Failed to fetch upload URL: '${localUploadUrl}'`);
                    }
                    const response = await sendFilesToUpload(localUploadUrl, otherFiles);
                    nonPDFRespFiles = await response.json();
                }

                // Combine response files
                const combinedRespFiles = [...pdfResponseFiles, ...nonPDFRespFiles];

                // Transform metadata to uploaded files
                return combinedRespFiles.map(transformMetadataToUploadedFile);
            } catch (error) {
                const err = buildErrorAndNotify(error, 'Failed to upload file');
                setError(err);
                return [];
            } finally {
                setLoading(false);
            }
        },
        [accessToken, generateSignedUploadUrl, transformMetadataToUploadedFile, uploadUrl]
    );

    // Delete a file from the MCP bucket.
    async function deleteFile(uploadId: string) {
        try {
            const response = await fetch(`${MCP_UPLOAD_URL}/${uploadId}`, {
                method: 'DELETE',
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            });

            if (!response.ok) {
                throw new Error(`Invalid response deleting file: ${await response.text()}`);
            }
        } catch (e) {
            const err = buildErrorAndNotify(e, 'Failed to delete file');
            setError(err);
        }
    }

    return {
        loading,
        error,
        uploadFiles,
        deleteFile,
        getVariantUrl,
    };
}
