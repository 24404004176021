import React from 'react';
import { Box, H3 } from '@vp/swan';
import { __ } from '@99designs/i18n';
import { PinComment as Comment } from '../PinComment/PinComment';
import { PinComment } from './PinComment';

export interface PinCommentListProps {
    comments: Comment[];
}

export function PinCommentList({ comments }: PinCommentListProps) {
    return (
        <Box>
            <H3 fontSkin="title-subsection">{__('Pin comments')}</H3>
            {comments.map((comment, index) => (
                <PinComment key={index} comment={comment} count={index + 1} />
            ))}
        </Box>
    );
}

export default PinCommentList;
