import React from 'react';
import { Button, FlexBox, Icon, Typography, tokensRaw } from '@vp/swan';
import { getVariantUrl } from '@99designs/design-services-common';
import {
    Drawer,
    DrawerContent,
    DrawerFooter,
    DrawerHeader,
    DrawerProps,
} from '@99designs/design-services-layouts';
import { CollaborationFile } from '@99designs/graph-utils/types';
import { __ } from '@99designs/i18n';
import { getFileDownload } from '../../../util/getFileDownload';

export const Z_INDEX_DRAWER_FILE_PREVIEW = 1100;

interface FilePreviewDrawerProps {
    file: CollaborationFile;
    onDelete?: (() => void) | undefined;
    showDownload?: boolean;
    showDelete?: boolean;
    isPreviewable: boolean;
    drawerProps: Omit<DrawerProps, 'children'>;
}

export const FilePreviewDrawer = React.forwardRef<HTMLDivElement, FilePreviewDrawerProps>(
    ({ file, onDelete, showDownload, showDelete, isPreviewable, drawerProps }, ref) => {
        return (
            <Drawer
                {...drawerProps}
                asPageOverlay
                isDraggable
                zIndex={Z_INDEX_DRAWER_FILE_PREVIEW}
                ref={ref}
            >
                <DrawerHeader onClickBack={() => drawerProps.handleClose?.()} />
                <DrawerContent
                    style={{ maxHeight: '60lvh', overflow: 'scroll' }} // will allow scroll for long images
                >
                    {isPreviewable ? (
                        <img
                            src={getVariantUrl(file.externalUploadId, 'preview')}
                            height={'100%'}
                            width={'100%'}
                            alt={file.name}
                            style={{
                                objectFit: 'contain',
                                borderRadius: tokensRaw.SwanSemSpace3,
                            }}
                        />
                    ) : (
                        <FlexBox flexDirection="column" gap="2" alignItems="center">
                            <Icon iconType="fileGeneric" size="32p" marginBottom={'2'} />

                            <Typography fontSize="xsmall" fontWeight="bold" marginBottom={'2'}>
                                {__('Preview unavailable')}
                            </Typography>
                            <Typography fontSize="xsmall">{file.name}</Typography>
                        </FlexBox>
                    )}
                </DrawerContent>
                <DrawerFooter>
                    <FlexBox justifyContent="flex-end" gap="3">
                        {showDownload && (
                            <Button onClick={() => getFileDownload(file)} iconPosition="left">
                                <Icon iconType="download" size="20p" />
                                {__('Download')}
                            </Button>
                        )}
                        {showDelete && onDelete && (
                            <Button onClick={() => onDelete()} iconPosition="left">
                                {__('Remove')}
                                <Icon iconType="delete" size="20p" />
                            </Button>
                        )}
                    </FlexBox>
                </DrawerFooter>
            </Drawer>
        );
    }
);
