import { useTracking } from '@vp/react-tracking';
import React, { useState } from 'react';
import { Button, FlexBox, Icon, Spinner } from '@vp/swan';
import { __ } from '@99designs/i18n';
import { useActionContext, useCollaborationContext } from '../../../context';
import { useEditAction } from '../../../hooks/useEditAction';
import { useOrderAction } from '../../../hooks/useOrderAction';
import { useWorkEntity } from '../../../hooks/useWorkEntity';
import { ProductPreview } from '../ProductPreview';

export const TopbarActions = () => {
    const [loadingOrder, setLoadingOrder] = useState(false);
    const [isPreviewOpen, setIsPreviewOpen] = useState(false);
    const { tracking } = useTracking();
    const { locale, trackingProperties } = useCollaborationContext();
    const {
        actionOptions: { edit, approve },
    } = useActionContext();
    const editUrl = useEditAction();
    const { workRevisionId } = useWorkEntity();
    const { onClickOrder } = useOrderAction();

    return (
        <FlexBox gap="between-actions" flexWrap="wrap" justifyContent="flex-end">
            {workRevisionId && (
                <>
                    <Button
                        skin="tertiary"
                        onClick={async () => {
                            tracking.track('Collaboration Action Taken', {
                                type: 'Viewed preview',
                                ...trackingProperties,
                            });
                            setIsPreviewOpen(true);
                        }}
                        iconPosition="left"
                    >
                        <Icon iconType="preview" />
                        {__('Preview')}
                    </Button>
                    {isPreviewOpen && (
                        <ProductPreview
                            locale={locale}
                            workRevisionId={workRevisionId}
                            setIsPreviewOpen={setIsPreviewOpen}
                        />
                    )}
                </>
            )}
            {edit !== 'hidden' && (
                <Button
                    skin="tertiary"
                    disabled={edit === 'disabled'}
                    onClick={async () => {
                        tracking.track('Collaboration Action Taken', {
                            type: 'Edit yourself',
                            ...trackingProperties,
                        });
                        window.location.href = editUrl;
                    }}
                    iconPosition="left"
                >
                    <Icon iconType="edit" />
                    {__('Edit yourself')}
                </Button>
            )}
            {approve !== 'hidden' && (
                <Button
                    skin="primary"
                    disabled={approve === 'disabled'}
                    onClick={async () => {
                        setLoadingOrder(true);
                        await onClickOrder();
                    }}
                    compactMode
                >
                    {loadingOrder && <Spinner accessibleText={__('Loading...')} />}
                    {__('Approve & order')}
                </Button>
            )}
        </FlexBox>
    );
};
