import { useTracking } from '@vp/react-tracking';
import React, { useEffect } from 'react';
import { Button, FlexBox, tokens, useScreenClass } from '@vp/swan';
import styled from 'styled-components';
import { __ } from '@99designs/i18n';
import { ChatArea, ChatAreaProps } from '../../components/ChatArea';
import { TRACKING_PAGE_MY_ACCOUNT, useCollaborationContext } from '../../context';
import { useOrderAction } from '../../hooks/useOrderAction';

const ChatContainer = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    align-items: center;
`;

const StyledChatArea = styled.div`
    flex: auto;
    display: flex;
    flex-direction: column-reverse;
    width: 100%;
    padding: ${tokens.SwanSemSpace7};
    overflow-y: auto;
`;

const ActionButtonArea = styled.div`
    border-top: 1px ${tokens.SwanSemColorBorderStandard} solid;
    flex: none;
    width: 100%;
    padding: ${tokens.SwanSemSpace6} ${tokens.SwanSemSpace7};

    @media (max-width: ${tokens.SwanBaseBreakpointXsEnd}) {
        padding-top: ${tokens.SwanSemSpace3};
        padding-bottom: ${tokens.SwanSemSpace3};
    }
`;

export type ActivityPanelProps = ChatAreaProps & ActionButtonProps;
export type ChatEvents = ChatAreaProps['events'];

interface ActionButtonProps {
    requestRevision: 'enabled' | 'disabled' | 'hidden';
    approve: 'enabled' | 'disabled' | 'hidden';
    onRequestRevisionClick?: () => void;
    isActivityRoute?: boolean;
}

export const ActivityPanel: React.FC<ActivityPanelProps> = ({
    events,
    requestRevision,
    onRequestRevisionClick,
    approve,
    isActivityRoute,
}) => {
    const { trackingProperties } = useCollaborationContext();
    const screenClass = useScreenClass();
    const isMobile = screenClass === 'xs';

    const { tracking } = useTracking();

    useEffect(() => {
        tracking.page(`Design Services:Collaboration:${isActivityRoute ? 'Activity' : 'Design'}`, {
            ...trackingProperties,
            pageName: `Design Services:Collaboration:${isActivityRoute ? 'Activity' : 'Design'}:${
                trackingProperties.collaborationStatus
            }`,
            pageSection: TRACKING_PAGE_MY_ACCOUNT,
            pageStage: TRACKING_PAGE_MY_ACCOUNT,
        });
    }, []);

    return (
        <ChatContainer>
            {/* On mobile, the overflow is handled by the parent container in ResponsiveDrawerLayout */}
            <StyledChatArea style={{ overflow: !isMobile ? 'auto' : undefined }}>
                <ChatArea events={events} />
            </StyledChatArea>
            {(requestRevision !== 'hidden' || approve !== 'hidden') && !isMobile && (
                <ActionButtonArea>
                    <ActionButtons
                        onRequestRevisionClick={onRequestRevisionClick}
                        requestRevision={requestRevision}
                        approve={approve}
                    />
                </ActionButtonArea>
            )}
        </ChatContainer>
    );
};

const ActionButtons = ({ requestRevision, approve, onRequestRevisionClick }: ActionButtonProps) => {
    const { onClickOrder } = useOrderAction();
    return (
        <FlexBox gap={'between-actions'} justifyContent="center" alignItems="center">
            {requestRevision !== 'hidden' && (
                <Button
                    width="full-width"
                    skin="secondary"
                    fontSkin={'body-standard-bold'}
                    disabled={requestRevision === 'disabled'}
                    onClick={onRequestRevisionClick}
                >
                    {__('Request a revision')}
                </Button>
            )}
            {approve !== 'hidden' && (
                <Button
                    width="full-width"
                    skin="primary"
                    fontSkin={'body-standard-bold'}
                    disabled={approve === 'disabled'}
                    onClick={onClickOrder}
                >
                    {__('Approve & order')}
                </Button>
            )}
        </FlexBox>
    );
};
