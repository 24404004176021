import React from 'react';
import {
    Box,
    Button,
    Carousel,
    CarouselSlide,
    Divider,
    FlexBox,
    FluidImage,
    Icon,
    ModalDialog,
    ModalDialogBody,
    ModalDialogCloseButton,
    ModalDialogContent,
    ModalDialogHeader,
    ModalDialogNav,
    ModalDialogTitle,
} from '@vp/swan';
import styled from 'styled-components';
import { __ } from '@99designs/i18n';

export interface PreviewFileTuple {
    url: string;
    filename?: string;
    title?: string;
    previewable?: boolean;
}

const StyledCarouselSlide = styled(CarouselSlide)`
    text-align: center;
    text-align: -webkit-center;
`;

const StyledFluidImage = styled(FluidImage)`
    object-fit: contain !important;
`;

export enum PreviewModalVariant {
    REFERENCE_FILE = 'reference-file',
    WORK_ENTITY = 'work-entity',
}

interface PreviewModalProps {
    isOpen: boolean;
    setIsOpen: (isOpen: boolean) => void;
    currentSlide: number;
    setCurrentSlide: (currentSlide: number) => void;
    previewFiles: PreviewFileTuple[];

    /**
     * The purpose of the modal in `kebab-case`.
     */
    variant: PreviewModalVariant;
    onDownload?: (file: PreviewFileTuple) => void;
}

export default function PreviewModal({
    isOpen,
    setIsOpen,
    currentSlide,
    setCurrentSlide,
    previewFiles,
    variant,
    onDownload,
}: PreviewModalProps) {
    const currentTitle = previewFiles[currentSlide]?.title;

    return (
        <ModalDialog
            isOpen={isOpen}
            onRequestDismiss={() => setIsOpen(false)}
            takeOver
            bodyWidth="capped"
        >
            <ModalDialogContent
                aria-labelledby="modal dialog general"
                display="flex"
                style={{ flexDirection: 'column' }}
            >
                <ModalDialogNav>
                    <ModalDialogCloseButton accessibleText={__('Close')} type="button" />
                </ModalDialogNav>
                {currentTitle && (
                    <ModalDialogHeader>
                        <ModalDialogTitle>{currentTitle}</ModalDialogTitle>
                    </ModalDialogHeader>
                )}
                <ModalDialogBody style={{ flexGrow: 1 }}>
                    <FlexBox
                        flexDirection="column"
                        justifyContent="space-between"
                        style={{ height: '100%' }}
                    >
                        <Box style={{ width: '100%', maxWidth: '720px', margin: 'auto' }}>
                            <Carousel
                                slidesToShow={1}
                                skin="standard"
                                progressIndicator="none"
                                accessibleTextForPrevious={__('Go to previous slide')}
                                accessibleTextForNext={__('Go to next slide')}
                                currentSlide={currentSlide}
                                afterChange={(index) => setCurrentSlide(index)}
                                swanStyle={{ paddingY: 10 }}
                            >
                                {previewFiles.map((view, i) => {
                                    return (
                                        <StyledCarouselSlide
                                            key={`${variant.valueOf()}-modal-carousel-${i}`}
                                        >
                                            <FlexBox flexDirection="column">
                                                <StyledFluidImage
                                                    src={view.url}
                                                    role="presentation"
                                                />
                                            </FlexBox>
                                        </StyledCarouselSlide>
                                    );
                                })}
                            </Carousel>
                        </Box>
                        {variant !== PreviewModalVariant.WORK_ENTITY && onDownload && (
                            <FlexBox flexDirection="column" alignItems="center" gap="6">
                                <Divider style={{ width: '100%' }} />
                                <Button
                                    skin="secondary"
                                    iconPosition="left"
                                    onClick={() => onDownload(previewFiles[currentSlide])}
                                >
                                    {__('Download')}
                                    <Icon iconType="download" />
                                </Button>
                            </FlexBox>
                        )}
                    </FlexBox>
                </ModalDialogBody>
            </ModalDialogContent>
        </ModalDialog>
    );
}
