import { bugtracker } from '../bugtracker';

export function buildError(e: unknown, message: string): Error {
    let err: Error;
    if (e instanceof Error) {
        err = new Error(`${message}: ${e.message}`);
    } else {
        err = new Error(`${message}: ${JSON.stringify(e)}`);
    }
    return err;
}

export function buildErrorAndNotify(e: unknown, message: string): Error {
    const err = buildError(e, message);
    bugtracker.notify(err);
    return err;
}
