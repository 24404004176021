import { Section, SectionProps, tokensRaw } from '@vp/swan';
import styled from 'styled-components';

const DrawerContentContainer = styled(Section)<{ $scrollPositionAtBottom?: boolean }>`
    height: 100%;
    padding: ${tokensRaw.SwanBaseSpace200};

    ${({ $scrollPositionAtBottom }) =>
        $scrollPositionAtBottom &&
        `
      display: flex;
      flex-direction: column-reverse;
    `}
`;

interface DrawerContentProps extends SectionProps {
    children: React.ReactNode;
    'aria-label'?: string;
    scrollPositionAtBottom?: boolean;
}

export const DrawerContent: React.FC<DrawerContentProps> = ({
    children,
    scrollPositionAtBottom,
    ...props
}) => {
    return (
        <DrawerContentContainer {...props} $scrollPositionAtBottom={scrollPositionAtBottom}>
            {children}
        </DrawerContentContainer>
    );
};
