import React from 'react';
import { BoundedContent, FlexBox, Spinner } from '@vp/swan';
import { __ } from '@99designs/i18n';
import { AUTH_INTERNAL_CLIENT_PARAM, AUTH_VP_ACCESS_TOKEN_LOCAL_STORAGE_KEY } from '../../consts';
import { redirectToInternalLogin } from '../../lib/auth/buildAuthConfiguration';
import { useIdentityContext } from '../IdentityContext';

interface RouteWithAuthProps {
    isInternal?: boolean;

    children: React.ReactNode;
}

const RedirectingLoader = () => (
    <BoundedContent p={7}>
        <FlexBox justifyContent={'center'} m={8}>
            <Spinner size="super" accessibleText={__('Redirecting to login...')} />
        </FlexBox>
    </BoundedContent>
);

export function RouteWithAuth({ isInternal, children }: RouteWithAuthProps) {
    const { isSignedIn, isInternalUser, SignIn, SignOut, auth } = useIdentityContext();

    // Private apps require internal auth
    // To use this right now you must also have the query param set `AUTH_INTERNAL_CLIENT_PARAM`
    if (isInternal && auth !== undefined && isSignedIn && !isInternalUser(auth)) {
        SignOut();
        SignIn();
        return <RedirectingLoader />;
    }

    let params;
    if (typeof window !== 'undefined') {
        params = new URLSearchParams(window.location.search);
    }

    // If the user is an agent but the session is connected to a shopper account, we need to sign them out and back in to get a new token with the correct privileges.
    if (
        params &&
        params.has(AUTH_INTERNAL_CLIENT_PARAM) &&
        auth !== undefined &&
        !isInternalUser(auth)
    ) {
        localStorage.removeItem(AUTH_VP_ACCESS_TOKEN_LOCAL_STORAGE_KEY);
        SignIn();

        return <RedirectingLoader />;
    }

    if (!isSignedIn) {
        SignIn();

        return <RedirectingLoader />;
    }

    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <>{children}</>;
}
