import { useCallback } from 'react';
import Bugsnag from '@bugsnag/js';
import debounce from 'lodash.debounce';
import { RevisionRequestState } from '@99designs/graph-utils/types';
import { PinComment } from '../ui';
import { formatPins } from '../views';
import {
    CollaborationFileFragment,
    useGetRevisionRequestQuery,
    useUpdateRevisionRequestMutation,
} from '../views/RequestRevisionPanel/RequestRevision.generated';

interface RevisionRequestPanelInput {
    collaborationPublicId: string;
    deliveryVersionId: number;
}

export interface RevisionRequestPanelData {
    id: number;
    state: RevisionRequestState;
    generalComment: string;
    pinnedComments: PinComment[];
    files: CollaborationFileFragment[];
}

export function useRevisionRequestPanelData({
    collaborationPublicId,
    deliveryVersionId,
}: RevisionRequestPanelInput) {
    const {
        data: queryData,
        loading,
        error,
    } = useGetRevisionRequestQuery({
        variables: {
            collaborationPublicId,
            deliveryVersionId,
        },
    });

    const [updateRevisionRequest] = useUpdateRevisionRequestMutation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const handleUpdate = useCallback(
        debounce(({ comment }: { comment?: string }) => {
            updateRevisionRequest({
                variables: {
                    collaborationPublicId: collaborationPublicId,
                    deliveryVersionId: deliveryVersionId,
                    comment,
                },
            }).catch((error) => {
                Bugsnag.notify(error, (event) => {
                    event.addMetadata('updateRevisionRequest', {
                        collaborationPublicId,
                    });
                });
            });
        }, 1000),
        []
    );

    const variationComments = queryData?.revisionRequest?.variationComments || [];
    const pinnedComments = formatPins(variationComments);

    const data: RevisionRequestPanelData = {
        id: queryData?.revisionRequest.id || 0,
        state: queryData?.revisionRequest.state || 'REVISION_REQUEST_STATE_PENDING',
        generalComment: queryData?.revisionRequest.message || '',
        pinnedComments,
        files: queryData?.revisionRequest.files || [],
    };

    return {
        data,
        loading,
        error,
        handleUpdate,
    };
}
