import React, { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Box, tokensRaw, useScreenClass } from '@vp/swan';
import styled from 'styled-components';
import { __ } from '@99designs/i18n';

const HEIGHT_OF_MOBILE_BOTTOM_BAR_PX = '66px';

const Container = styled.div`
    display: flex;
    flex-direction: row;
    height: 100%;
    width: 100%;

    @media (max-width: ${tokensRaw.SwanBaseBreakpointXsEnd}) {
        padding-bottom: ${HEIGHT_OF_MOBILE_BOTTOM_BAR_PX};
    }
`;

const PanelLeft = styled.div`
    flex: 1;
    background-color: ${tokensRaw.SwanBaseColorWhite};
    border-right: 1px solid ${tokensRaw.SwanBaseColorGrey300};
    max-width: 560px;
    min-width: 400px;
    overflow-y: auto;
`;

const PanelRight = styled.div`
    flex: 1.618; // Golden ratio between min and max width values
    background-color: ${tokensRaw.SwanBaseColorWhite};
    overflow-y: auto;
    height: 100%;
`;

export interface DualPanelViewProps {
    left: React.ReactNode;
    right: React.ReactNode;
}

type Panel = 'designs';

// DO NOT USE - This component is deprecated and will be removed soon. Look at using ResponsiveDrawerLayout instead.
// Desktop shows two panels while mobile will show the left panel by default. To display a particular panel on mobile, having the params ?activePanel='activity' | 'designs' | 'revision' in the URL with set the activePanel value as the default view on mobile.
export const DualPanelView: React.FC<DualPanelViewProps> = ({ left, right }) => {
    const screenClass = useScreenClass();

    const [searchParams, setSearchParams] = useSearchParams();
    const allParams = Object.fromEntries(searchParams.entries());

    // searchParams.get will return null if the param is not present
    const commentId = searchParams.get('commentId');
    const activePanel = searchParams.get('activePanel') as Panel;

    // Handles which panel should be in view on mobile
    const renderPanel = () => {
        switch (activePanel) {
            case 'designs':
                return (
                    <Box style={{ width: '100%', height: '100%' }}>
                        <PanelRight>{right}</PanelRight>
                    </Box>
                );
            default:
                return <Box style={{ width: '100%' }}>{left}</Box>;
        }
    };

    // Switch to the design panel if a user is viewing a comment
    useEffect(() => {
        if (commentId !== null) {
            setSearchParams({
                ...allParams,
                activePanel: 'designs',
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [commentId]);

    return (
        <Container>
            {screenClass === 'xs' ? (
                <>{renderPanel()}</>
            ) : (
                <>
                    <PanelLeft>{left}</PanelLeft>
                    <PanelRight>{right}</PanelRight>
                </>
            )}
        </Container>
    );
};
