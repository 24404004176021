import { Spinner } from '@vp/swan';
import { __ } from '@99designs/i18n';
import { useWorkEntityFieldQuery } from './WorkEntityField.generated';
import WorkEntityViews from './WorkEntityViews';

interface WorkEntityFieldProps {
    workEntityValue: string;
}

export default function WorkEntityField(props: WorkEntityFieldProps) {
    const { data, loading, error } = useWorkEntityFieldQuery({
        variables: {
            workId: props.workEntityValue,
        },
    });

    if (error) {
        throw error;
    }

    if (loading || !data) {
        return <Spinner accessibleText={__('Loading…')} />;
    }

    return <WorkEntityViews views={data.workEntityViews.views} />;
}
