import { useTracking } from '@vp/react-tracking';
import React, { useEffect } from 'react';
import { useScreenClass } from '@vp/swan';
import {
    DrawerRevisionRequest,
    EmptyCanvas,
    ErrorLoadingState,
    LOCAL_STORAGE_PINNED_COMMENTS_ONBOARDING,
    PinnedCommentsOnboardingOverlay,
    RevisionRequestPanelData,
    SingleImageDesignPreview,
    TRACKING_PAGE_MY_ACCOUNT,
    ViewProvider,
    useCanvasProps,
    useCollaborationContext,
    useVersion,
} from '@99designs/collaborate-views';

export interface RevisionCanvasProps {
    revisionRequestId: number | null;
    deliveryId: string;
    subRoute: string | null;
    interactable?: boolean;
    revisionRequest: RevisionRequestPanelData;
    handleUpdate: ({ comment }: { comment?: string | undefined }) => void;
}

export const RevisionCanvas: React.FC<RevisionCanvasProps> = ({
    deliveryId,
    revisionRequestId,
    subRoute,
    interactable = false,
    revisionRequest,
    handleUpdate,
}) => {
    const { tracking } = useTracking();
    const {
        collaborationId,
        revisionRequests,
        deliveries,
        trackingProperties,
        viewerRole,
        locale,
        hasDesignLiveDelivery,
        latestVersion,
    } = useCollaborationContext();
    const { loading, errors, props } = useCanvasProps(
        deliveryId,
        collaborationId,
        revisionRequestId,
        deliveries.length > 0,
        trackingProperties,
        locale
    );
    const { version, setVersion } = useVersion(subRoute);

    const screenClass = useScreenClass();

    const hasSeenPinnedCommentsOnboarding =
        localStorage.getItem(LOCAL_STORAGE_PINNED_COMMENTS_ONBOARDING) === 'true';
    const shouldRenderPinnedCommentsOnboarding =
        screenClass === 'xs' &&
        interactable &&
        props.pins.length === 0 &&
        !hasSeenPinnedCommentsOnboarding;

    useEffect(() => {
        if (interactable) {
            tracking.page('Design Services:Collaboration:Request Revision', {
                ...trackingProperties,
                pageName: `Design Services:Collaboration:Request Revision:${trackingProperties.collaborationStatus}`,
                pageSection: TRACKING_PAGE_MY_ACCOUNT,
                pageStage: TRACKING_PAGE_MY_ACCOUNT,
            });
        } else {
            tracking.page('Design Services:Collaboration:Revision Feedback', {
                ...trackingProperties,
                pageName: `Design Services:Collaboration:Revision Feedback:${trackingProperties.collaborationStatus}`,
                pageSection: TRACKING_PAGE_MY_ACCOUNT,
                pageStage: TRACKING_PAGE_MY_ACCOUNT,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <ErrorLoadingState loading={loading} errors={errors} data={props}>
            {(props) =>
                Object.keys(props.images).length === 0 ? (
                    <EmptyCanvas isDesignLive={hasDesignLiveDelivery} />
                ) : (
                    <ViewProvider
                        initialView={{ view: interactable ? 'editRevision' : 'viewRevision' }}
                    >
                        <SingleImageDesignPreview
                            {...props}
                            revisionsRemaining={revisionRequests.remaining}
                            revisionsTotal={revisionRequests.used}
                            version={version}
                            setVersion={setVersion}
                            isDesignerView={viewerRole === 'VIEWER_ROLE_DESIGNER'}
                            interactable={interactable}
                        />

                        {screenClass === 'xs' && (
                            <DrawerRevisionRequest
                                revisionRequest={revisionRequest}
                                handleUpdate={handleUpdate}
                                deliveryVersionId={deliveryId}
                                latestVersion={latestVersion}
                                collaborationId={collaborationId}
                                images={props.images}
                                pins={props.pins}
                                interactable={interactable}
                            />
                        )}

                        {shouldRenderPinnedCommentsOnboarding && (
                            <PinnedCommentsOnboardingOverlay />
                        )}
                    </ViewProvider>
                )
            }
        </ErrorLoadingState>
    );
};
