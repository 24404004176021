import { useSearchParams } from 'react-router-dom';
import { PinComment } from '../PinComment/PinComment';

/**
 * Hook to manage the query params for pinned comments on a revision request
 */
export function useRevisionRequestQueryParams(pin: PinComment): {
    commentId: string | null | undefined;
    pinClickHandler: () => void;
    closeHandler: (id?: string) => void;
} {
    const [searchParams, setSearchParams] = useSearchParams();
    const commentId = searchParams.has('commentId') ? searchParams.get('commentId') : null;
    const closeHandler = (id?: string) => {
        // don't close the popover if another pin has been clicked
        if (id && id !== commentId) {
            return;
        }
        setSearchParams({
            selectedSurface: String(pin.selectedSurface),
            deliveryVariationId: String(pin.deliveryVariationId),
            activePanel: 'designs',
        });
    };

    const pinClickHandler = () => {
        setSearchParams({
            selectedSurface: String(pin.selectedSurface),
            deliveryVariationId: String(pin.deliveryVariationId),
            commentId: String(pin.commentId),
            activePanel: 'designs',
        });
    };

    return {
        commentId,
        closeHandler,
        pinClickHandler,
    };
}
