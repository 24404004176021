/* eslint-disable */

import * as Apollo from '@apollo/client';
import { DocumentNode } from 'graphql';
import * as Types from '@99designs/graph-utils/types';

export type GetRevisionRequestQueryVariables = Types.Exact<{
    collaborationPublicId: Types.Scalars['String'];
    deliveryVersionId: Types.Scalars['Int'];
}>;

export type GetRevisionRequestQuery = {
    __typename: 'Query';
    revisionRequest: { __typename: 'RevisionRequest' } & RevisionRequestFragment;
};

export type RevisionRequestFragment = {
    __typename: 'RevisionRequest';
    id: number;
    state: Types.RevisionRequestState;
    message: string;
    variationComments: Array<{
        __typename: 'DeliveryVariationSurfaceComment';
        commentId: string;
        deliveryVariationId: number;
        comment: {
            __typename: 'SurfaceComment';
            surfaceIndexId: number;
            surfaceX: number;
            surfaceY: number;
            message: string;
        };
    }>;
    files: Array<{ __typename: 'CollaborationFile' } & CollaborationFileFragment>;
};

export type GetOrCreateRevisionRequestMutationVariables = Types.Exact<{
    collaborationPublicId: Types.Scalars['String'];
    deliveryVersionId: Types.Scalars['Int'];
}>;

export type GetOrCreateRevisionRequestMutation = {
    __typename: 'Mutation';
    getOrCreateRevisionRequest: { __typename: 'RevisionRequest' } & RevisionRequestFragment;
};

export type UpdateRevisionRequestMutationVariables = Types.Exact<{
    collaborationPublicId: Types.Scalars['String'];
    deliveryVersionId: Types.Scalars['Int'];
    comment?: Types.Maybe<Types.Scalars['String']>;
}>;

export type UpdateRevisionRequestMutation = {
    __typename: 'Mutation';
    createOrUpdateRevisionRequest: { __typename: 'RevisionRequest' } & RevisionRequestFragment;
};

export type CollaborationFileFragment = {
    __typename: 'CollaborationFile';
    id: number;
    externalUploadId: string;
    name: string;
};

export type CreateRevisionRequestFileMutationVariables = Types.Exact<{
    revisionRequestId: Types.Scalars['Int'];
    externalUploadId: Types.Scalars['String'];
    fileName: Types.Scalars['String'];
}>;

export type CreateRevisionRequestFileMutation = {
    __typename: 'Mutation';
    createRevisionRequestFile: { __typename: 'CollaborationFile' } & CollaborationFileFragment;
};

export const CollaborationFileFragmentDoc: DocumentNode = {
    kind: 'Document',
    definitions: [
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'CollaborationFile' },
            typeCondition: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'CollaborationFile' },
            },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'externalUploadId' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                ],
            },
        },
    ],
};
export const RevisionRequestFragmentDoc: DocumentNode = {
    kind: 'Document',
    definitions: [
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'RevisionRequest' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'RevisionRequest' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'state' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'variationComments' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'commentId' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'deliveryVariationId' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'comment' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'surfaceIndexId' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'surfaceX' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'surfaceY' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'message' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'files' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'FragmentSpread',
                                    name: { kind: 'Name', value: 'CollaborationFile' },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'CollaborationFile' },
            typeCondition: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'CollaborationFile' },
            },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'externalUploadId' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                ],
            },
        },
    ],
};
export const GetRevisionRequestDocument: DocumentNode = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'getRevisionRequest' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'collaborationPublicId' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'deliveryVersionId' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'revisionRequest' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'collaborationPublicId' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'collaborationPublicId' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'deliveryVersionId' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'deliveryVersionId' },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'FragmentSpread',
                                    name: { kind: 'Name', value: 'RevisionRequest' },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'RevisionRequest' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'RevisionRequest' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'state' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'variationComments' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'commentId' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'deliveryVariationId' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'comment' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'surfaceIndexId' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'surfaceX' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'surfaceY' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'message' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'files' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'FragmentSpread',
                                    name: { kind: 'Name', value: 'CollaborationFile' },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'CollaborationFile' },
            typeCondition: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'CollaborationFile' },
            },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'externalUploadId' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                ],
            },
        },
    ],
};

/**
 * __useGetRevisionRequestQuery__
 *
 * To run a query within a React component, call `useGetRevisionRequestQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRevisionRequestQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRevisionRequestQuery({
 *   variables: {
 *      collaborationPublicId: // value for 'collaborationPublicId'
 *      deliveryVersionId: // value for 'deliveryVersionId'
 *   },
 * });
 */
export function useGetRevisionRequestQuery(
    baseOptions: Apollo.QueryHookOptions<GetRevisionRequestQuery, GetRevisionRequestQueryVariables>
) {
    return Apollo.useQuery<GetRevisionRequestQuery, GetRevisionRequestQueryVariables>(
        GetRevisionRequestDocument,
        baseOptions
    );
}
export function useGetRevisionRequestLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        GetRevisionRequestQuery,
        GetRevisionRequestQueryVariables
    >
) {
    return Apollo.useLazyQuery<GetRevisionRequestQuery, GetRevisionRequestQueryVariables>(
        GetRevisionRequestDocument,
        baseOptions
    );
}
export type GetRevisionRequestQueryHookResult = ReturnType<typeof useGetRevisionRequestQuery>;
export type GetRevisionRequestLazyQueryHookResult = ReturnType<
    typeof useGetRevisionRequestLazyQuery
>;
export type GetRevisionRequestQueryResult = Apollo.QueryResult<
    GetRevisionRequestQuery,
    GetRevisionRequestQueryVariables
>;
export const GetOrCreateRevisionRequestDocument: DocumentNode = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'getOrCreateRevisionRequest' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'collaborationPublicId' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'deliveryVersionId' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'getOrCreateRevisionRequest' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'input' },
                                value: {
                                    kind: 'ObjectValue',
                                    fields: [
                                        {
                                            kind: 'ObjectField',
                                            name: { kind: 'Name', value: 'collaborationPublicId' },
                                            value: {
                                                kind: 'Variable',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'collaborationPublicId',
                                                },
                                            },
                                        },
                                        {
                                            kind: 'ObjectField',
                                            name: { kind: 'Name', value: 'deliveryVersionId' },
                                            value: {
                                                kind: 'Variable',
                                                name: { kind: 'Name', value: 'deliveryVersionId' },
                                            },
                                        },
                                    ],
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'FragmentSpread',
                                    name: { kind: 'Name', value: 'RevisionRequest' },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'RevisionRequest' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'RevisionRequest' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'state' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'variationComments' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'commentId' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'deliveryVariationId' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'comment' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'surfaceIndexId' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'surfaceX' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'surfaceY' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'message' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'files' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'FragmentSpread',
                                    name: { kind: 'Name', value: 'CollaborationFile' },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'CollaborationFile' },
            typeCondition: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'CollaborationFile' },
            },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'externalUploadId' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                ],
            },
        },
    ],
};
export type GetOrCreateRevisionRequestMutationFn = Apollo.MutationFunction<
    GetOrCreateRevisionRequestMutation,
    GetOrCreateRevisionRequestMutationVariables
>;

/**
 * __useGetOrCreateRevisionRequestMutation__
 *
 * To run a mutation, you first call `useGetOrCreateRevisionRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGetOrCreateRevisionRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [getOrCreateRevisionRequestMutation, { data, loading, error }] = useGetOrCreateRevisionRequestMutation({
 *   variables: {
 *      collaborationPublicId: // value for 'collaborationPublicId'
 *      deliveryVersionId: // value for 'deliveryVersionId'
 *   },
 * });
 */
export function useGetOrCreateRevisionRequestMutation(
    baseOptions?: Apollo.MutationHookOptions<
        GetOrCreateRevisionRequestMutation,
        GetOrCreateRevisionRequestMutationVariables
    >
) {
    return Apollo.useMutation<
        GetOrCreateRevisionRequestMutation,
        GetOrCreateRevisionRequestMutationVariables
    >(GetOrCreateRevisionRequestDocument, baseOptions);
}
export type GetOrCreateRevisionRequestMutationHookResult = ReturnType<
    typeof useGetOrCreateRevisionRequestMutation
>;
export type GetOrCreateRevisionRequestMutationResult =
    Apollo.MutationResult<GetOrCreateRevisionRequestMutation>;
export type GetOrCreateRevisionRequestMutationOptions = Apollo.BaseMutationOptions<
    GetOrCreateRevisionRequestMutation,
    GetOrCreateRevisionRequestMutationVariables
>;
export const UpdateRevisionRequestDocument: DocumentNode = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'updateRevisionRequest' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'collaborationPublicId' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'deliveryVersionId' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'comment' } },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'createOrUpdateRevisionRequest' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'input' },
                                value: {
                                    kind: 'ObjectValue',
                                    fields: [
                                        {
                                            kind: 'ObjectField',
                                            name: { kind: 'Name', value: 'collaborationPublicId' },
                                            value: {
                                                kind: 'Variable',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'collaborationPublicId',
                                                },
                                            },
                                        },
                                        {
                                            kind: 'ObjectField',
                                            name: { kind: 'Name', value: 'deliveryVersionId' },
                                            value: {
                                                kind: 'Variable',
                                                name: { kind: 'Name', value: 'deliveryVersionId' },
                                            },
                                        },
                                        {
                                            kind: 'ObjectField',
                                            name: { kind: 'Name', value: 'comment' },
                                            value: {
                                                kind: 'Variable',
                                                name: { kind: 'Name', value: 'comment' },
                                            },
                                        },
                                    ],
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'FragmentSpread',
                                    name: { kind: 'Name', value: 'RevisionRequest' },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'RevisionRequest' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'RevisionRequest' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'state' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'variationComments' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'commentId' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'deliveryVariationId' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'comment' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'surfaceIndexId' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'surfaceX' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'surfaceY' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'message' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'files' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'FragmentSpread',
                                    name: { kind: 'Name', value: 'CollaborationFile' },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'CollaborationFile' },
            typeCondition: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'CollaborationFile' },
            },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'externalUploadId' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                ],
            },
        },
    ],
};
export type UpdateRevisionRequestMutationFn = Apollo.MutationFunction<
    UpdateRevisionRequestMutation,
    UpdateRevisionRequestMutationVariables
>;

/**
 * __useUpdateRevisionRequestMutation__
 *
 * To run a mutation, you first call `useUpdateRevisionRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRevisionRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRevisionRequestMutation, { data, loading, error }] = useUpdateRevisionRequestMutation({
 *   variables: {
 *      collaborationPublicId: // value for 'collaborationPublicId'
 *      deliveryVersionId: // value for 'deliveryVersionId'
 *      comment: // value for 'comment'
 *   },
 * });
 */
export function useUpdateRevisionRequestMutation(
    baseOptions?: Apollo.MutationHookOptions<
        UpdateRevisionRequestMutation,
        UpdateRevisionRequestMutationVariables
    >
) {
    return Apollo.useMutation<
        UpdateRevisionRequestMutation,
        UpdateRevisionRequestMutationVariables
    >(UpdateRevisionRequestDocument, baseOptions);
}
export type UpdateRevisionRequestMutationHookResult = ReturnType<
    typeof useUpdateRevisionRequestMutation
>;
export type UpdateRevisionRequestMutationResult =
    Apollo.MutationResult<UpdateRevisionRequestMutation>;
export type UpdateRevisionRequestMutationOptions = Apollo.BaseMutationOptions<
    UpdateRevisionRequestMutation,
    UpdateRevisionRequestMutationVariables
>;
export const CreateRevisionRequestFileDocument: DocumentNode = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'createRevisionRequestFile' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'revisionRequestId' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'externalUploadId' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'fileName' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'createRevisionRequestFile' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'input' },
                                value: {
                                    kind: 'ObjectValue',
                                    fields: [
                                        {
                                            kind: 'ObjectField',
                                            name: { kind: 'Name', value: 'revisionRequestId' },
                                            value: {
                                                kind: 'Variable',
                                                name: { kind: 'Name', value: 'revisionRequestId' },
                                            },
                                        },
                                        {
                                            kind: 'ObjectField',
                                            name: { kind: 'Name', value: 'externalUploadId' },
                                            value: {
                                                kind: 'Variable',
                                                name: { kind: 'Name', value: 'externalUploadId' },
                                            },
                                        },
                                        {
                                            kind: 'ObjectField',
                                            name: { kind: 'Name', value: 'fileName' },
                                            value: {
                                                kind: 'Variable',
                                                name: { kind: 'Name', value: 'fileName' },
                                            },
                                        },
                                    ],
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'FragmentSpread',
                                    name: { kind: 'Name', value: 'CollaborationFile' },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'CollaborationFile' },
            typeCondition: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'CollaborationFile' },
            },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'externalUploadId' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                ],
            },
        },
    ],
};
export type CreateRevisionRequestFileMutationFn = Apollo.MutationFunction<
    CreateRevisionRequestFileMutation,
    CreateRevisionRequestFileMutationVariables
>;

/**
 * __useCreateRevisionRequestFileMutation__
 *
 * To run a mutation, you first call `useCreateRevisionRequestFileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateRevisionRequestFileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createRevisionRequestFileMutation, { data, loading, error }] = useCreateRevisionRequestFileMutation({
 *   variables: {
 *      revisionRequestId: // value for 'revisionRequestId'
 *      externalUploadId: // value for 'externalUploadId'
 *      fileName: // value for 'fileName'
 *   },
 * });
 */
export function useCreateRevisionRequestFileMutation(
    baseOptions?: Apollo.MutationHookOptions<
        CreateRevisionRequestFileMutation,
        CreateRevisionRequestFileMutationVariables
    >
) {
    return Apollo.useMutation<
        CreateRevisionRequestFileMutation,
        CreateRevisionRequestFileMutationVariables
    >(CreateRevisionRequestFileDocument, baseOptions);
}
export type CreateRevisionRequestFileMutationHookResult = ReturnType<
    typeof useCreateRevisionRequestFileMutation
>;
export type CreateRevisionRequestFileMutationResult =
    Apollo.MutationResult<CreateRevisionRequestFileMutation>;
export type CreateRevisionRequestFileMutationOptions = Apollo.BaseMutationOptions<
    CreateRevisionRequestFileMutation,
    CreateRevisionRequestFileMutationVariables
>;
