/* eslint-disable */

import * as Apollo from '@apollo/client';
import { DocumentNode } from 'graphql';
import * as Types from '@99designs/graph-utils/types';

export type DiscardRevisionRequestMutationVariables = Types.Exact<{
    revisionRequestId: Types.Scalars['Int'];
}>;

export type DiscardRevisionRequestMutation = {
    __typename: 'Mutation';
    discardRevisionRequest: { __typename: 'GenericRepsonse'; success: boolean };
};

export const DiscardRevisionRequestDocument: DocumentNode = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'DiscardRevisionRequest' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'revisionRequestId' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'discardRevisionRequest' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'revisionRequestId' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'revisionRequestId' },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'success' } },
                            ],
                        },
                    },
                ],
            },
        },
    ],
};
export type DiscardRevisionRequestMutationFn = Apollo.MutationFunction<
    DiscardRevisionRequestMutation,
    DiscardRevisionRequestMutationVariables
>;

/**
 * __useDiscardRevisionRequestMutation__
 *
 * To run a mutation, you first call `useDiscardRevisionRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDiscardRevisionRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [discardRevisionRequestMutation, { data, loading, error }] = useDiscardRevisionRequestMutation({
 *   variables: {
 *      revisionRequestId: // value for 'revisionRequestId'
 *   },
 * });
 */
export function useDiscardRevisionRequestMutation(
    baseOptions?: Apollo.MutationHookOptions<
        DiscardRevisionRequestMutation,
        DiscardRevisionRequestMutationVariables
    >
) {
    return Apollo.useMutation<
        DiscardRevisionRequestMutation,
        DiscardRevisionRequestMutationVariables
    >(DiscardRevisionRequestDocument, baseOptions);
}
export type DiscardRevisionRequestMutationHookResult = ReturnType<
    typeof useDiscardRevisionRequestMutation
>;
export type DiscardRevisionRequestMutationResult =
    Apollo.MutationResult<DiscardRevisionRequestMutation>;
export type DiscardRevisionRequestMutationOptions = Apollo.BaseMutationOptions<
    DiscardRevisionRequestMutation,
    DiscardRevisionRequestMutationVariables
>;
