export interface VistaLogoProps {
    width?: string;
    height?: string;
}

export const VistaLogo = ({ width, height }: VistaLogoProps) => (
    <svg
        width={width ?? '26'}
        height={height ?? '23'}
        viewBox="0 0 26 23"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path d="M13.2416 22.5L7.6366 12.602H18.8457L13.2416 22.5Z" fill="#006196" />
        <path d="M18.8459 12.6003H7.63422L13.2393 2.70065L18.8459 12.6003Z" fill="#2BA8E0" />
        <path d="M14.7693 0H25.9802L18.846 12.6003L13.2401 2.70066L14.7693 0Z" fill="#006196" />
        <path d="M11.7109 0L13.2393 2.70066L7.63503 12.6003L0.5 0H11.7109Z" fill="#6ECFF5" />
    </svg>
);
