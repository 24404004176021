import { DeliveryState } from '@99designs/graph-utils/types';
import { __ } from '@99designs/i18n';

interface DeliveryStatus {
    color: string;
    label: string;
}

export const getCollaborationStatus = (status: DeliveryState): DeliveryStatus => {
    switch (status) {
        case 'DELIVERY_STATE_REQUEST_SUBMITTED':
            return {
                color: '#318643',
                label: __('Request submitted'),
            };
        case 'DELIVERY_STATE_DESIGN_IN_PROGRESS':
            return {
                color: '#318643',
                label: __('Design in progress'),
            };
        case 'DELIVERY_STATE_READY_FOR_REVIEW':
            return {
                color: '#E8B738',
                label: __('Ready for review'),
            };
        case 'DELIVERY_STATE_REVISION_IN_PROGRESS':
            return {
                color: '#0A856A',
                label: __('Edits received'),
            };
        case 'DELIVERY_STATE_CANCELLED':
            return {
                color: '#D24345',
                label: __('Canceled'),
            };
        case 'DELIVERY_STATE_APPOINTMENT_SCHEDULED':
            return {
                color: '#0A856A',
                label: __('Appointment scheduled'),
            };
        case 'DELIVERY_STATE_APPOINTMENT_IN_SESSION':
            return {
                color: '#0A856A',
                label: __('Appointment in session'),
            };
        case 'DELIVERY_STATE_APPOINTMENT_PREPARING':
            return {
                color: '#0A856A',
                label: __('Preparing for appointment'),
            };
        default:
            return { color: '#000', label: '' }; // Default color and empty label
    }
};
