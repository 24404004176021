/* eslint-disable */

import * as Apollo from '@apollo/client';
import { DocumentNode } from 'graphql';
import * as Types from '@99designs/graph-utils/types';

export type UpdateAppointmentMutationVariables = Types.Exact<{
    input: Types.UpdateAppointmentInput;
}>;

export type UpdateAppointmentMutation = {
    __typename: 'Mutation';
    updateAppointment: { __typename: 'GenericRepsonse'; success: boolean };
};

export const UpdateAppointmentDocument: DocumentNode = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'UpdateAppointment' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'UpdateAppointmentInput' },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'updateAppointment' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'input' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'success' } },
                            ],
                        },
                    },
                ],
            },
        },
    ],
};
export type UpdateAppointmentMutationFn = Apollo.MutationFunction<
    UpdateAppointmentMutation,
    UpdateAppointmentMutationVariables
>;

/**
 * __useUpdateAppointmentMutation__
 *
 * To run a mutation, you first call `useUpdateAppointmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAppointmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAppointmentMutation, { data, loading, error }] = useUpdateAppointmentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateAppointmentMutation(
    baseOptions?: Apollo.MutationHookOptions<
        UpdateAppointmentMutation,
        UpdateAppointmentMutationVariables
    >
) {
    return Apollo.useMutation<UpdateAppointmentMutation, UpdateAppointmentMutationVariables>(
        UpdateAppointmentDocument,
        baseOptions
    );
}
export type UpdateAppointmentMutationHookResult = ReturnType<typeof useUpdateAppointmentMutation>;
export type UpdateAppointmentMutationResult = Apollo.MutationResult<UpdateAppointmentMutation>;
export type UpdateAppointmentMutationOptions = Apollo.BaseMutationOptions<
    UpdateAppointmentMutation,
    UpdateAppointmentMutationVariables
>;
