import React from 'react';
import { Box, Divider, FlexBox, Section, Typography, tokensRaw } from '@vp/swan';
import { FormattedHTMLMessage } from '@99designs/design-services-common';
import { DrawerContent, DrawerFooter, DrawerHeader } from '@99designs/design-services-layouts';
import { __ } from '@99designs/i18n';
import { RevisionRequestPanelData } from '../../../../hooks';
import { ImageRef } from '../../DesignPreviewCanvas/ImageRef';
import { ThumbnailBarCompact } from '../../DesignPreviewCanvas/ThumbnailBar';
import { useDeliveryQueryParams } from '../../DesignPreviewCanvas/useDeliveryQueryParams';
import { FileWithDrawer } from '../../FileWithDrawer';
import { PinComment } from '../../PinComment/PinComment';
import { TruncateText } from '../../TruncateText';
import { useViewNavigation } from '../DrawerRevisionRequestViewContext';

export interface ViewRevisionRequestProps {
    revisionRequest: RevisionRequestPanelData;
    deliveryVersionId: string;
    images: { [key: number]: ImageRef[] };
    pins: PinComment[];
    onClickBack: () => void;
}

export const ViewRevisionRequest: React.FC<ViewRevisionRequestProps> = ({
    revisionRequest,
    images,
    pins,
    deliveryVersionId,
    onClickBack,
}) => {
    const { generalComment, pinnedComments } = revisionRequest;

    const hasPinnedComments = pinnedComments.length > 0;
    const singularPinnedComments = __('{pinnedComments} pin comment');
    const pluralPinnedComments = __('{pinnedComments} pin comments');

    const hasMultipleVariations = Object.keys(images).length > 1;
    const { deliveryVariation, setDeliveryVariationQueryParams } = useDeliveryQueryParams(images);
    const { navigateTo } = useViewNavigation();

    return (
        <>
            <DrawerHeader onClickBack={onClickBack}>
                <Typography fontSkin="body-standard-bold">
                    <FormattedHTMLMessage
                        message={'Comments on Version {deliveryVersionId}'}
                        values={{ deliveryVersionId }}
                    />
                </Typography>
            </DrawerHeader>
            <DrawerContent padding="0">
                {hasMultipleVariations && (
                    <Section padding="5">
                        <ThumbnailBarCompact
                            images={images}
                            pins={pins}
                            deliveryVariation={deliveryVariation}
                            setDeliveryVariationQueryParams={setDeliveryVariationQueryParams}
                        />
                    </Section>
                )}

                {hasMultipleVariations && generalComment !== '' && <Divider />}

                {generalComment && (
                    <Section padding="6" onClick={() => navigateTo({ view: 'viewGeneralComment' })}>
                        <FlexBox gap="3">
                            <Typography fontSkin="body-small-bold" style={{ flex: '1 0 auto' }}>
                                {__('General comment:')}
                            </Typography>
                            <TruncateText fontSkin="body-small" $maxLines={2} $maxHeight="48px">
                                {generalComment}
                            </TruncateText>
                        </FlexBox>
                    </Section>
                )}

                {revisionRequest.files.length > 0 && (
                    <>
                        <Divider />

                        <Section paddingX="6" paddingY="5">
                            <FlexBox gap="3" alignItems="center">
                                <Typography fontSkin="body-small-bold">{__('Uploads:')}</Typography>
                                <FlexBox
                                    gap="5"
                                    alignItems="center"
                                    style={{
                                        overflowX: 'auto',
                                        marginRight: `-${tokensRaw.SwanSemSpace5}`,
                                        paddingRight: tokensRaw.SwanSemSpace5,
                                    }}
                                >
                                    {revisionRequest.files.map((file, index) => (
                                        <Box style={{ lineHeight: 0 }} key={index}>
                                            <FileWithDrawer
                                                file={file}
                                                showDownload={true}
                                                showDelete={false}
                                            />
                                        </Box>
                                    ))}
                                </FlexBox>
                            </FlexBox>
                        </Section>
                    </>
                )}
            </DrawerContent>

            {hasPinnedComments && (
                <DrawerFooter>
                    <Typography fontSkin="body-small-bold">
                        <FormattedHTMLMessage
                            message={
                                pinnedComments.length === 1
                                    ? singularPinnedComments
                                    : pluralPinnedComments
                            }
                            values={{ pinnedComments: pinnedComments.length }}
                        />
                    </Typography>
                </DrawerFooter>
            )}
        </>
    );
};
