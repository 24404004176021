import React from 'react';
import { Button, FlexBox, H3, Icon, Typography } from '@vp/swan';
import { DrawerContent, DrawerFooter, DrawerHeader } from '@99designs/design-services-layouts';
import { __ } from '@99designs/i18n';

export interface ViewCommentProps {
    title: string;
    comment: string;
    interactable: boolean;
    onDelete: () => void;
    onEdit: () => void;
    onBack: () => void;
}

export const ViewComment: React.FC<ViewCommentProps> = ({
    title,
    comment,
    interactable,
    onEdit,
    onDelete,
    onBack,
}) => {
    const [showDeleteConfirmation, setShowDeleteConfirmation] = React.useState(false);
    if (showDeleteConfirmation) {
        return (
            <>
                <DrawerHeader onClickBack={() => setShowDeleteConfirmation(false)} />
                <DrawerContent>
                    <H3 fontSkin="title-subsection">
                        {__('Are you sure you want to delete this comment?')}
                    </H3>
                    <FlexBox flexDirection="column" gap="3">
                        <Button onClick={onDelete} skin="primary">
                            {__('Delete comment')}
                        </Button>
                        <Button onClick={() => setShowDeleteConfirmation(false)}>
                            {__('Cancel')}
                        </Button>
                    </FlexBox>
                </DrawerContent>
            </>
        );
    }

    return (
        <>
            <DrawerHeader onClickBack={onBack}>
                <H3 fontSkin="body-standard-bold" marginBottom="0">
                    {title}
                </H3>
            </DrawerHeader>
            <DrawerContent>
                <Typography fontSkin="body-standard" style={{ whiteSpace: 'pre-wrap' }}>
                    {comment}
                </Typography>
            </DrawerContent>

            {interactable && (
                <DrawerFooter>
                    <FlexBox justifyContent="flex-end" gap="between-actions">
                        <Button
                            onClick={() => setShowDeleteConfirmation(true)}
                            buttonShape="round"
                            aria-label={__('Delete')}
                            skin="tertiary"
                        >
                            <Icon iconType="delete" />
                        </Button>
                        <Button onClick={onEdit} skin="primary">
                            {__('Edit')}
                        </Button>
                    </FlexBox>
                </DrawerFooter>
            )}
        </>
    );
};
