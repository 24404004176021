import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Popover } from 'react-tiny-popover';
import { KeepScale } from 'react-zoom-pan-pinch';
import {
    Box,
    Button,
    FlexBox,
    Icon,
    Spinner,
    TextArea,
    Typography,
    tokens,
    tokensRaw,
    useScreenClass,
} from '@vp/swan';
import styled from 'styled-components';
import { useSwanModeContext } from '@99designs/design-services-layouts';
import { __, formatMessage } from '@99designs/i18n';
import { useRevisionRequestQueryParams } from '../DesignRevision/useRevisionRequestQueryParams';
import { useViewNavigation } from '../DrawerRevisionRequest';

// Types
export interface PinComment {
    commentText: string;
    viewIndex: number;
    xPercent: number;
    yPercent: number;
    selectedSurface: number;
    commentId?: string;
    deliveryVariationId: number;
}

// Styled components
const PinContainer = styled.span`
    display: block;
    position: absolute;
    // Position pin edge at the point of the click
    transform: translate(-100%, -100%);
`;

const PopoverContent = styled(Box)`
    min-width: 270px;
    max-width: 375px;
    width: 100%;
    margin: ${tokensRaw.SwanSemSpace2};
    background: ${tokensRaw.SwanBaseColorWhite};
    border: 1px solid ${tokensRaw.SwanBaseColorGrey300};
    border-radius: ${tokens.SwanSemBorderRadiusContainer};
    box-shadow: ${tokensRaw.SwanSemElevationStandard};
`;

// A bespoke component for our pins
const Pin = styled.button<{ active: boolean }>`
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${(p) =>
        p.active ? tokens.SwanSemColorTextInfoStrongPaired : tokens.SwanSemColorTextStandard};
    sem/color/text/info-strong-paired
    font-size: ${tokens.SwanSemFontSizeStandard};
    font-weight: ${tokens.SwanBaseFontWeightBold};
    background-color: ${(p) =>
        p.active ? tokens.SwanSemColorBgSelectedAccentActive : tokens.SwanSemColorBgSelectedAccent};
    border-radius: ${tokensRaw.SwanSemSpace6} ${tokensRaw.SwanSemSpace6} 0 ${
    tokensRaw.SwanSemSpace6
};
// This needs to be a raw token value to get the shape rounded based on the width and height set on Pin
    border: 2px solid ${tokens.SwanSemColorIconStandard};
    box-shadow: ${tokens.SwanSemElevationStandard};
    transition: background 0.15s ease-in, color 0.15s ease-in;

    &:hover {
        background-color: ${tokens.SwanSemColorBgSelectedAccentHover};
        color: ${tokensRaw.SwanSemColorTextStandard};
    }

    &:focus {
        background-color: ${tokens.SwanSemColorBgSelectedAccentActive};
        outline: ${tokens.SwanSemFocusColorOuter};
        color: ${tokens.SwanSemColorTextInfoStrongPaired};
    }
`;

export const isAndroid = () => {
    return /Android/i.test(navigator.userAgent);
};

export const getDataPopoverTarget = (x: number, y: number): string => {
    // Using both the xPercent and yPercent value of the PinComment the create a unique string
    // Cannot use periods so we replace them with empty string
    // We use both the X and Y values to better guarantee uniqueness
    const xString = x.toString().replace('.', '');
    const yString = y.toString().replace('.', '');

    return `pin-comment-popover-${xString}${yString}`;
};

/**
 * PinCommentButtons: Determines which action buttons are visible for a pin
 * comment
 */
const PinCommentButtons = ({
    disabled,
    showRemoveButton,
    onSaveClick,
    onRemoveClick,
}: {
    disabled: boolean;
    showRemoveButton: boolean;
    onSaveClick: () => Promise<void>;
    onRemoveClick: () => Promise<void>;
}) => {
    const [saving, setSaving] = useState(false);
    const [deleting, setDeleting] = useState(false);
    return (
        <>
            {showRemoveButton && (
                <Button
                    skin="tertiary"
                    onClick={async () => {
                        setDeleting(true);
                        await onRemoveClick();
                        setDeleting(false);
                    }}
                    buttonShape="round"
                >
                    {deleting ? (
                        <Spinner accessibleText={__('Loading...')} size="mini" />
                    ) : (
                        <Icon iconType={'delete'} alt={__('Remove')} size={'20p'} />
                    )}
                </Button>
            )}
            <Button
                onClick={async () => {
                    setSaving(true);
                    await onSaveClick();
                    setSaving(false);
                }}
                disabled={disabled}
                skin="primary"
            >
                {saving ? <Spinner accessibleText={__('Loading...')} size="mini" /> : __('Save')}
            </Button>
        </>
    );
};

/**
 * AutoFocusTextArea: Text area component for a new or editable pin comment
 */
function AutoFocusTextArea({
    value,
    onChange,
    heading,
}: {
    value: string;
    onChange: (value: string) => void;
    heading: string;
}) {
    const textRef = useRef<HTMLTextAreaElement | null>(null);

    useEffect(() => {
        textRef?.current?.focus();
    }, []);

    return (
        <TextArea
            ref={textRef}
            value={value}
            onChange={(event: React.ChangeEvent<HTMLTextAreaElement>): void =>
                onChange(event.target.value)
            }
            fullWidth={true}
            rows={3}
            resize="vertical"
            aria-label={heading}
            style={{ fontSize: tokensRaw.SwanBaseFontSize100 }}
        />
    );
}

/**
 * useCommentPopoverState: Function to determine and update the state of the
 * popover/modal
 */
export function useCommentPopoverState(
    pin: PinComment,
    isNewComment: boolean,
    onPinClose?: () => void
) {
    const [inputText, setInputText] = useState(pin.commentText || '');
    const { commentId, pinClickHandler, closeHandler } = useRevisionRequestQueryParams(pin);
    const [popoverIsOpen, setPopoverIsOpen] = useState(false);

    useEffect(() => {
        setPopoverIsOpen((pin.commentId === commentId && commentId != null) || isNewComment);
        setInputText(pin.commentText);
    }, [pin.commentId, commentId, isNewComment, pin.commentText]);

    const onClose = () => {
        if (onPinClose) {
            onPinClose();
        }
        closeHandler(pin.commentId);
        setPopoverIsOpen(false);
    };

    const onOpen = () => {
        pinClickHandler();
        setPopoverIsOpen(true);
    };

    const onClickOutside = () => (isNewComment && inputText ? undefined : onClose());

    const onToggle = () => (popoverIsOpen ? onClose() : onOpen());

    return {
        inputText,
        setInputText,
        popoverIsOpen,
        setPopoverIsOpen,
        onClose,
        onOpen,
        onClickOutside,
        onToggle,
    };
}

/**
 * PinCommentBadgePopover: A floating popover implementation for pin comments,
 * used on desktop
 */
export const PinCommentBadgePopover = ({
    pin,
    isNewComment = false,
    isReadOnly = false,
    handleRemove,
    handleAddUpdate,
    onPinClose,
}: PinCommentBadgeProps) => {
    const { inputText, setInputText, popoverIsOpen, onClose, onClickOutside, onToggle } =
        useCommentPopoverState(pin, isNewComment, onPinClose);
    const popoverHeading = isNewComment ? __('Add comment') : __('Edit comment');
    const { isCompactMode } = useSwanModeContext();

    return (
        <PinContainer
            data-testid="pin-comment-badge-popover"
            style={{
                left: `${pin.xPercent}%`,
                top: `${pin.yPercent}%`,
            }}
        >
            <Popover
                isOpen={popoverIsOpen}
                onClickOutside={onClickOutside}
                positions={['top', 'bottom', 'left', 'right']}
                containerStyle={{ zIndex: '3' }}
                content={() => (
                    <PopoverContent padding="5">
                        <Box compactMode={isCompactMode}>
                            {isReadOnly ? (
                                <FlexBox alignItems="center" justifyContent="space-between">
                                    <Typography fontSkin="body-standard">
                                        {pin.commentText}
                                    </Typography>
                                </FlexBox>
                            ) : (
                                <>
                                    <FlexBox
                                        alignItems="center"
                                        justifyContent="space-between"
                                        marginBottom="between-actions"
                                    >
                                        <Typography fontSkin="title-subsection">
                                            {popoverHeading}
                                        </Typography>
                                        <Button
                                            skin="tertiary"
                                            onClick={onClose}
                                            buttonShape="round"
                                        >
                                            <Icon
                                                iconType="close"
                                                aria-label={__('Close comment dialog')}
                                            />
                                        </Button>
                                    </FlexBox>
                                    <AutoFocusTextArea
                                        value={inputText}
                                        onChange={(value) => setInputText(value)}
                                        heading={popoverHeading}
                                    />
                                    <FlexBox
                                        marginTop="to-actions"
                                        gap="between-actions"
                                        justifyContent="flex-end"
                                    >
                                        <PinCommentButtons
                                            disabled={!inputText}
                                            showRemoveButton={!isNewComment || inputText.length > 0}
                                            onSaveClick={async () => {
                                                await handleAddUpdate({
                                                    ...pin,
                                                    commentText: inputText,
                                                });
                                                onClose();
                                            }}
                                            onRemoveClick={async () => {
                                                await handleRemove(pin);
                                                onClose();
                                            }}
                                        />
                                    </FlexBox>
                                </>
                            )}
                        </Box>
                    </PopoverContent>
                )}
            >
                <KeepScale style={{ transformOrigin: 'right bottom' }}>
                    <Pin
                        onClick={onToggle}
                        role="button"
                        aria-label={`Comment ${pin.viewIndex}`}
                        active={popoverIsOpen}
                    >
                        {isNewComment ? <Icon iconType="plus" size="20p" /> : pin.viewIndex}
                    </Pin>
                </KeepScale>
            </Popover>
        </PinContainer>
    );
};

/**
 * PinCommentBadgeMobile: A mobile implementation for pin comments
 * uses ViewContext to navigate between views in DrawerRevisionRequest
 */
export const PinCommentBadgeMobile = ({
    pin,
    isNewComment = false,
    isReadOnly = false,
    handleRemove,
    handleAddUpdate,
    onPinClose,
}: PinCommentBadgeProps) => {
    const { inputText, popoverIsOpen, onClose, onOpen } = useCommentPopoverState(
        pin,
        isNewComment,
        onPinClose
    );
    const { current, navigateTo, goBack, updateCurrent } = useViewNavigation();

    const navigateToEdit = useCallback(() => {
        navigateTo({
            view: 'editPinnedComment',
            data: {
                comment: inputText,
                onUpdate: async (comment) => {
                    await handleAddUpdate({ ...pin, commentText: comment ?? '' });
                    if (!isNewComment) {
                        updateCurrent({ comment });
                    }
                },
                onClose,
            },
        });
    }, [navigateTo, inputText, handleAddUpdate, pin, onClose, updateCurrent, isNewComment]);

    const navigateToView = useCallback(() => {
        navigateTo({
            view: 'viewPinnedComment',
            data: {
                title: formatMessage(__('Comment {commentNumber}'), {
                    commentNumber: pin.viewIndex,
                }),
                comment: inputText,
                interactable: !isReadOnly,
                onEdit: () => {
                    navigateToEdit();
                    onClose();
                },
                onDelete: async () => {
                    await handleRemove(pin);
                    onClose();
                    goBack();
                },
                onBack: () => {
                    onClose();
                    goBack();
                },
            },
        });
        onClose();
    }, [navigateTo, inputText, isReadOnly, navigateToEdit, handleRemove, pin, onClose, goBack]);

    useEffect(() => {
        if (popoverIsOpen && isNewComment && current?.view !== 'editPinnedComment') {
            navigateToEdit();
        }
        if (popoverIsOpen && !isNewComment) {
            navigateToView();
        }
    }, [navigateToEdit, navigateToView, popoverIsOpen, isNewComment, current]);

    return (
        <PinContainer
            data-testid="pin-comment-badge-mobile"
            style={{
                left: `${pin.xPercent}%`,
                top: `${pin.yPercent}%`,
            }}
        >
            <KeepScale style={{ transformOrigin: 'right bottom' }}>
                <Pin
                    onClick={onOpen}
                    role="button"
                    aria-label={`Comment ${pin.viewIndex}`}
                    active={popoverIsOpen}
                >
                    {isNewComment ? <Icon iconType="plus" size="20p" /> : pin.viewIndex}
                </Pin>
            </KeepScale>
        </PinContainer>
    );
};

export interface PinCommentBadgeProps {
    isNewComment?: boolean;
    showPopover?: boolean;
    isReadOnly?: boolean;
    pin: PinComment;
    handleRemove: (pin: PinComment) => Promise<void>;
    handleAddUpdate: (pin: PinComment) => Promise<void>;
    onPinClose?: () => void;
}

/**
 * Returns a desktop or mobile pin comment based on screensize
 */
export const PinCommentBadge = ({ pin, isReadOnly, ...props }: PinCommentBadgeProps) => {
    const screenSize = useScreenClass();

    return screenSize === 'xs' ? (
        <PinCommentBadgeMobile pin={pin} isReadOnly={isReadOnly} {...props} />
    ) : (
        <PinCommentBadgePopover pin={pin} isReadOnly={isReadOnly} {...props} />
    );
};
