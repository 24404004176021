/* eslint-disable */

import * as Apollo from '@apollo/client';
import { DocumentNode } from 'graphql';
import * as Types from '@99designs/graph-utils/types';

export type DeliveryVariationSurfaceCommentFragment = {
    __typename: 'DeliveryVariationSurfaceComment';
    commentId: string;
    deliveryVariationId: number;
    comment: {
        __typename: 'SurfaceComment';
        surfaceIndexId: number;
        surfaceX: number;
        surfaceY: number;
        message: string;
    };
};

export type CreatePinCommentMutationVariables = Types.Exact<{
    input: Types.CreateRevisionRequestSurfaceCommentInput;
}>;

export type CreatePinCommentMutation = {
    __typename: 'Mutation';
    createRevisionRequestSurfaceComment: {
        __typename: 'DeliveryVariationSurfaceComment';
    } & DeliveryVariationSurfaceCommentFragment;
};

export type EditCommentMutationVariables = Types.Exact<{
    input: Types.EditCommentInput;
}>;

export type EditCommentMutation = {
    __typename: 'Mutation';
    editComment: {
        __typename: 'DeliveryVariationSurfaceComment';
    } & DeliveryVariationSurfaceCommentFragment;
};

export type DeleteCommentMutationVariables = Types.Exact<{
    commentId: Types.Scalars['ID'];
}>;

export type DeleteCommentMutation = {
    __typename: 'Mutation';
    deleteComment: { __typename: 'GenericRepsonse'; success: boolean };
};

export const DeliveryVariationSurfaceCommentFragmentDoc: DocumentNode = {
    kind: 'Document',
    definitions: [
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'DeliveryVariationSurfaceComment' },
            typeCondition: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'DeliveryVariationSurfaceComment' },
            },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'commentId' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'deliveryVariationId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'comment' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'surfaceIndexId' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'surfaceX' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'surfaceY' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                            ],
                        },
                    },
                ],
            },
        },
    ],
};
export const CreatePinCommentDocument: DocumentNode = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'CreatePinComment' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: {
                                kind: 'Name',
                                value: 'CreateRevisionRequestSurfaceCommentInput',
                            },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'createRevisionRequestSurfaceComment' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'input' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'FragmentSpread',
                                    name: {
                                        kind: 'Name',
                                        value: 'DeliveryVariationSurfaceComment',
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'DeliveryVariationSurfaceComment' },
            typeCondition: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'DeliveryVariationSurfaceComment' },
            },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'commentId' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'deliveryVariationId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'comment' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'surfaceIndexId' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'surfaceX' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'surfaceY' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                            ],
                        },
                    },
                ],
            },
        },
    ],
};
export type CreatePinCommentMutationFn = Apollo.MutationFunction<
    CreatePinCommentMutation,
    CreatePinCommentMutationVariables
>;

/**
 * __useCreatePinCommentMutation__
 *
 * To run a mutation, you first call `useCreatePinCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePinCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPinCommentMutation, { data, loading, error }] = useCreatePinCommentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreatePinCommentMutation(
    baseOptions?: Apollo.MutationHookOptions<
        CreatePinCommentMutation,
        CreatePinCommentMutationVariables
    >
) {
    return Apollo.useMutation<CreatePinCommentMutation, CreatePinCommentMutationVariables>(
        CreatePinCommentDocument,
        baseOptions
    );
}
export type CreatePinCommentMutationHookResult = ReturnType<typeof useCreatePinCommentMutation>;
export type CreatePinCommentMutationResult = Apollo.MutationResult<CreatePinCommentMutation>;
export type CreatePinCommentMutationOptions = Apollo.BaseMutationOptions<
    CreatePinCommentMutation,
    CreatePinCommentMutationVariables
>;
export const EditCommentDocument: DocumentNode = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'EditComment' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'EditCommentInput' },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'editComment' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'input' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'FragmentSpread',
                                    name: {
                                        kind: 'Name',
                                        value: 'DeliveryVariationSurfaceComment',
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'DeliveryVariationSurfaceComment' },
            typeCondition: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'DeliveryVariationSurfaceComment' },
            },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'commentId' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'deliveryVariationId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'comment' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'surfaceIndexId' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'surfaceX' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'surfaceY' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                            ],
                        },
                    },
                ],
            },
        },
    ],
};
export type EditCommentMutationFn = Apollo.MutationFunction<
    EditCommentMutation,
    EditCommentMutationVariables
>;

/**
 * __useEditCommentMutation__
 *
 * To run a mutation, you first call `useEditCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editCommentMutation, { data, loading, error }] = useEditCommentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditCommentMutation(
    baseOptions?: Apollo.MutationHookOptions<EditCommentMutation, EditCommentMutationVariables>
) {
    return Apollo.useMutation<EditCommentMutation, EditCommentMutationVariables>(
        EditCommentDocument,
        baseOptions
    );
}
export type EditCommentMutationHookResult = ReturnType<typeof useEditCommentMutation>;
export type EditCommentMutationResult = Apollo.MutationResult<EditCommentMutation>;
export type EditCommentMutationOptions = Apollo.BaseMutationOptions<
    EditCommentMutation,
    EditCommentMutationVariables
>;
export const DeleteCommentDocument: DocumentNode = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'DeleteComment' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'commentId' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'deleteComment' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'commentId' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'commentId' },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'success' } },
                            ],
                        },
                    },
                ],
            },
        },
    ],
};
export type DeleteCommentMutationFn = Apollo.MutationFunction<
    DeleteCommentMutation,
    DeleteCommentMutationVariables
>;

/**
 * __useDeleteCommentMutation__
 *
 * To run a mutation, you first call `useDeleteCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCommentMutation, { data, loading, error }] = useDeleteCommentMutation({
 *   variables: {
 *      commentId: // value for 'commentId'
 *   },
 * });
 */
export function useDeleteCommentMutation(
    baseOptions?: Apollo.MutationHookOptions<DeleteCommentMutation, DeleteCommentMutationVariables>
) {
    return Apollo.useMutation<DeleteCommentMutation, DeleteCommentMutationVariables>(
        DeleteCommentDocument,
        baseOptions
    );
}
export type DeleteCommentMutationHookResult = ReturnType<typeof useDeleteCommentMutation>;
export type DeleteCommentMutationResult = Apollo.MutationResult<DeleteCommentMutation>;
export type DeleteCommentMutationOptions = Apollo.BaseMutationOptions<
    DeleteCommentMutation,
    DeleteCommentMutationVariables
>;
