import { BoundedContent, Box, Link, Typography } from '@vp/swan';
import { FormattedReactMessage, __vistaRoute } from '@99designs/design-services-common';
import { __ } from '@99designs/i18n';
import Ghost from './Ghost.png';

export const InternalServer: React.FC = () => {
    return (
        <BoundedContent>
            <Box marginTop={10} marginBottom={10} textAlign="center">
                <Box marginTop={8}>
                    <img alt="" src={Ghost} width={128} height={128} />
                </Box>
                <Box marginTop={8}>
                    <Typography fontSize="x2large" fontWeight="bold">
                        {__('Uh oh. There was an unexpected error. Try refreshing the page')}
                    </Typography>
                </Box>
                <Box marginTop={'7'}>
                    <FormattedReactMessage
                        message={__(
                            'Our team has been alerted and are looking into the issue. If refreshing doesn’t fix the problem, please visit our <Link>help centre</Link>.'
                        )}
                        replacements={{
                            Link: (
                                <Link
                                    to={__vistaRoute('/customer-care/help-center/')}
                                    target="_blank"
                                />
                            ),
                        }}
                    />
                </Box>
            </Box>
        </BoundedContent>
    );
};
