import i18n from 'i18next';
import { createKey } from './helpers';

export interface TranslationObject {
    value: string;
    _context?: string;
}

// translation function
export function __(
    translationKey: string,
    interpolationMap?: Record<string, unknown> | undefined
): string {
    const updatedKey = createKey(translationKey);
    const translation = i18n.t(updatedKey, interpolationMap);

    if (translation && typeof translation === 'object' && 'value' in translation) {
        return (translation as TranslationObject).value;
    }

    if (updatedKey !== translation) {
        return translation;
    }

    return i18n.t(translationKey, interpolationMap);
}

// function responsible for the localisation of site urls
export function __url(translationKey: string): string {
    return i18n.t(translationKey);
}

// temporary function responsible for the localisation of products
export function __product(translationKey: string): string {
    return i18n.t(translationKey);
}

// responsible for the localisation of images
export function __image(translationKey: string): string {
    return i18n.t(translationKey);
}
