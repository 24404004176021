import { useTracking } from '@vp/react-tracking';
import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Divider, FlexBox, Link, Typography, tokens } from '@vp/swan';
import { getCurrentCulture } from '@99designs/design-services-common';
import { __vistaRoute } from '@99designs/design-services-common';
import { AppointmentEventType } from '@99designs/graph-utils/types';
import { FormattedHTMLMessage, __ } from '@99designs/i18n';
import { useCollaborationContext } from '../../../context';
import { getAnalyticsData } from '../../../util/getAnalyticsData';
import { CalendarBadge } from '../CalendarBadge/CalendarBadge';
import { COLLABORATE_BASE_PATH } from '../constants';
import { CollaborationEvent } from './CollaborationEvent';

export interface AppointmentEventProps {
    type: AppointmentEventType;
    dateTime: string;
    designerName: string;
    zoomMeetingUrl: string;
    collaborationPublicId: string;
    canReschedule: boolean;
    deliveryVersionId: number;
}

export const AppointmentEvent: React.FC<AppointmentEventProps> = ({
    dateTime,
    designerName,
    zoomMeetingUrl,
    collaborationPublicId,
    type,
    canReschedule,
    deliveryVersionId,
}) => {
    const { trackingProperties } = useCollaborationContext();
    const { tracking } = useTracking();
    const navigate = useNavigate();

    const currentCulture = useMemo(() => getCurrentCulture(), []);
    const humanTime = new Date(dateTime) // ISO timestamp
        .toLocaleDateString(currentCulture, {
            weekday: 'short',
            month: 'short',
            day: 'numeric',
            hour12: true,
            hour: 'numeric',
            minute: 'numeric',
            timeZoneName: 'long',
        })
        .replace(/^0/, '')
        .replace('a.m.', 'AM')
        .replace('p.m.', 'PM');

    const configMap: {
        [K in AppointmentEventType]: {
            header: string;
            body: React.ReactNode;
            showAppointmentDetails: boolean;
        };
    } = {
        APPOINTMENT_EVENT_TYPE_CONFIRMED: {
            header: __(
                'Your DesignLive video call is confirmed! Please find your appointment details and link to join the session below.'
            ),
            body: (
                <FormattedHTMLMessage
                    message={__('DesignLive video call with {designerName} on <b> {humanTime}</b>')}
                    values={{ designerName, humanTime }}
                />
            ),
            showAppointmentDetails: true,
        },
        APPOINTMENT_EVENT_TYPE_RESCHEDULED: {
            header: __(
                'Your DesignLive video call is confirmed! Please find your appointment details and link to join the session below.'
            ),
            body: __('This DesignLive video call was rescheduled and a new Zoom link was shared'),
            showAppointmentDetails: false,
        },
        APPOINTMENT_EVENT_TYPE_RESCHEDULED_CONFIRMED: {
            header: __(
                'Your DesignLive video call has been rescheduled. Please find your new appointment details and link to join the session below.'
            ),
            body: (
                <FormattedHTMLMessage
                    message={__('DesignLive video call with {designerName} on <b> {humanTime}</b>')}
                    values={{ designerName, humanTime }}
                />
            ),
            showAppointmentDetails: true,
        },
    };

    const config = configMap[type];
    if (!config) {
        // in case of unknown type
        return null;
    }

    return (
        <CollaborationEvent showAvatar={true} color={tokens.SwanSemColorBgHelp}>
            <FlexBox flexDirection={'column'} gap={4} padding={2}>
                <Typography fontSize={'standard'} data-testid="header">
                    {config.header}
                </Typography>
                <Divider />
                <FlexBox gap={4} marginBottom={2}>
                    <CalendarBadge dateTime={dateTime} cancelled={!config.showAppointmentDetails} />
                    <Typography fontSize={'standard'} data-testid="body">
                        {config.body}
                    </Typography>
                </FlexBox>
                {config.showAppointmentDetails && (
                    <>
                        <Button
                            fontSkin={'body-standard-bold'}
                            width="full-width"
                            skin="primary"
                            render={(props) => (
                                <Link
                                    to={zoomMeetingUrl}
                                    target="_blank"
                                    className={props.className}
                                    aria-label={__('Join DesignLive session with Zoom')}
                                    onClick={() => {
                                        tracking.track('Navigation Clicked', {
                                            ...getAnalyticsData({
                                                collaborationPublicId,
                                                label: 'Content: Collaboration Activity Event',
                                                destinationPath: '/zoom',
                                                ctaType: 'join-session',
                                                ctaValue: 'Join session with Zoom',
                                            }),
                                            ...trackingProperties,
                                        });
                                    }}
                                >
                                    {props.children}
                                </Link>
                            )}
                        >
                            {__('Join session with Zoom')}
                        </Button>
                        {canReschedule && (
                            <Button
                                fontSkin={'body-standard-bold'}
                                width="full-width"
                                aria-label={__('Reschedule DesignLive appointment')}
                                onClick={() => {
                                    navigate(
                                        `${COLLABORATE_BASE_PATH}/${collaborationPublicId}/delivery/${deliveryVersionId}/appointment`
                                    );
                                    tracking.track('Navigation Clicked', {
                                        ...getAnalyticsData({
                                            collaborationPublicId,
                                            label: 'Content: Collaboration Activity Event',
                                            destinationPath: '/graphic-design/dashboard',
                                            ctaType: 'reschedule-session',
                                            ctaValue: 'Reschedule appointment',
                                        }),
                                        ...trackingProperties,
                                    });
                                }}
                            >
                                {__('Reschedule appointment')}
                            </Button>
                        )}
                    </>
                )}
            </FlexBox>
        </CollaborationEvent>
    );
};
