/* eslint-disable */

import * as Apollo from '@apollo/client';
import { DocumentNode } from 'graphql';
import * as Types from '@99designs/graph-utils/types';

export type AppointmentQueryVariables = Types.Exact<{
    collaborationPublicId: Types.Scalars['String'];
    deliveryVersionId: Types.Scalars['Int'];
}>;

export type AppointmentQuery = {
    __typename: 'Query';
    collaborationAppointment: {
        __typename: 'Appointment';
        id: number;
        startTime: any;
        durationMinutes: number;
        timezone: string;
        designerName: string;
        designerCalendarId: number;
        zoomMeetingUrl: string;
        canBeRescheduled: boolean;
        rescheduleUrl: string;
    };
};

export const AppointmentDocument: DocumentNode = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'Appointment' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'collaborationPublicId' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'deliveryVersionId' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'collaborationAppointment' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'collaborationPublicId' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'collaborationPublicId' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'deliveryVersionId' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'deliveryVersionId' },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'startTime' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'durationMinutes' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'timezone' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'designerName' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'designerCalendarId' },
                                },
                                { kind: 'Field', name: { kind: 'Name', value: 'zoomMeetingUrl' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'canBeRescheduled' },
                                },
                                { kind: 'Field', name: { kind: 'Name', value: 'rescheduleUrl' } },
                            ],
                        },
                    },
                ],
            },
        },
    ],
};

/**
 * __useAppointmentQuery__
 *
 * To run a query within a React component, call `useAppointmentQuery` and pass it any options that fit your needs.
 * When your component renders, `useAppointmentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAppointmentQuery({
 *   variables: {
 *      collaborationPublicId: // value for 'collaborationPublicId'
 *      deliveryVersionId: // value for 'deliveryVersionId'
 *   },
 * });
 */
export function useAppointmentQuery(
    baseOptions: Apollo.QueryHookOptions<AppointmentQuery, AppointmentQueryVariables>
) {
    return Apollo.useQuery<AppointmentQuery, AppointmentQueryVariables>(
        AppointmentDocument,
        baseOptions
    );
}
export function useAppointmentLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<AppointmentQuery, AppointmentQueryVariables>
) {
    return Apollo.useLazyQuery<AppointmentQuery, AppointmentQueryVariables>(
        AppointmentDocument,
        baseOptions
    );
}
export type AppointmentQueryHookResult = ReturnType<typeof useAppointmentQuery>;
export type AppointmentLazyQueryHookResult = ReturnType<typeof useAppointmentLazyQuery>;
export type AppointmentQueryResult = Apollo.QueryResult<
    AppointmentQuery,
    AppointmentQueryVariables
>;
