export const Pins = () => (
    <svg
        width="128"
        height="64"
        viewBox="0 0 128 64"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g clipPath="url(#clip0_7322_6350)">
            <path
                d="M67.3683 25.4958L74.0717 36.479C74.4784 37.1454 73.9988 38 73.2181 38H54.8356C54.0436 38 53.5659 37.1232 53.9954 36.4577L58.8737 28.8993C59.2584 28.3032 60.1241 28.2862 60.532 28.8666L61.4926 30.2333C61.9214 30.8435 62.8431 30.7865 63.1935 30.1282L65.632 25.5469C65.9958 24.8634 66.9649 24.8349 67.3683 25.4958Z"
                stroke="black"
            />
            <circle cx="59.5" cy="23.5" r="1.5" stroke="black" />
            <path
                d="M111 4H17C16.4477 4 16 4.44772 16 5V55C16 55.5523 16.4477 56 17 56H111C111.552 56 112 55.5523 112 55V5C112 4.44772 111.552 4 111 4Z"
                stroke="black"
            />
            <path
                d="M27.2191 46.0448C27.408 44.3235 25.0996 43.2098 24.2341 42.8575C24.2899 41.1301 22.1637 40.1759 21.5036 39.9194L24.1895 35.212C25.8183 32.3574 22.0833 30.6158 20.4545 33.4704C20.4545 33.4704 12.9339 47.3715 13.0209 46.4985C13.108 45.6255 13.3523 44.4426 12.9071 43.1065C11.8198 39.8527 7.98566 41.637 8.72555 44.4956C9.9491 49.2033 10.0306 53.1786 9.85846 56.0653C9.76365 57.655 10.6117 59.1377 12.1181 59.8068L16.2662 61.6492C16.3819 61.7006 16.5012 61.7413 16.628 61.7646C17.3691 61.9009 20.1374 62.2769 21.9397 60.7117C24.1521 58.7961 26.6864 55.7062 28.166 53.1131L29.82 50.2143C31.474 47.3154 27.2191 46.0448 27.2191 46.0448Z"
                fill="white"
            />
            <path
                d="M27.2191 46.0448C27.408 44.3235 25.0996 43.2098 24.2341 42.8575M27.2191 46.0448C27.2191 46.0448 31.474 47.3154 29.82 50.2143L28.166 53.1131C26.6864 55.7062 24.1521 58.7961 21.9397 60.7117C20.1374 62.2769 17.3691 61.9009 16.628 61.7646C16.5012 61.7413 16.3819 61.7006 16.2662 61.6492L12.1181 59.8068C10.6117 59.1377 9.76365 57.655 9.85846 56.0653C10.0306 53.1786 9.9491 49.2033 8.72555 44.4956C7.98566 41.637 11.8198 39.8527 12.9071 43.1065C13.3523 44.4426 13.108 45.6255 13.0209 46.4985C12.9339 47.3715 20.4545 33.4704 20.4545 33.4704C22.0833 30.6158 25.8183 32.3574 24.1895 35.212L21.5036 39.9194M27.2191 46.0448L26.0097 48.1823M24.2341 42.8575C24.2899 41.1301 22.1637 40.1759 21.5036 39.9194M24.2341 42.8575L22.758 45.4662M21.5036 39.9194L19.0605 44.237"
                stroke="black"
                strokeLinejoin="round"
            />
            <path
                d="M127 3C127 1.89543 126.105 1 125 1H89C87.8954 1 87 1.89543 87 3V23C87 24.1046 87.8954 25 89 25H96.0775C97.2926 25 98.4419 25.5524 99.201 26.5012L102 30L104.799 26.5012C105.558 25.5524 106.707 25 107.922 25H125C126.105 25 127 24.1046 127 23V3Z"
                fill="white"
                stroke="black"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M121 9.5C121 9.22386 120.768 9 120.481 9H93.5185C93.2321 9 93 9.22386 93 9.5C93 9.77614 93.2321 10 93.5185 10H120.481C120.768 10 121 9.77614 121 9.5Z"
                fill="black"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M121 14.5C121 14.2239 120.768 14 120.481 14H93.5185C93.2321 14 93 14.2239 93 14.5C93 14.7761 93.2321 15 93.5185 15H120.481C120.768 15 121 14.7761 121 14.5Z"
                fill="black"
            />
        </g>
        <defs>
            <clipPath id="clip0_7322_6350">
                <rect width="128" height="64" fill="white" />
            </clipPath>
        </defs>
    </svg>
);
