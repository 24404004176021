import { useTracking } from '@vp/react-tracking';
import { AlertBox, FlexBox, Spinner } from '@vp/swan';
import { DateSelector as DateSelectorCommon, bugtracker } from '@99designs/design-services-common';
import { __ } from '@99designs/i18n';
import { useCollaborationContext } from '../../../context';
import { useAvailableDatesQuery } from './DataSelector.generated';

interface DateSelectorProps {
    timezone: string;
    date: string | null;
    setDate: (date: string) => void;
    calendarId?: number;
    locale: string;
}

export function DateSelector({ timezone, setDate, date, calendarId, locale }: DateSelectorProps) {
    const { tracking } = useTracking();
    const { trackingProperties, environmentForScheduler } = useCollaborationContext();
    const { data, loading, error } = useAvailableDatesQuery({
        variables: {
            timezone,
            locale,
            ...(calendarId && { calendarId }),
        },
        onError: (e) => {
            bugtracker.notify(`Failed to get available design live dates: ${e.message}`);
        },
        context: {
            headers: {
                Environment: environmentForScheduler,
            },
        },
    });

    if (loading) {
        return (
            <FlexBox alignItems="center" justifyContent="center" padding="3">
                <Spinner accessibleText={__('Loading')} />
            </FlexBox>
        );
    }

    if (!data || error) {
        return (
            <AlertBox skin="error">
                {__(
                    'There was an error fetching available dates. Please refresh the page to try again.'
                )}
            </AlertBox>
        );
    }

    return (
        <DateSelectorCommon
            colSpan={4}
            colSpanXs={6}
            setDate={(date) => {
                if (!date) {
                    return;
                }
                setDate(date);
                tracking.track('DesignLive Rescheduler Edited', {
                    ...trackingProperties,
                    label: 'Appointment Date',
                    date,
                });
            }}
            date={date}
            immutable={false}
            appointmentDates={data.designLiveAvailableDates.dates.map((ad) => ({
                date: ad.date,
                isAvailable: ad.isAvailable,
            }))}
        />
    );
}
