import {
    FieldErrors,
    UseFormClearErrors,
    UseFormRegister,
    UseFormSetError,
    UseFormSetValue,
    UseFormWatch,
} from 'react-hook-form';
import { P, tokensRaw } from '@vp/swan';
import styled from 'styled-components';
import { getRequestType } from '../../Submit/requestDataFromBrief';
import { FormInput } from '../BriefForm';
import { Field, FieldDisplay, FieldDisplayWrapper, FieldSet, isFieldShown } from '../Field';
import { FieldFragment, StructuredBriefFragment } from '../brief.generated';
import {
    UNIMPLEMENTED_QUESTION_KEYS_EDITABLE,
    UNIMPLEMENTED_QUESTION_KEYS_READONLY,
} from '../questions';

export interface FieldGroupData {
    id: string;
    title: string;
    description: string | null;
    fields: FieldFragment[];
}

type FieldGroupProps = {
    group: FieldGroupData;
    errors: FieldErrors<FormInput>;
    register: UseFormRegister<FormInput>;
    setValue: UseFormSetValue<FormInput>;
    watch: UseFormWatch<FormInput>;
    clearErrors: UseFormClearErrors<FormInput>;
    setError: UseFormSetError<FormInput>;
};

export const Legend = styled.legend`
    font-size: 1.5rem;
    font-weight: 700;
    padding: ${tokensRaw.SwanSemSpace4} 0 ${tokensRaw.SwanSemSpace4} 0;
`;

export function FieldGroup({
    group,
    register,
    errors,
    setValue,
    watch,
    clearErrors,
    setError,
}: FieldGroupProps) {
    if (!group.fields.length) return null;
    const isDesignLive = watch('requestType', getRequestType(group.fields)) === 'DesignLive';

    return (
        <FieldSet>
            <Legend id={group.id}>{group.title}</Legend>
            {group.description && (
                <P fontSize="small" textColor="subtle" mt="0" mb="4">
                    {group.description}
                </P>
            )}
            {group.fields.map((f) => {
                // Temporary solution to conditionally render the question about appointment time
                // This can be removed once design-live-appointment-v2 is in use
                if (!isDesignLive && f.id === 'appointmentTime') {
                    return null;
                }

                return (
                    <Field
                        key={`form-field-${f.id}`}
                        register={register}
                        field={f}
                        error={errors[f.id]}
                        setValue={setValue}
                        clearErrors={clearErrors}
                        setError={setError}
                    />
                );
            })}
        </FieldSet>
    );
}

type FieldGroupDisplayProps = {
    group: FieldGroupData;
    colWidthOverride?: NonNullable<1 | 2 | 4 | 3 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12>;
};

export function FieldGroupDisplay({ group, colWidthOverride }: FieldGroupDisplayProps) {
    if (!group.fields.length) {
        return null;
    }

    const isGroupShown = group.fields.some((f) => isFieldShown(f));

    return isGroupShown ? (
        <FieldDisplayWrapper title={group.title}>
            {group.fields.map((f) => {
                return (
                    <FieldDisplay
                        colWidthOverride={colWidthOverride}
                        key={`field-display-${f.id}`}
                        field={f}
                    />
                );
            })}
        </FieldDisplayWrapper>
    ) : null;
}

export enum FieldGroupVariant {
    EDITABLE,
    READONLY,
}

export function fieldGroups(
    brief: StructuredBriefFragment,
    variant: FieldGroupVariant = FieldGroupVariant.EDITABLE
) {
    const unimplementedQuestionKeys =
        variant === FieldGroupVariant.READONLY
            ? UNIMPLEMENTED_QUESTION_KEYS_READONLY
            : UNIMPLEMENTED_QUESTION_KEYS_EDITABLE;
    return brief.groups.map((group) => {
        const fields = brief.dynamicFields.filter(
            (field) => field.groupId === group.id && !unimplementedQuestionKeys.includes(field.id)
        );

        return {
            id: group.id,
            title: group.title,
            description: group.description,
            fields,
        };
    });
}
