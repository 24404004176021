import React, { useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { Drawer, useDefaultDrawerProps } from '@99designs/design-services-layouts';
import { __ } from '@99designs/i18n';
import { RevisionRequestPanelData } from '../../../hooks';
import { ImageRef } from '../DesignPreviewCanvas/ImageRef';
import { PinComment } from '../PinComment/PinComment';
import { COLLABORATE_BASE_PATH } from '../constants';
import { EditComment } from './DrawerContent/EditComment';
import { EditRevisionRequest } from './DrawerContent/EditRevisionRequest';
import { ViewComment } from './DrawerContent/ViewComment';
import { ViewRevisionRequest } from './DrawerContent/ViewRevisionRequest';
import { useViewNavigation } from './DrawerRevisionRequestViewContext';

export interface DrawerRevisionRequestProps {
    revisionRequest: RevisionRequestPanelData;
    handleUpdate: ({ comment }: { comment?: string | undefined }) => void;
    deliveryVersionId: string;
    collaborationId: string;
    images: { [key: number]: ImageRef[] };
    pins: PinComment[];
    interactable: boolean;
    latestVersion: number;
}

export const DrawerRevisionRequest: React.FC<DrawerRevisionRequestProps> = ({
    revisionRequest,
    handleUpdate,
    images,
    pins,
    deliveryVersionId,
    latestVersion,
    interactable,
    collaborationId,
}) => {
    const drawerRef = useRef<HTMLDivElement>(null);
    const drawer = useDefaultDrawerProps(drawerRef, {
        initialOpenState: true,
        collapsedStateOffset: 130,
    });

    const navigate = useNavigate();
    const { current, navigateTo, goBack } = useViewNavigation();

    return (
        <Drawer
            {...drawer}
            ref={drawerRef}
            zIndex={1000}
            isDraggable={current?.view === 'editRevision' || current?.view === 'viewRevision'}
        >
            {current?.view === 'editRevision' && (
                <EditRevisionRequest
                    revisionRequest={revisionRequest}
                    deliveryVersionId={deliveryVersionId}
                    handleUpdate={handleUpdate}
                    images={images}
                    pins={pins}
                />
            )}

            {current?.view === 'viewRevision' && (
                <ViewRevisionRequest
                    revisionRequest={revisionRequest}
                    deliveryVersionId={deliveryVersionId}
                    images={images}
                    pins={pins}
                    onClickBack={() => {
                        navigate(
                            `${COLLABORATE_BASE_PATH}/${collaborationId}/delivery/${latestVersion}`
                        );
                    }}
                />
            )}

            {current?.view === 'editGeneralComment' && (
                <EditComment
                    comment={revisionRequest.generalComment}
                    onUpdate={(comment) => handleUpdate({ comment })}
                    placeholderText={__('Write a note to the designer')}
                />
            )}

            {current?.view === 'viewGeneralComment' && (
                <ViewComment
                    title={__('General comment')}
                    comment={revisionRequest.generalComment}
                    interactable={interactable}
                    onEdit={() => navigateTo({ view: 'editGeneralComment' })}
                    onDelete={() => {
                        handleUpdate({ comment: '' });
                        goBack();
                    }}
                    onBack={() => goBack()}
                />
            )}

            {current?.view === 'editPinnedComment' && current.data && (
                <EditComment placeholderText={__('Add comment')} {...current.data} />
            )}

            {current?.view === 'viewPinnedComment' && current.data && (
                <ViewComment {...current.data} />
            )}
        </Drawer>
    );
};
