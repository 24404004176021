import {
    Banner,
    BannerDescription,
    BannerFootnote,
    BannerImage,
    BannerImageContainer,
    BannerLinks,
    BannerText,
    BannerTextContainer,
    BannerTitle,
    BoxProps,
    FluidImage,
    Typography,
} from '@vp/swan';
import { StaticImageData } from 'next/image';
import styled from 'styled-components';
import { __ } from '@99designs/i18n';

export type HeroPropsType = 'full' | 'basic';

export type HeroProps = {
    type: HeroPropsType;
    heading: string;
    description?: string;
    image?: string | StaticImageData;
    footnote?: string;
    bgc?: string;
    darkMode?: BoxProps['darkMode'];
    ctaRenderer?: () => React.ReactNode;
    textAlign?: string;
};

const StyledBannerText = styled(BannerText)<{ $textAlign?: string }>`
    ${({ $textAlign }) =>
        $textAlign &&
        `
        text-align: ${$textAlign};
        margin-left: 0 !important;
    `}
`;

const NormalisedImage = styled(FluidImage)`
    object-fit: contain !important;
`;

const StyledBanner = styled(Banner)<{ $backgroundColor?: string; $type?: HeroPropsType }>`
    ${({ $type }) =>
        $type === 'basic' &&
        `
        min-height: 0;
    `}
    ${({ $backgroundColor }) =>
        $backgroundColor &&
        `
        --swan-public-marketing-background: ${$backgroundColor};
    `}
`;

export function Hero(props: HeroProps) {
    const { type, image, heading, description, footnote, ctaRenderer, textAlign } = props;
    const isFull = type === 'full';

    // normalise image src; if it's a string, it's a path to an image, so we
    // need to wrap it in a StaticImageData object
    const normalisedImage = typeof image === 'string' ? { src: image } : image || {};

    return (
        <StyledBanner
            darkMode={props.darkMode}
            paddingY={isFull ? 0 : 9}
            $backgroundColor={props.bgc}
            $type={type}
        >
            <BannerTextContainer>
                <StyledBannerText $textAlign={textAlign}>
                    <BannerTitle>{heading}</BannerTitle>
                    {description && (
                        <BannerDescription>
                            <Typography fontSkin="title-item">{description}</Typography>
                        </BannerDescription>
                    )}
                    {ctaRenderer && <BannerLinks>{ctaRenderer()}</BannerLinks>}
                    {footnote && <BannerFootnote>{footnote}</BannerFootnote>}
                </StyledBannerText>
            </BannerTextContainer>
            {type === 'full' && image && (
                <BannerImageContainer>
                    <BannerImage>
                        <picture>
                            <NormalisedImage
                                src={normalisedImage.src}
                                alt={__('Hero image', {
                                    _context: `This is the alt text for the brief's default hero image`,
                                })}
                            />
                        </picture>
                    </BannerImage>
                </BannerImageContainer>
            )}
        </StyledBanner>
    );
}

export default Hero;
