import React from 'react';
import {
    Button,
    Icon,
    Menu,
    MenuItem,
    MenuList,
    MenuPopover,
    MenuTrigger,
    Typography,
} from '@vp/swan';
import { __ } from '@99designs/i18n';

export type VersionDropdownProps = {
    version: number;
    setVersion: (version: number) => void;
    revisionsTotal: number;
    revisionsRemaining: number;
    interactable?: boolean;
};

const Label: React.FC<Pick<VersionDropdownProps, 'version' | 'revisionsRemaining'>> = ({
    version,
    revisionsRemaining,
}) => (
    <div>
        <Typography fontSkin="body-standard-bold">
            {__('Version {{version}}', { version })}
        </Typography>
        <Typography fontSkin="body-small">
            {revisionsRemaining === 1
                ? __('1 revision left')
                : __('{{x}} revisions left', { x: revisionsRemaining })}
        </Typography>
    </div>
);

export const VersionDropdown: React.FC<VersionDropdownProps> = ({
    version: currentVersion,
    setVersion,
    revisionsTotal,
    revisionsRemaining,
    interactable,
}) => {
    if (!interactable) {
        return (
            <Button style={{ pointerEvents: 'none' }} textAlign="left">
                <Label version={currentVersion} revisionsRemaining={revisionsRemaining} />
            </Button>
        );
    }
    const versions = Array.from({ length: revisionsTotal }, (_, index) => 1 + index);

    return (
        <Menu
            aria-label={__('Select a version')}
            onChange={(num: string) => {
                const v = parseInt(num);
                if (isNaN(v)) {
                    return;
                }
                setVersion(v);
            }}
        >
            <MenuTrigger>
                <Button textAlign="left">
                    <Label version={currentVersion} revisionsRemaining={revisionsRemaining} />
                    <Icon iconType="caretDown" />
                </Button>
            </MenuTrigger>
            <MenuPopover>
                <MenuList>
                    {versions.map((version) => (
                        <MenuItem key={version.toString()} textValue={version.toString()}>
                            <Typography
                                fontSkin={
                                    version === currentVersion ? 'body-small-bold' : 'body-small'
                                }
                            >
                                {__('Version {{version}}', { version })}
                            </Typography>
                        </MenuItem>
                    ))}
                </MenuList>
            </MenuPopover>
        </Menu>
    );
};
