import React from 'react';
import { AlertBox, AlertBoxDismissButton, Button, FlexBox, Spinner, Typography } from '@vp/swan';
import {
    Drawer,
    DrawerContent,
    DrawerHeader,
    DrawerProps,
} from '@99designs/design-services-layouts';
import { CollaborationFile } from '@99designs/graph-utils/types';
import { __ } from '@99designs/i18n';
import { useDeleteUploadedFile } from '../../../hooks';
import { Z_INDEX_DRAWER_FILE_PREVIEW } from './PreviewDrawer';

const Z_INDEX_DRAWER_FILE_DELETE = Z_INDEX_DRAWER_FILE_PREVIEW + 10;

interface FileDeleteConfirmationDrawerProps {
    drawerProps: Omit<DrawerProps, 'children'>;
    file: CollaborationFile;
    removeUploadedFile: (file: CollaborationFile) => void;
    ref: React.RefObject<HTMLDivElement>;
}

export const FileDeleteConfirmationDrawer = React.forwardRef<
    HTMLDivElement,
    FileDeleteConfirmationDrawerProps
>(({ drawerProps, file, removeUploadedFile }, ref) => {
    const { handleDelete, clearError, error, loading } = useDeleteUploadedFile({
        file,
        removeUploadedFile,
        onSuccessCallback: () => {
            drawerProps.handleClose?.();
        },
    });

    return (
        <Drawer
            {...drawerProps}
            ref={ref}
            asPageOverlay
            isDraggable
            data-testid="delete-file-confirmation"
            zIndex={Z_INDEX_DRAWER_FILE_DELETE}
        >
            <DrawerHeader onClickBack={() => drawerProps.handleClose?.()} />
            <DrawerContent>
                <Typography fontSkin="body-standard-bold" marginBottom="5">
                    {__('Are you sure you want to remove this file?')}
                </Typography>

                {error && (
                    <AlertBox skin="error" marginBottom={'4'}>
                        <span data-testid="error-alert">{error.message}</span>
                        <AlertBoxDismissButton
                            accessibleText={__('Dismiss alert')}
                            onClick={() => clearError()}
                        />
                    </AlertBox>
                )}

                <FlexBox flexDirection="column" gap="3">
                    <Button
                        onClick={() => {
                            handleDelete?.();
                            drawerProps.handleClose?.();
                        }}
                        skin="primary"
                        disabled={!!loading}
                    >
                        {loading && <Spinner size="tiny" accessibleText={__('Loading...')} />}
                        {__('Remove file')}
                    </Button>
                    <Button onClick={() => drawerProps.handleClose?.()}>{__('Cancel')}</Button>
                </FlexBox>
            </DrawerContent>
        </Drawer>
    );
});
