import React, { ReactNode, createContext, useContext, useState } from 'react';
import { Box } from '@vp/swan';

interface SwanModeContextType {
    toggleDarkMode: () => void;
    toggleCompactMode: () => void;
    isDarkMode?: boolean;
    isCompactMode?: boolean;
}

export const SwanModeContext = createContext<SwanModeContextType | undefined>(undefined);

export const useSwanModeContext = () => {
    const context = useContext(SwanModeContext);
    if (!context) {
        throw new Error('useSwanModeContext must be used within a SwanModeProvider');
    }
    return context;
};

interface SwanModeProviderProps {
    children: ReactNode;
    value?: {
        isDarkMode?: boolean;
        isCompactMode?: boolean;
    };
}

export const SwanModeProvider: React.FC<SwanModeProviderProps> = ({ children, value }) => {
    const [isDarkMode, setIsDarkMode] = useState<boolean>(value?.isDarkMode || false);
    const [isCompactMode, setIsCompactMode] = useState<boolean>(value?.isCompactMode || false);

    const toggleDarkMode = () => {
        setIsDarkMode((prev) => !prev);
    };

    const toggleCompactMode = () => {
        setIsCompactMode((prev) => !prev);
    };

    return (
        <SwanModeContext.Provider
            value={{ isDarkMode, toggleDarkMode, isCompactMode, toggleCompactMode }}
        >
            <Box compactMode={isCompactMode} darkMode={isDarkMode}>
                {children}
            </Box>
        </SwanModeContext.Provider>
    );
};
