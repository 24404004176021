import React, { Dispatch, SetStateAction, useState } from 'react';
import {
    AlertBox,
    Box,
    Buttonbar,
    ButtonbarButton,
    ButtonbarButtonLabel,
    Carousel,
    CarouselSlide,
    Divider,
    FlexBox,
    FluidImage,
    ModalDialog,
    ModalDialogBody,
    ModalDialogCloseButton,
    ModalDialogContent,
    ModalDialogHeader,
    ModalDialogNav,
    ModalDialogTitle,
    Spinner,
} from '@vp/swan';
import styled from 'styled-components';
import { __ } from '@99designs/i18n';
import { useProductPreviewQuery } from './ProductPreview.generated';

const StyledCarouselSlide = styled(CarouselSlide)`
    text-align: center;
    text-align: -webkit-center;
`;

const StyledFluidImage = styled(FluidImage)`
    object-fit: contain !important;
`;

export interface ProductPreviewProps {
    workRevisionId: string;
    locale: string;
    setIsPreviewOpen: Dispatch<SetStateAction<boolean>>;
}

export const ProductPreview: React.FC<ProductPreviewProps> = ({
    workRevisionId,
    locale,
    setIsPreviewOpen,
}) => {
    const [currentSlide, setCurrentSlide] = useState(0);

    const { data, loading, error } = useProductPreviewQuery({
        variables: {
            culture: locale,
            workRevisionId: workRevisionId,
        },
    });

    const previews = data?.collaborationProductPreview.views.map((view) => {
        return view._links.image;
    });
    const isLoading = loading || !previews;

    return (
        <ModalDialog
            variant="standard"
            isOpen={true}
            onRequestDismiss={() => setIsPreviewOpen(false)}
            takeOver={true}
            data-testid="product-preview-modal"
        >
            <ModalDialogContent
                aria-labelledby="modal dialog menu"
                display="flex"
                style={{ flexDirection: 'column' }}
            >
                <ModalDialogNav>
                    <ModalDialogCloseButton accessibleText={__('Close')} />
                </ModalDialogNav>
                <ModalDialogHeader>
                    <ModalDialogTitle>{__('Product preview')}</ModalDialogTitle>
                </ModalDialogHeader>
                <ModalDialogBody style={{ flexGrow: 1 }}>
                    {error && (
                        <FlexBox justifyContent="center">
                            <AlertBox skin="error" marginBottom={'3'}>
                                <span data-testid="error-alert">
                                    {__('An unexpected error occurred. Please try again later.')}
                                </span>
                            </AlertBox>
                        </FlexBox>
                    )}
                    {isLoading && !error && (
                        <FlexBox justifyContent="center">
                            <Spinner accessibleText={__('Loading...')} />
                        </FlexBox>
                    )}
                    {!isLoading && !error && (
                        <FlexBox
                            flexDirection="column"
                            justifyContent="space-between"
                            style={{ height: '100%' }}
                        >
                            <Box style={{ width: '100%', maxWidth: '720px', margin: 'auto' }}>
                                <Carousel
                                    slidesToShow={1}
                                    skin="standard"
                                    progressIndicator="none"
                                    accessibleTextForPrevious={__('Go to previous slide')}
                                    accessibleTextForNext={__('Go to next slide')}
                                    currentSlide={currentSlide}
                                    afterChange={(index) => setCurrentSlide(index)}
                                    swanStyle={{ paddingY: 10 }}
                                >
                                    {previews.map((preview, idx) => (
                                        <StyledCarouselSlide key={`modal-carousel-${idx}`}>
                                            <StyledFluidImage
                                                src={preview.href}
                                                role="presentation"
                                                data-testid="product-preview-image"
                                            />
                                        </StyledCarouselSlide>
                                    ))}
                                </Carousel>
                            </Box>
                            {previews.length > 1 && (
                                <FlexBox flexDirection="column" alignItems="center" gap="6">
                                    <Divider style={{ width: '100%' }} />
                                    <Buttonbar
                                        variant={'single-select'}
                                        selectedValue={`${currentSlide}`}
                                        onSelectedValueChange={(val) => {
                                            const idx = val ? +val : 0;
                                            setCurrentSlide(idx);
                                        }}
                                    >
                                        {previews.map((preview, idx) => (
                                            <ButtonbarButton
                                                key={`product-preview-tab-${idx}`}
                                                value={`${idx}`}
                                            >
                                                <ButtonbarButtonLabel>
                                                    {preview.title}
                                                </ButtonbarButtonLabel>
                                            </ButtonbarButton>
                                        ))}
                                    </Buttonbar>
                                </FlexBox>
                            )}
                        </FlexBox>
                    )}
                </ModalDialogBody>
            </ModalDialogContent>
        </ModalDialog>
    );
};
