/* eslint-disable */

import * as Apollo from '@apollo/client';
import { DocumentNode } from 'graphql';
import * as Types from '@99designs/graph-utils/types';

export type DesignLiveAvailableTimesQueryVariables = Types.Exact<{
    date: Types.Scalars['Time'];
    locale: Types.Scalars['String'];
    timezone: Types.Scalars['String'];
    calendarId?: Types.Maybe<Types.Scalars['Int']>;
}>;

export type DesignLiveAvailableTimesQuery = {
    __typename: 'Query';
    designLiveAvailableTimes: {
        __typename: 'Times';
        times: Array<{ __typename: 'Timeslot'; startTimeUTC: any; endTimeUTC: any }>;
    };
};

export const DesignLiveAvailableTimesDocument: DocumentNode = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'DesignLiveAvailableTimes' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'date' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'Time' } },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'locale' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'timezone' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'calendarId' } },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'designLiveAvailableTimes' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'date' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'date' } },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'locale' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'locale' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'timezone' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'timezone' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'calendarId' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'calendarId' },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'times' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'startTimeUTC' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'endTimeUTC' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
};

/**
 * __useDesignLiveAvailableTimesQuery__
 *
 * To run a query within a React component, call `useDesignLiveAvailableTimesQuery` and pass it any options that fit your needs.
 * When your component renders, `useDesignLiveAvailableTimesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDesignLiveAvailableTimesQuery({
 *   variables: {
 *      date: // value for 'date'
 *      locale: // value for 'locale'
 *      timezone: // value for 'timezone'
 *      calendarId: // value for 'calendarId'
 *   },
 * });
 */
export function useDesignLiveAvailableTimesQuery(
    baseOptions: Apollo.QueryHookOptions<
        DesignLiveAvailableTimesQuery,
        DesignLiveAvailableTimesQueryVariables
    >
) {
    return Apollo.useQuery<DesignLiveAvailableTimesQuery, DesignLiveAvailableTimesQueryVariables>(
        DesignLiveAvailableTimesDocument,
        baseOptions
    );
}
export function useDesignLiveAvailableTimesLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        DesignLiveAvailableTimesQuery,
        DesignLiveAvailableTimesQueryVariables
    >
) {
    return Apollo.useLazyQuery<
        DesignLiveAvailableTimesQuery,
        DesignLiveAvailableTimesQueryVariables
    >(DesignLiveAvailableTimesDocument, baseOptions);
}
export type DesignLiveAvailableTimesQueryHookResult = ReturnType<
    typeof useDesignLiveAvailableTimesQuery
>;
export type DesignLiveAvailableTimesLazyQueryHookResult = ReturnType<
    typeof useDesignLiveAvailableTimesLazyQuery
>;
export type DesignLiveAvailableTimesQueryResult = Apollo.QueryResult<
    DesignLiveAvailableTimesQuery,
    DesignLiveAvailableTimesQueryVariables
>;
