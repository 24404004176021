import { useSearchParams } from 'react-router-dom';
import Bugsnag from '@bugsnag/js';
import { DELIVERY_VARIATION_KEY, SELECTED_SURFACE_KEY } from './DELIVERY_VARIATION_KEY';
import { ImageRef } from './ImageRef';

export function useDeliveryQueryParams(images: { [key: number]: ImageRef[] }) {
    const [searchParams, setSearchParams] = useSearchParams();
    const deliveryVariation = getDeliveryVariation(searchParams, images);
    const selectedSurface = getSelectedSurface(searchParams, images[deliveryVariation]);

    const setDeliveryVariationQueryParams = ({
        deliveryVariation,
        selectedSurface,
    }: {
        deliveryVariation?: number;
        selectedSurface?: number;
    }) => {
        if (deliveryVariation != null) {
            searchParams.set(DELIVERY_VARIATION_KEY, deliveryVariation.toString());
        }
        if (selectedSurface != null) {
            searchParams.set(SELECTED_SURFACE_KEY, selectedSurface.toString());
        }
        setSearchParams(searchParams);
    };
    return { deliveryVariation, selectedSurface, setDeliveryVariationQueryParams };
}

export function getSelectedSurface(searchParams: URLSearchParams, images: ImageRef[]) {
    const selectedSurface = searchParams.get(SELECTED_SURFACE_KEY)
        ? Number(searchParams.get(SELECTED_SURFACE_KEY))
        : 0;
    if (selectedSurface < 0 || selectedSurface >= images.length) {
        Bugsnag.notify(`Invalid selectedSurface query parameter ${selectedSurface}`);
        return 0;
    }
    return selectedSurface;
}

export function getDeliveryVariation(
    searchParams: URLSearchParams,
    images: { [key: number]: ImageRef[] }
) {
    const dv = searchParams.get(DELIVERY_VARIATION_KEY)
        ? Number(searchParams.get(DELIVERY_VARIATION_KEY))
        : Number(Object.keys(images)[0]);
    const deliveryVariations = Object.keys(images);
    if (deliveryVariations.includes(String(dv))) {
        return dv;
    }
    Bugsnag.notify(`Invalid deliveryVariationId query parameter ${dv}`);

    return Number(deliveryVariations[0]);
}
