import { useCallback, useState } from 'react';
import { CollaborationFile } from '@99designs/graph-utils/types';
import { __ } from '@99designs/i18n';
import { useCollaborationDeleteFileMutation } from '../views/RequestRevisionPanel/components/UploadedFiles.generated';

interface UseDeleteUploadedFileProps {
    file: CollaborationFile;
    removeUploadedFile: (file: CollaborationFile) => void;
    onSuccessCallback?: () => void;
}

export function useDeleteUploadedFile({
    file,
    removeUploadedFile,
    onSuccessCallback,
}: UseDeleteUploadedFileProps) {
    const [error, setError] = useState<Error | null>(null);
    const [collaborationDeleteFile, { loading }] = useCollaborationDeleteFileMutation();

    const handleDelete = useCallback(async () => {
        try {
            await collaborationDeleteFile({
                variables: {
                    fileId: file.id,
                },
            });
            removeUploadedFile(file);
            onSuccessCallback?.();
        } catch (error) {
            setError(new Error(__(`An unexpected error occurred. Please try again later.`)));
        }
    }, [collaborationDeleteFile, file, removeUploadedFile, onSuccessCallback]);

    const clearError = () => {
        setError(null);
    };

    return {
        handleDelete,
        clearError,
        error,
        loading,
    };
}
