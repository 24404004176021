import i18n from 'i18next';
import dadkURLs from './da-dk.json';
import deatURLs from './de-at.json';
import dechURLs from './de-ch.json';
import dedeURLs from './de-de.json';
import enauURLs from './en-au.json';
import encaURLs from './en-ca.json';
import engbURLs from './en-gb.json';
import enieURLs from './en-ie.json';
import eninURLs from './en-in.json';
import ennzURLs from './en-nz.json';
import ensgURLs from './en-sg.json';
import enusURLs from './en-us.json';
import esesURLs from './es-es.json';
import esusURLs from './es-us.json';
import fifiURLs from './fi-fi.json';
import frbeURLs from './fr-be.json';
import frcaURLs from './fr-ca.json';
import frchURLs from './fr-ch.json';
import frfrURLs from './fr-fr.json';
import itchURLs from './it-ch.json';
import ititURLs from './it-it.json';
import nbnoURLs from './nb-no.json';
import nlbeURLs from './nl-be.json';
import nlnlURLs from './nl-nl.json';
import ptptURLs from './pt-pt.json';
import svseURLs from './sv-se.json';

export type TranslationKeys = keyof typeof enusURLs;

export function __vistaRoute(key: TranslationKeys, params?: Record<string, string>) {
    // Get the translated route as normal
    let route = i18n.t(key);

    // If there are dynamic parameters, replace them in the route to allow navigation
    if (params) {
        Object.keys(params).forEach((paramKey) => {
            const paramValue = params[paramKey];
            route = route.replace(`{${paramKey}}`, paramValue);
        });
    }

    return route;
}

export const urlTranslations = {
    dadkURLs,
    deatURLs,
    dechURLs,
    dedeURLs,
    enauURLs,
    encaURLs,
    engbURLs,
    enieURLs,
    eninURLs,
    ennzURLs,
    ensgURLs,
    enusURLs,
    esesURLs,
    esusURLs,
    fifiURLs,
    frbeURLs,
    frcaURLs,
    frchURLs,
    frfrURLs,
    itchURLs,
    ititURLs,
    nbnoURLs,
    nlbeURLs,
    nlnlURLs,
    ptptURLs,
    svseURLs,
};
