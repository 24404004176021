import React from 'react';
import { BOOKEND_TYPES, Footer, Header } from '@vp/esi-bookends';
import { Box } from '@vp/swan';
import { APP_TENANT } from '@99designs/design-services-common';
import { PageTracking } from '@99designs/tracking';

interface PageLayoutProps {
    children: React.ReactNode;
    pageTracking: PageTracking;
    locale: string;
    breadcrumbs: React.ReactNode;
}

export function PageLayout({ children, pageTracking, locale, breadcrumbs }: PageLayoutProps) {
    return (
        <Box height="100vh">
            <Header
                locale={locale}
                tenant={APP_TENANT}
                type={BOOKEND_TYPES['FULL']}
                trackingConfiguration={pageTracking}
            />
            {breadcrumbs}
            <Box as="main" id="maincontentstart">
                {children}
            </Box>
            <Footer
                locale={locale}
                tenant={APP_TENANT}
                type={BOOKEND_TYPES['FULL']}
                trackingConfiguration={pageTracking}
            />
        </Box>
    );
}
