import { Box, Button, Icon, Typography, tokens, tokensRaw } from '@vp/swan';
import styled from 'styled-components';
import { getVariantUrl } from '@99designs/design-services-common';
import { CollaborationFile } from '@99designs/graph-utils/types';
import { __ } from '@99designs/i18n';
import { getFileDownload } from '../../../util/getFileDownload';

const ImageContainer = styled(Box)`
    position: relative;
    width: ${tokens.SwanSemSpace12};
    height: ${tokens.SwanSemSpace12};
    border-radius: ${tokens.SwanSemSpace3};
    margin-bottom: ${tokens.SwanSemSpace4};
    margin-right: ${tokens.SwanSemSpace6};
    cursor: zoom-in;
`;

const IconContainer = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: ${tokens.SwanSemSpace5};
`;

interface UploadedFileProps {
    file: CollaborationFile;
    onDelete?: (file: CollaborationFile) => void;
    showDownload?: boolean;
    onClick?: (file: CollaborationFile) => void;
}

const Action = ({
    onClick,
    iconType,
    label,
    testId,
}: {
    onClick: () => void;
    iconType: Parameters<typeof Icon>[0]['iconType'];
    label: string;
    testId: string;
}) => (
    <Button
        buttonShape="round"
        skin="secondary"
        data-testid={testId}
        style={{
            position: 'absolute',
            top: tokens.SwanSemSpace3,
            right: tokens.SwanSemSpace3,
        }}
        role="button"
        aria-label={label}
        onClick={(e) => {
            e.stopPropagation();
            onClick();
        }}
    >
        <Icon iconType={iconType} size="20p" />
    </Button>
);

export function UploadedFile(props: UploadedFileProps) {
    const { file, onDelete, showDownload, onClick } = props;
    const isPreviewable = !file.name.toLowerCase().endsWith('.pdf');
    return (
        <ImageContainer data-testid={'file-' + file.id} onClick={() => onClick?.(file)}>
            {isPreviewable ? (
                <img
                    src={getVariantUrl(file.externalUploadId, 'preview')}
                    height={'100%'}
                    width={'100%'}
                    alt={file.name}
                    style={{ objectFit: 'contain', borderRadius: tokensRaw.SwanSemSpace3 }}
                />
            ) : (
                <IconContainer role="presentation">
                    <Icon iconType="fileGeneric" size="standard" marginBottom={'2'} />

                    <Typography fontSize="xsmall" fontWeight="bold" marginBottom={'2'}>
                        {__('Preview unavailable')}
                    </Typography>
                    <Typography fontSize="xsmall">{file.name}</Typography>
                </IconContainer>
            )}
            {onDelete && (
                <Action
                    testId="delete-file"
                    label={__('Delete file')}
                    onClick={() => onDelete(file)}
                    iconType="delete"
                />
            )}
            {showDownload && (
                <Action
                    testId="download-file"
                    label={__('Download file')}
                    onClick={() => getFileDownload(file)}
                    iconType="download"
                />
            )}
        </ImageContainer>
    );
}
