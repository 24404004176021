import { tokens, tokensRaw } from '@vp/swan';
import styled from 'styled-components';

const ThumbnailContainer = styled.div<{ size?: string }>`
    position: relative;
    width: ${({ size }) => size || tokensRaw.SwanSemSpace10};
    height: ${({ size }) => size || tokensRaw.SwanSemSpace10};
    overflow: visible;
`;

const ThumbnailImage = styled.img`
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    background: ${tokens.SwanBaseColorGrey100};
    border-radius: ${tokens.SwanSemBorderRadiusContainer};
`;

const CommentSvg = styled.svg`
    width: 10px;
    height: 10px;
    fill: white;
`;

const NotificationBadge = styled.span<{ isActive: boolean }>`
    display: ${({ isActive }) => (isActive ? 'flex' : 'none')};
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(30%, -30%);
    width: ${tokensRaw.SwanSemSpace6};
    height: ${tokensRaw.SwanSemSpace6};
    background-color: ${tokens.SwanSemColorBgAccentStrongPaired};
    border-radius: 50%;
    box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.15);
    z-index: 3;
`;

interface ThumbnailProps {
    src: string;
    alt: string;
    hasComments: boolean;
    size?: string;
}

export const Thumbnail = ({ src, alt, hasComments, size }: ThumbnailProps) => (
    <ThumbnailContainer size={size}>
        <ThumbnailImage src={src} alt={alt} />
        <NotificationBadge isActive={hasComments}>
            {hasComments && (
                <CommentSvg viewBox="0 0 10 10" xmlns="http://www.w3.org/2000/svg">
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M4.70021 7.89952C4.71268 8.58299 4.69011 10 4.69011 10C4.69011 10 9.84957 8.42591 9.99599 4.24284C10.1424 0.0597629 6.23945 -0.0328377 4.83612 0.00459004C3.4328 0.0420178 1.06068 0.917889 0.491298 2.22763C-0.0780826 3.53737 -0.355929 4.70879 0.811513 6.2138C1.97895 7.71882 3.84411 7.88279 4.70021 7.89952Z"
                        fill={tokensRaw.SwanBaseColorBlack}
                    />
                </CommentSvg>
            )}
        </NotificationBadge>
    </ThumbnailContainer>
);
