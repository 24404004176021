import Bugsnag, { NotifiableError, OnErrorCallback } from '@bugsnag/js';

export const bugtracker = {
    notify: function notify(error: NotifiableError, onError?: OnErrorCallback | undefined) {
        if (process.env.NEXT_PUBLIC_DESIGN_SERVICES_BUGSNAG_RELEASE_STAGE !== 'production') {
            console.log(error);
        }
        onError ? Bugsnag.notify(error, onError) : Bugsnag.notify(error);
    },
};
export default bugtracker;
