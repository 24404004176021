import { useEffect, useState } from 'react';
import { UseFormRegister, UseFormSetValue } from 'react-hook-form';
import { Box, Checkbox, List, Typography } from '@vp/swan';
import { __ } from '@99designs/i18n';
import { useFieldViewSkinContext } from '../../../../context/FieldViewContext';
import { useBriefFormContext } from '../../../BriefContext/BriefFormContext';
import { FormInput } from '../../BriefForm';
import { Field_MultiChoiceField_Fragment } from '../../brief.generated';
import { useUpdateMultiChoiceField } from './useUpdateMultiChoiceField';

export type MultiChoiceFieldProps = Field_MultiChoiceField_Fragment & {
    register: UseFormRegister<FormInput>;
    setValue: UseFormSetValue<FormInput>;
};

export function Input({
    id,
    required,
    options,
    register,
    multiChoiceValue,
    setValue,
}: MultiChoiceFieldProps) {
    const { briefId } = useBriefFormContext();
    const [selectedValues, setSelectedValues] = useState(multiChoiceValue);
    const updateMutation = useUpdateMultiChoiceField(id, briefId, selectedValues);

    useEffect(() => {
        // The default value when no options are selected is `false`,
        // this is incompatible with dynamic briefs
        setValue(id, selectedValues);

        if (required) {
            if (selectedValues.length > 0) {
                updateMutation(selectedValues);
            }
        } else {
            // This is to make sure we can uncheck all options when the field is optional
            updateMutation(selectedValues);
        }
    }, [id, setValue, selectedValues, updateMutation, required]);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { value, checked } = event.target;

        if (checked) {
            // If checked, add the checkbox value to the array
            setSelectedValues((prevState) => [...prevState, value]);
        } else {
            // If unchecked, remove the checkbox value from the array
            setSelectedValues((prevState) => prevState.filter((item) => item !== value));
        }
    };

    return (
        <div role="group">
            {options.map((option: { label: string; value: string }, index: number) => (
                <Box marginBottom={'5'} key={`${id}-${index}`}>
                    <label>
                        <Checkbox
                            {...register(id, {
                                required: required ? __('This field is required') : false,
                            })}
                            defaultChecked={selectedValues?.includes(option.value)}
                            value={option.value}
                            marginRight={'4'}
                            onChange={handleChange}
                        />
                        {option.label}
                    </label>
                </Box>
            ))}
        </div>
    );
}

type MultiChoiceFieldViewProps = {
    multiChoiceValue: Field_MultiChoiceField_Fragment['multiChoiceValue'];
    options: Field_MultiChoiceField_Fragment['options'];
};

export function View({ multiChoiceValue: values, options }: MultiChoiceFieldViewProps) {
    const skin = useFieldViewSkinContext();

    return (
        <Typography fontSize={skin.fontSize}>
            <List>
                {options
                    .filter((o) => values.includes(o.value))
                    .map((o) => (
                        <li key={o.value}>{o.label}</li>
                    ))}
            </List>
        </Typography>
    );
}
