import React from 'react';
import { useScreenClass } from '@vp/swan';
import {
    DrawerHome,
    EmptyCanvas,
    ErrorLoadingState,
    SingleImageDesignPreview,
    useCanvasProps,
    useCollaborationContext,
    useVersion,
} from '@99designs/collaborate-views';

export interface CanvasProps {
    revisionRequestId: number | null;
    deliveryId: string;
    subRoute: string | null;
    interactable?: boolean;
}

export const Canvas: React.FC<CanvasProps> = ({
    deliveryId,
    revisionRequestId,
    subRoute,
    interactable = false,
}) => {
    const {
        collaborationId,
        revisionRequests,
        deliveries,
        trackingProperties,
        viewerRole,
        locale,
        state,
        hasDesignLiveDelivery,
    } = useCollaborationContext();
    const { loading, errors, props } = useCanvasProps(
        deliveryId,
        collaborationId,
        revisionRequestId,
        deliveries.length > 0,
        trackingProperties,
        locale
    );
    const { version, setVersion } = useVersion(subRoute);
    const screenClass = useScreenClass();

    return (
        <ErrorLoadingState loading={loading} errors={errors} data={props}>
            {(props) =>
                Object.keys(props.images).length === 0 ? (
                    <EmptyCanvas isDesignLive={hasDesignLiveDelivery} />
                ) : (
                    <>
                        <SingleImageDesignPreview
                            {...props}
                            revisionsRemaining={revisionRequests.remaining}
                            revisionsTotal={
                                subRoute === 'revision' ? revisionRequests.used : deliveries.length
                            }
                            version={version}
                            setVersion={setVersion}
                            isDesignerView={viewerRole === 'VIEWER_ROLE_DESIGNER'}
                            interactable={interactable}
                        />
                        {screenClass === 'xs' && subRoute !== 'revision' && (
                            <DrawerHome
                                images={props.images}
                                pins={props.pins}
                                setVersion={setVersion}
                                revisionsRemaining={revisionRequests.remaining}
                                revisionsTotal={deliveries.length}
                                version={version}
                                state={state}
                                interactable={interactable}
                                deliveryHasRevisionRequest={props.hasRevisionRequest}
                            />
                        )}
                    </>
                )
            }
        </ErrorLoadingState>
    );
};
