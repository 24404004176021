import { Box, Typography } from '@vp/swan';
import { CollaborationFile } from '@99designs/graph-utils/types';
import { __ } from '@99designs/i18n';
import { UploadedFileGrid } from '../../RequestRevisionPanel/components/UploadedFileGrid';

export function ReferenceImageSection({ files }: { files: CollaborationFile[] }) {
    return (
        <Box>
            <Typography fontSkin="title-subsection">{__('Reference images')}</Typography>
            <UploadedFileGrid files={files} showDownload />
        </Box>
    );
}
