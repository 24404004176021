import React, { useEffect, useState } from 'react';
import { UseFormRegister, UseFormSetValue } from 'react-hook-form';
import {
    Box,
    Button,
    Column,
    FlexBox,
    FluidImage,
    GridContainer,
    Icon,
    Row,
    Spinner,
    SquareImageContainer,
    Typography,
    tokensRaw,
} from '@vp/swan';
import styled, { css } from 'styled-components';
import {
    PREVIEWABLE_IMAGE_EXTENSIONS,
    UploadedFile,
    useFileUploader,
    useIdentityContext,
} from '@99designs/design-services-common';
import { StructuredBriefFile } from '@99designs/graph-utils/types';
import { __ } from '@99designs/i18n';
import { useBriefFormContext } from '../../../BriefContext/BriefFormContext';
import { useFieldProcessing } from '../../FieldProcessingProvider';
import { Field_FilesField_Fragment } from '../../brief.generated';
import { useUpdateFilesField } from './useUpdateFilesField';

export type FilesFieldProps = Field_FilesField_Fragment & {
    register: UseFormRegister<any>;
    setValue: UseFormSetValue<any>;
};

export const FileCTA = styled(Button)`
    top: ${tokensRaw.SwanSemSpace3};
    right: ${tokensRaw.SwanSemSpace3};
    position: absolute;
    opacity: 0;
    transition: opacity 0.3s ease;
    z-index: 1;
`;

export const ImageContainer = styled(SquareImageContainer)`
    border-radius: ${tokensRaw.SwanSemSpace3};
    overflow: hidden;

    &:hover ${FileCTA} {
        opacity: 1;
        cursor: pointer;
    }
`;

const errorStyles = css`
    background-color: ${tokensRaw.SwanBaseColorRed100};
`;

const DropZone = styled(FlexBox)<{ error: boolean }>`
    background-color: ${tokensRaw.SwanBaseColorGrey100};
    border: 1px solid ${tokensRaw.SwanBaseColorGrey300};
    border-radius: ${tokensRaw.SwanSemSpace3};
    padding: ${tokensRaw.SwanSemSpace5};
    cursor: pointer;
    transition: background-color 0.3s ease;
    &:hover {
        background-color: ${tokensRaw.SwanBaseColorGrey700};
    }

    ${(props) => props.error && errorStyles}
`;

const FileInput = styled.input`
    display: none;
`;

export const IconContainer = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: ${tokensRaw.SwanSemSpace5};
`;

const MAX_FILE_SIZE = 10000000; // 10MB

const buildErrorMessage = (allowedFileTypes: string[]) => {
    if (allowedFileTypes.length === 0) {
        return __('Only files under 10MB are supported');
    }

    const allowedTypes = [...allowedFileTypes].map((ext) => ext.replace('.', ''));

    if (allowedFileTypes.length === 1) {
        return __('Only {{fileType}} files under 10MB are supported', {
            fileType: allowedTypes[0],
        });
    }

    const lastFileType = allowedTypes.pop();
    const fileTypes = allowedTypes.join(', ');
    return __('Only {{fileTypes}} and {{lastFileType}} files under 10MB are supported', {
        fileTypes,
        lastFileType,
    });
};

const filterFiles = (files: FileList | null, allowedFileTypes: string[]) => {
    if (!files) {
        return [];
    }

    if (allowedFileTypes.length === 0) {
        return Array.from(files).filter((file) => file.size < MAX_FILE_SIZE);
    }

    return Array.from(files).filter((file) => {
        const fileExtension = file.name.split('.').pop();
        return allowedFileTypes.includes(`.${fileExtension}`) && file.size < MAX_FILE_SIZE;
    });
};

// Map the response from the MCP upload bucket to the StructuredBriefFile type.
function mapUploadFilesToBriefFiles(files: UploadedFile[]): StructuredBriefFile[] {
    return files.map((file) => {
        const isPreviewable = PREVIEWABLE_IMAGE_EXTENSIONS.includes(
            file.name.split('.').pop()?.toLowerCase() || ''
        );

        return {
            __typename: 'StructuredBriefFile',
            size: parseInt(file.originalFileSize) || 0, // Provide a default value in case of parsing error.
            bucket: 'CimpressDocs',
            key: file.id,
            name: file.name,
            downloadUrl: file.downloadUrl,
            previewUrl: isPreviewable ? file.previewUrl : null,
            previewable: isPreviewable,
        };
    });
}

export function Input({
    id,
    required,
    subtext,
    allowedFileTypes,
    filesValue,
    register,
    setValue,
}: FilesFieldProps) {
    const { accessToken } = useIdentityContext();
    const { error: uploadError, uploadFiles, loading, deleteFile } = useFileUploader(accessToken);
    const [selectedFiles, setSelectedFiles] = useState<StructuredBriefFile[]>([]);
    const [error, setError] = useState<string | null>(null);
    const { setProcessingField } = useFieldProcessing();
    const setIsUploading = (isUploading: boolean) => {
        setProcessingField(id, isUploading);
    };

    const { briefId } = useBriefFormContext();
    const updateMutation = useUpdateFilesField(id, briefId, selectedFiles);

    useEffect(() => {
        setValue(id, filesValue);
        setSelectedFiles(filesValue);
    }, []);

    useEffect(() => {
        if (uploadError) {
            setError(__(`Sorry couldn't upload files. Please try again.`));
        }
    }, [uploadError]);

    useEffect(() => {
        setValue(id, selectedFiles);
    }, [id, selectedFiles, setValue]);

    useEffect(() => {
        const filesToUpdate = selectedFiles.map(
            ({ downloadUrl: _downloadUrl, previewUrl: _previewUrl, ...rest }) => rest
        );

        updateMutation(filesToUpdate);
    }, [selectedFiles, updateMutation]);

    const handleFileChange = async (
        event: React.ChangeEvent<HTMLInputElement> | React.DragEvent<HTMLDivElement>
    ) => {
        event.preventDefault();
        event.stopPropagation();

        const files = 'dataTransfer' in event ? event.dataTransfer.files : event.target.files;
        const filteredFiles = filterFiles(files, allowedFileTypes || []);

        if (files && files.length > 0) {
            // Check if some files were filtered out due to file type or size restrictions
            if (filteredFiles.length !== files.length) {
                setError(buildErrorMessage(allowedFileTypes || []));
                return;
            }

            // Clear any previous error if file size is within the limit
            setError(null);

            setIsUploading(true);
            try {
                const uploadedFiles = mapUploadFilesToBriefFiles(await uploadFiles(filteredFiles));
                const newSelectedFiles = [...selectedFiles, ...uploadedFiles];

                setSelectedFiles(newSelectedFiles);

                const filesToUpdate = newSelectedFiles.map(
                    ({ downloadUrl: _downloadUrl, previewUrl: _previewUrl, ...rest }) => rest
                );
                updateMutation(filesToUpdate);
                return newSelectedFiles;
            } finally {
                setIsUploading(false);
            }
        }
    };

    const handleFileRemove = async (fileIndex: number) => {
        const fileToRemove = selectedFiles[fileIndex];
        await deleteFile(fileToRemove.key);

        const updatedFiles = selectedFiles.filter((_, index) => index !== fileIndex);

        setSelectedFiles(updatedFiles);

        const filesToUpdate = updatedFiles.map(
            ({ downloadUrl: _downloadUrl, previewUrl: _previewUrl, ...rest }) => rest
        );
        updateMutation(filesToUpdate);
        return updatedFiles;
    };

    return (
        <Box
            marginBottom={'5'}
            key={id}
            onDrop={handleFileChange}
            onDragOver={(event: React.DragEvent<HTMLDivElement>) => event.preventDefault()} // Necessary to allow drop
        >
            <label>
                <DropZone
                    justifyContent="center"
                    alignItems="center"
                    flexDirection="column"
                    paddingY={8}
                    error={!!error}
                >
                    <FileInput
                        {...register(id, {
                            required: required ? __('This field is required') : false,
                            onChange: handleFileChange,
                        })}
                        type="file"
                        data-testid="file-input"
                        accept={allowedFileTypes ? allowedFileTypes.join(', ') : ''}
                        multiple
                    />

                    {loading ? (
                        <Spinner size="super" accessibleText={__('Loading…')} />
                    ) : (
                        <>
                            <Icon iconType="upload" size="24p" />
                            <Typography fontSize="xsmall" fontWeight="bold" paddingTop={'3'}>
                                {__('Upload logo or image')}
                            </Typography>
                        </>
                    )}
                </DropZone>
                <Typography
                    fontSize="xsmall"
                    textColor={error ? 'error' : 'subtle'}
                    paddingTop={'3'}
                    marginBottom={'5'}
                >
                    {error || subtext}
                </Typography>
            </label>
            <GridContainer>
                <Row>
                    {selectedFiles.map((file, index) => (
                        <Column span={4} key={`${file.name}-${index}`} marginBottom={'5'}>
                            <ImageContainer bgc="strong">
                                <FileCTA
                                    onClick={() => handleFileRemove(index)}
                                    buttonShape="round"
                                    compactMode
                                    skin="primary"
                                    aria-label="delete"
                                >
                                    <Icon iconType="deleteStudio" size="20p" skin="white" />
                                </FileCTA>
                                {file.previewable && file.previewUrl ? (
                                    <FluidImage src={file.previewUrl} alt={file.name} />
                                ) : (
                                    <IconContainer>
                                        <Icon
                                            iconType="fileGeneric"
                                            size="32p"
                                            marginBottom={'2'}
                                        />

                                        <Typography
                                            fontSize="xsmall"
                                            fontWeight="bold"
                                            marginBottom={'2'}
                                        >
                                            {__('Preview unavailable')}
                                        </Typography>
                                        <Typography fontSize="xsmall">{file.name}</Typography>
                                    </IconContainer>
                                )}
                            </ImageContainer>
                        </Column>
                    ))}
                </Row>
            </GridContainer>
        </Box>
    );
}
