import { useTracking } from '@vp/react-tracking';
import { useNavigate } from 'react-router-dom';
import { ApolloError } from '@apollo/client';
import { GraphQLErrors } from '@apollo/client/errors';
import { Button, Spinner } from '@vp/swan';
import { GraphQLError } from 'graphql';
import { CollaborateErrorCode } from '@99designs/graph-utils/types';
import { __ } from '@99designs/i18n';
import { GetCollaborationDocument, useAlertContext, useCollaborationContext } from '../../context';
import { GetEventsDocument } from '../ActivityPanel/Activity.generated';
import { useSubmitRevisionRequestMutation } from './SubmitRevisionRequest.generated';

export const SubmitButton = ({
    revisionRequestId,
    isSubmitEnabled,
    spinnerSize,
    width,
}: {
    revisionRequestId: number;
    isSubmitEnabled: boolean;
    spinnerSize?: React.ComponentProps<typeof Spinner>['size'];
    width?: React.ComponentProps<typeof Button>['width'];
}) => {
    const { tracking } = useTracking();
    const navigate = useNavigate();
    const { collaborationId } = useCollaborationContext();

    const [submitMutation, { loading }] = useSubmitRevisionRequestMutation({
        refetchQueries: () => [
            {
                query: GetCollaborationDocument,
                variables: {
                    collaborationPublicId: collaborationId,
                },
            },
            {
                query: GetEventsDocument,
                variables: {
                    collaborationPublicId: collaborationId,
                },
            },
        ],
        awaitRefetchQueries: true,
    });
    const { showError, showSuccess } = useAlertContext();
    const { trackingProperties } = useCollaborationContext();

    const onSubmit = async () => {
        try {
            const response = await submitMutation({
                variables: {
                    revisionRequestId,
                },
            });

            if (response.errors) {
                showError(__('Failed to submit revision request'));
                console.error(response.errors);
            } else {
                tracking.track('Collaboration Action Taken', {
                    type: 'Submit feedback',
                    ...trackingProperties,
                });
                showSuccess(__('Revision request submitted'));
                navigate('../activity', { relative: 'path' });
            }
        } catch (error) {
            let errorMessage = __('Failed to submit revision request');
            if (error instanceof ApolloError && error.graphQLErrors) {
                const msg = getGraphQLValidationErrorMessage(error.graphQLErrors);
                if (msg !== null) {
                    errorMessage = msg;
                }
            }
            showError(errorMessage);
            console.error(error);
        }
    };
    return (
        <Button
            onClick={onSubmit}
            skin="primary"
            width={width}
            disabled={!isSubmitEnabled || loading}
            data-testid="submit"
        >
            {loading && <Spinner size={spinnerSize} accessibleText={__('Loading...')} />}
            {__('Submit feedback')}
        </Button>
    );
};

function getGraphQLValidationErrorMessage(graphQLErrors: GraphQLErrors): string | null {
    let message: string | null = null;
    if (graphQLErrors) {
        graphQLErrors.forEach((err: GraphQLError) => {
            if (err.extensions && err.extensions.validationErrors) {
                const validationErrors: { [key in CollaborateErrorCode]: boolean } =
                    err.extensions.validationErrors;
                if (
                    validationErrors['ERROR_CODE_REVISION_REQUEST_MUST_HAVE_COMMENT'] !== undefined
                ) {
                    message = __('Revision request must have a general comment or pinned comment.');
                }
            }
        });
    }
    return message;
}
