import { useEffect } from 'react';
import { UseFormClearErrors, UseFormRegister, UseFormSetValue } from 'react-hook-form';
import { TextArea, Typography } from '@vp/swan';
import styled from 'styled-components';
import { __ } from '@99designs/i18n';
import { useFieldViewSkinContext } from '../../../context/FieldViewContext';
import { useBriefFormContext } from '../../BriefContext/BriefFormContext';
import { FormInput } from '../BriefForm';
import { useFieldProcessing } from '../FieldProcessingProvider';
import { SubText } from '../SubText';
import { Field_TextAreaField_Fragment } from '../brief.generated';
import { useDebouncedTextUpdate } from './text/useDebouncedTextUpdate';
import { useUpdateTextAreaField } from './text/useUpdateTextField';

export type TextAreaFieldProps = Field_TextAreaField_Fragment & {
    register: UseFormRegister<FormInput>;
    setValue: UseFormSetValue<FormInput>;
    clearErrors: UseFormClearErrors<FormInput>;
};

// TODO remove when we move to SWAN V2
const StyledTextArea = styled(TextArea)`
    border-radius: 8px;
`;

export function Input({
    id,
    register,
    setValue,
    required,
    placeholder,
    textAreaValue,
    subtext,
    maxLength,
    clearErrors,
}: TextAreaFieldProps) {
    const { briefId } = useBriefFormContext();
    const updateMutation = useUpdateTextAreaField(id, briefId, textAreaValue);
    const { setProcessingField } = useFieldProcessing();
    const debouncedTextUpdate = useDebouncedTextUpdate(id, updateMutation);

    useEffect(() => {
        // any changes to this value from empty to non-empty will appear to fail validation
        // for the first mutation unless we explicitly set it.
        setValue(id, textAreaValue ?? '');
    }, []);

    return (
        <>
            <StyledTextArea
                {...register(id, {
                    required: required ? __('This field is required') : false,
                    onChange: (e) => {
                        setValue(id, e.target.value);
                        clearErrors(id);
                        if (required && (e.target.value == null || e.target.value === '')) {
                            return;
                        }
                        setProcessingField(id, true);
                        debouncedTextUpdate(e.target.value);
                    },
                })}
                {...(textAreaValue ? { defaultValue: textAreaValue } : {})}
                placeholder={placeholder || ''}
                maxLength={maxLength}
                fullWidth
                resize="vertical"
                rows={5}
            />
            {subtext && <SubText>{subtext}</SubText>}
        </>
    );
}

interface TextAreaFieldViewProps {
    textAreaValue: Field_TextAreaField_Fragment['textAreaValue'];
}

export function View({ textAreaValue }: TextAreaFieldViewProps) {
    const skin = useFieldViewSkinContext();

    if (!textAreaValue) return null;

    return (
        <Typography fontSize={skin.fontSize} style={{ whiteSpace: 'pre-wrap' }}>
            {textAreaValue}
        </Typography>
    );
}
