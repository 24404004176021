import { useEffect } from 'react';
import { UseFormClearErrors, UseFormRegister, UseFormSetValue } from 'react-hook-form';
import { TextInput, Typography } from '@vp/swan';
import { __ } from '@99designs/i18n';
import { useFieldViewSkinContext } from '../../../context/FieldViewContext';
import { useBriefFormContext } from '../../BriefContext/BriefFormContext';
import { FormInput } from '../BriefForm';
import { useFieldProcessing } from '../FieldProcessingProvider';
import { Field_InputField_Fragment } from '../brief.generated';
import { useDebouncedTextUpdate } from './text/useDebouncedTextUpdate';
import { useUpdateTextField } from './text/useUpdateTextField';

export type InputFieldProps = Field_InputField_Fragment & {
    register: UseFormRegister<FormInput>;
    setValue: UseFormSetValue<FormInput>;
    clearErrors: UseFormClearErrors<FormInput>;
};

export function Input({
    id,
    required,
    placeholder,
    register,
    inputValue,
    immutable,
    maxLength,
    setValue,
    clearErrors,
}: InputFieldProps) {
    const { briefId } = useBriefFormContext();
    const updateMutation = useUpdateTextField(id, briefId, inputValue);
    const { setProcessingField } = useFieldProcessing();
    const debouncedTextUpdate = useDebouncedTextUpdate(id, updateMutation);

    useEffect(() => {
        // When a default value is supplied for a form field on creation, server side,
        // react-hook-form doesn't see that the value has been populated unless it gets set
        // explicitly. This logic ensures that the form library sees the default value.

        // In addition, any changes to this value from empty to non-empty will appear to
        // fail validation for the first mutation unless we explicitly set it.
        setValue(id, inputValue ?? '');
    }, []);

    return (
        <TextInput
            disabled={!!immutable && inputValue !== ''}
            data-testid={id}
            {...register(id, {
                required: required ? __('This field is required') : false,
                onChange: (e) => {
                    setValue(id, e.target.value);
                    clearErrors(id);
                    if (required && (e.target.value == null || e.target.value === '')) {
                        return;
                    }
                    setProcessingField(id, true);
                    debouncedTextUpdate(e.target.value ?? '');
                },
            })}
            {...(inputValue ? { defaultValue: inputValue } : {})}
            placeholder={placeholder ?? ''}
            maxLength={maxLength}
        />
    );
}

interface InputFieldViewProps {
    inputValue: Field_InputField_Fragment['inputValue'];
}

export function View({ inputValue }: InputFieldViewProps) {
    const skin = useFieldViewSkinContext();

    if (!inputValue) return null;

    return <Typography fontSize={skin.fontSize}>{inputValue}</Typography>;
}
