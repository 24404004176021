import { FlexBox, tokensRaw } from '@vp/swan';
import styled from 'styled-components';
import { ICON_SIZE } from './StepIconWrapper';

// This masks either side of the step diagram so that the border doesn't show as
// you resize the screen.
// For example:
// [mask](Step 1) +---------+ (Step 2) +---------+ (Step 3)[mask]
const lineMask = (position: 'left' | 'right') => `
        &:before {
            z-index: -1;
            content: '';
            position: absolute;
            ${position}: 0%;
            width: 50%;
            height: 100%;
            background: white;
        }
`;

const StepItemContainer = styled(FlexBox)`
    position: relative;
    z-index: 1;

    &:first-child {
        ${lineMask('left')}
    }

    &:last-child {
        ${lineMask('right')}
    }

    &:not(:last-child) {
        &:after {
            content: '';
            position: absolute;
            left: 50%;
            z-index: -1;
            top: ${ICON_SIZE / 2}px;
            height: 2px;
            width: 100rem;
            background: #dfdfdf;
        }
    }

    @media (max-width: ${tokensRaw.SwanBaseBreakpointXsEnd}) {
        &:after {
            display: none;
        }

        margin-bottom: 20px;
    }
`;

export default StepItemContainer;
