import React from 'react';
import {
    Box,
    Button,
    Divider,
    FlexBox,
    Icon,
    PipeSeparator,
    Section,
    Typography,
    tokensRaw,
} from '@vp/swan';
import { FormattedHTMLMessage } from '@99designs/design-services-common';
import { DrawerContent, DrawerFooter } from '@99designs/design-services-layouts';
import { __ } from '@99designs/i18n';
import { RevisionRequestPanelData, useUploadFiles } from '../../../../hooks';
import { ActionButtons } from '../../../../views';
import { UploadArea } from '../../../../views/RequestRevisionPanel/components';
import { ImageRef } from '../../DesignPreviewCanvas/ImageRef';
import { ThumbnailBarCompact } from '../../DesignPreviewCanvas/ThumbnailBar';
import { useDeliveryQueryParams } from '../../DesignPreviewCanvas/useDeliveryQueryParams';
import { FileWithDrawer } from '../../FileWithDrawer';
import { PinComment } from '../../PinComment/PinComment';
import { TruncateText } from '../../TruncateText';
import { useViewNavigation } from '../DrawerRevisionRequestViewContext';

export interface EditRevisionRequestProps {
    revisionRequest: RevisionRequestPanelData;
    handleUpdate: ({ comment }: { comment?: string | undefined }) => void;
    deliveryVersionId: string;
    images: { [key: number]: ImageRef[] };
    pins: PinComment[];
}

export const EditRevisionRequest: React.FC<EditRevisionRequestProps> = ({
    revisionRequest,
    images,
    pins,
}) => {
    const {
        uploadedFiles,
        removeUploadedFile,
        addUploadedFile,
        uploadStarted,
        uploadCompleted,
        isSubmitEnabled,
    } = useUploadFiles({ files: revisionRequest.files });
    const { id, generalComment, pinnedComments } = revisionRequest;

    const hasPinnedComments = pinnedComments.length > 0;
    const singularPinnedComments = __('{pinnedComments} pin comment');
    const pluralPinnedComments = __('{pinnedComments} pin comments');

    const hasMultipleVariations = Object.keys(images).length > 1;
    const { deliveryVariation, setDeliveryVariationQueryParams } = useDeliveryQueryParams(images);
    const { navigateTo } = useViewNavigation();

    return (
        <>
            <DrawerContent padding="0" data-testid="edit-revision-request">
                {hasMultipleVariations && (
                    <>
                        <Section padding="5">
                            <ThumbnailBarCompact
                                images={images}
                                pins={pins}
                                deliveryVariation={deliveryVariation}
                                setDeliveryVariationQueryParams={setDeliveryVariationQueryParams}
                            />
                        </Section>
                        <Divider />
                    </>
                )}
                <FlexBox alignItems="center" justifyContent="flex-start" padding="5">
                    <Button
                        onClick={() => navigateTo({ view: 'editGeneralComment' })}
                        iconPosition="left"
                        skin="tertiary"
                    >
                        {generalComment ? (
                            <>
                                <Icon iconType="edit" />
                                {__(' Edit general comment')}
                            </>
                        ) : (
                            <>
                                <Icon iconType="plus" />
                                {__('Add a general comment')}
                            </>
                        )}
                    </Button>

                    <PipeSeparator />

                    <UploadArea
                        addUploadedFile={addUploadedFile}
                        uploadStarted={uploadStarted}
                        uploadCompleted={uploadCompleted}
                        revisionRequestId={id}
                        uploadWithDropzone={false}
                    />
                </FlexBox>

                {generalComment && (
                    <>
                        <Divider />

                        <Section
                            padding="6"
                            onClick={() => navigateTo({ view: 'viewGeneralComment' })}
                        >
                            <FlexBox gap="3">
                                <Typography fontSkin="body-small-bold" style={{ flex: '1 0 auto' }}>
                                    {__('General comment:')}
                                </Typography>
                                <TruncateText fontSkin="body-small" $maxLines={2} $maxHeight="48px">
                                    {generalComment}
                                </TruncateText>
                            </FlexBox>
                        </Section>
                    </>
                )}

                {uploadedFiles.length > 0 && (
                    <>
                        <Divider />

                        <Section padding="6">
                            <FlexBox gap="3" alignItems="center">
                                <Typography fontSkin="body-small-bold">{__('Uploads:')}</Typography>
                                <FlexBox
                                    gap="5"
                                    alignItems="center"
                                    style={{
                                        overflowX: 'auto',
                                        marginRight: `-${tokensRaw.SwanSemSpace5}`,
                                        paddingRight: tokensRaw.SwanSemSpace5,
                                    }}
                                >
                                    {uploadedFiles.map((file, index) => (
                                        <Box style={{ lineHeight: 0 }} key={index}>
                                            <FileWithDrawer
                                                file={file}
                                                removeUploadedFile={removeUploadedFile}
                                                showDelete={true}
                                            />
                                        </Box>
                                    ))}
                                </FlexBox>
                            </FlexBox>
                        </Section>
                    </>
                )}
            </DrawerContent>

            <DrawerFooter>
                <FlexBox
                    justifyContent={hasPinnedComments ? 'space-between' : 'flex-end'}
                    gap="3"
                    alignItems="center"
                >
                    {hasPinnedComments && (
                        <Typography fontSkin="body-small-bold">
                            <FormattedHTMLMessage
                                message={
                                    pinnedComments.length === 1
                                        ? singularPinnedComments
                                        : pluralPinnedComments
                                }
                                values={{ pinnedComments: pinnedComments.length }}
                            />
                        </Typography>
                    )}
                    <ActionButtons isSubmitEnabled={isSubmitEnabled} revisionRequestId={id} />
                </FlexBox>
            </DrawerFooter>
        </>
    );
};
