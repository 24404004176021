import { useParams, useSearchParams } from 'react-router-dom';
import { useCollaborationContext } from '../context';
import { usePreviewPanelDataQuery } from '../graphql/PreviewPanelData.generated';
import { DELIVERY_VARIATION_KEY } from '../ui';

export function useWorkEntity() {
    const { deliveryVersionId } = useParams<{
        deliveryVersionId: string;
    }>();
    const [searchParams] = useSearchParams();
    const { collaborationId, locale, deliveries } = useCollaborationContext();
    // It's fine to call this hook multiple times, it will only make one request, the apollo cache handles this by default.
    const previewPanelResponse = usePreviewPanelDataQuery({
        variables: {
            collaborationPublicId: collaborationId,
            deliveryVersionId: Number(deliveryVersionId),
            locale,
        },
        skip: deliveryVersionId == null || deliveries.length === 0,
    });

    const deliveryVariationId = searchParams.has(DELIVERY_VARIATION_KEY)
        ? Number(searchParams.get(DELIVERY_VARIATION_KEY))
        : previewPanelResponse.data?.collaborationDelivery.variations[0]?.id;

    const variation = previewPanelResponse.data?.collaborationDelivery.variations.find(
        (v) => v.id === deliveryVariationId
    );

    return {
        workId: variation?.workId,
        workRevisionId: variation?.workRevisionId,
        workEntity: variation?.workEntity,
    };
}
