import React from 'react';
import { Icon, IconProps, Link, Typography, tokens, useScreenClass } from '@vp/swan';
import styled from 'styled-components';

export interface ToolbarButtonProps {
    icon: IconProps['iconType'];
    href?: string;
    onClick?: () => void;
    children: React.ReactNode;
    isActive?: boolean;
    openInNewTab?: boolean;
}

const ToolbarButtonIconContainer = styled.div`
    align-items: center;
    border-radius: ${tokens.SwanSemBorderRadiusStandard};
    display: flex;
    margin: auto;
    height: 36px;
    justify-content: center;
    transition: background-color 0.2s ease;
    width: 36px;

    @media (max-width: ${tokens.SwanBaseBreakpointXsEnd}) {
        height: 28px;
        width: 28px;
    }
`;

const ToolbarButtonLink = styled(Link)<{ $isActive?: boolean }>`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    appearance: none;
    background: none;
    border: none;
    transition: background-color 0.2s ease;

    ${ToolbarButtonIconContainer} {
        background-color: ${(props) =>
            props.$isActive ? tokens.SwanSemColorBgActive : tokens.SwanBaseColorWhite};
    }

    &:focus-visible {
        box-shadow: none;

        ${ToolbarButtonIconContainer} {
            box-shadow: 0 0 0 2px ${tokens.SwanBaseColorWhite},
                0 0 0 4px ${tokens.SwanBaseColorBlue700};
        }
    }

    &:hover {
        ${ToolbarButtonIconContainer} {
            background-color: ${(props) =>
                props.$isActive ? tokens.SwanSemColorBgActive : tokens.SwanSemColorBgHover};
        }
    }
`;

export const ToolbarButton: React.FC<ToolbarButtonProps> = ({
    children,
    icon,
    href,
    isActive,
    onClick,
    openInNewTab,
}) => {
    const screenClass = useScreenClass();

    return (
        <ToolbarButtonLink
            role="button"
            $isActive={isActive}
            {...(href && { href, skin: 'unstyled' })}
            {...(onClick && { onClick: onClick, as: 'button', skin: 'unstyled' })}
            {...(openInNewTab && { target: '_blank', rel: 'noopener noreferrer' })}
        >
            <ToolbarButtonIconContainer>
                <Icon
                    compactMode
                    iconType={icon}
                    alt=""
                    skin={'standard'}
                    width={screenClass === 'xs' ? '16px' : '24px'}
                />
            </ToolbarButtonIconContainer>
            <Typography fontSkin="body-small" textAlign="center" textColor="standard">
                {children}
            </Typography>
        </ToolbarButtonLink>
    );
};
