import React from 'react';
import { Box, Column, FlexBox, ResponsiveImage, Typography } from '@vp/swan';
import styled from 'styled-components';
import { PreviewModal, PreviewModalVariant } from '@99designs/design-services-common';
import { View } from '@99designs/graph-utils/types';

export interface WorkEntityViewsProps {
    views: View[];
}

const StyledResponsiveImage = styled(ResponsiveImage)`
    object-fit: cover;
    cursor: zoom-in;
    width: 100%;
`;

const StyledBox = styled(Box)`
    width: 100%;
    height: 100%;
    display: inline-block;
`;

const StyledColumn = styled(Column)`
    display: inline-block;
`;

export default function WorkEntityViews({ views }: WorkEntityViewsProps) {
    const [isOpen, setIsOpen] = React.useState(false);
    const [currentSlide, setCurrentSlide] = React.useState(0);

    const images = views.map((view) => {
        return {
            url: view._links.image.href,
            title: view._links.image.title,
        };
    });

    return (
        <>
            <PreviewModal
                isOpen={isOpen}
                setIsOpen={setIsOpen}
                currentSlide={currentSlide}
                setCurrentSlide={setCurrentSlide}
                previewFiles={images}
                variant={PreviewModalVariant.WORK_ENTITY}
            />
            {views.map(({ _links }, i) => (
                <StyledColumn span={5}>
                    <FlexBox flexDirection="column" alignItems="center">
                        <StyledBox py={'3'} align="center">
                            <StyledResponsiveImage
                                src={_links.image.href}
                                alt={_links.image.title}
                                role="button"
                                tabIndex={0}
                                onClick={() => {
                                    setCurrentSlide(i);
                                    setIsOpen(!isOpen);
                                }}
                            />
                        </StyledBox>
                        <Typography fontSize="small" fontWeight="bold" textAlign="center">
                            {_links.image.title}
                        </Typography>
                    </FlexBox>
                </StyledColumn>
            ))}
        </>
    );
}
