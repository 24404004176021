import React, { useRef, useState } from 'react';
import {
    ReactZoomPanPinchRef,
    TransformComponent,
    TransformWrapper,
    useControls,
    useTransformEffect,
} from 'react-zoom-pan-pinch';
import {
    Buttonbar,
    ButtonbarButton,
    ButtonbarButtonLabel,
    Hidden,
    ZoomControls,
    ZoomControlsIn,
    ZoomControlsOut,
    ZoomControlsValue,
    tokens,
    tokensRaw,
    useScreenClass,
} from '@vp/swan';
import styled from 'styled-components';
import { __ } from '@99designs/i18n';
import { PinComment } from '../PinComment/PinComment';
import { VersionDropdown } from '../VersionDropdown';
import { ImageRef } from './ImageRef';
import { PreviewImage } from './PreviewImage';
import { ThumbnailBar } from './ThumbnailBar';
import { useDeliveryQueryParams } from './useDeliveryQueryParams';

export type SingleImageDesignPreviewCanvasProps = {
    images: { [key: number]: ImageRef[] };
    pins: PinComment[];
    handleAddUpdatePinComment?: (pin: PinComment) => Promise<void>;
    handleRemovePinComment?: (pin: PinComment) => Promise<void>;
    interactable?: boolean;
    revisionsRemaining: number;
    revisionsTotal: number;
    version: number;
    setVersion: (version: number) => void;
    isDesignerView: boolean;
};

export interface PercentCoordinates {
    xPercent: number;
    yPercent: number;
}

// Styled components
const CanvasWrapper = styled.div`
    background-color: ${tokensRaw.SwanBaseColorGrey100};
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow-x: hidden;
    position: relative;
`;

const Header = styled.div`
    position: absolute;
    width: 100%;
    top: 0;
    display: flex;
    align-items: center;

    justify-content: space-between;
    padding: ${tokens.SwanSemSpace8} ${tokens.SwanSemSpace7} ${tokens.SwanSemSpace4};
    gap: ${tokens.SwanSemSpaceBetweenActions};
    z-index: 2;

    @media (max-width: ${tokensRaw.SwanBaseBreakpointSmEnd}) {
        align-items: flex-start;
    }
`;

const CanvasArea = styled.div`
    flex: auto;
    overflow: hidden;
    position: relative;
    z-index: 1;
    cursor: grab;
    &:active {
        cursor: grabbing;
    }
`;

const DisplayControls = styled.div`
    display: inline-flex;
    gap: ${tokens.SwanSemSpaceBetweenActions};
    align-items: center;
    @media (max-width: ${tokensRaw.SwanBaseBreakpointMdEnd}) {
        flex-direction: column;
        align-items: flex-end;
    }
`;

// Zoom controls for the canvas. This uses hooks from react-zoom-pan-pinch to manipulate the view of the canvas, and it also feeds back the value of the zoom amount
const Zoom = () => {
    const [zoomValue, setZoomValue] = useState(100);
    const { zoomIn, zoomOut, resetTransform } = useControls();

    useTransformEffect(({ state }) => {
        setZoomValue(Math.round((state.scale * 100) / 5) * 5);
    });

    return (
        <ZoomControls>
            <ZoomControlsIn accessibleText={__('Zoom in')} onClick={() => zoomIn()} />
            <ZoomControlsOut accessibleText={__('Zoom out')} onClick={() => zoomOut()} />
            <ZoomControlsValue
                accessibleText={__('Current zoom level: {{zoomValue}}%', { zoomValue })}
                onClick={() => resetTransform()}
            >
                {zoomValue}%
            </ZoomControlsValue>
        </ZoomControls>
    );
};

/**
 * A design preview canvas built on top of the react-zoom-pan-pinch library.
 * This library perfomantly manages the translations and scaling of our design
 * images. It also provides gesture and touch support for mobile.
 */
export const SingleImageDesignPreview: React.FC<SingleImageDesignPreviewCanvasProps> = ({
    images,
    pins,
    handleAddUpdatePinComment,
    handleRemovePinComment,
    interactable = false,
    revisionsRemaining,
    version,
    setVersion,
    isDesignerView,
    revisionsTotal,
}) => {
    const transformComponentRef = useRef<ReactZoomPanPinchRef | null>(null);
    const { deliveryVariation, selectedSurface, setDeliveryVariationQueryParams } =
        useDeliveryQueryParams(images);

    const hasMultipleVariations = Object.keys(images).length > 1;
    const currentImage = images[deliveryVariation][selectedSurface];
    const screenClass = useScreenClass();
    const paddingCanvas =
        screenClass === 'xs'
            ? `120px ${tokensRaw.SwanSemSpace7} 400px`
            : `160px ${tokensRaw.SwanSemSpace7}`;

    return (
        <CanvasWrapper>
            <TransformWrapper
                wheel={{ wheelDisabled: true }}
                doubleClick={{ disabled: true }}
                ref={transformComponentRef}
                initialScale={1}
                minScale={0.1}
                maxScale={5}
                centerZoomedOut={true}
            >
                <Header>
                    <div>
                        <Hidden xs>
                            <VersionDropdown
                                version={version}
                                setVersion={setVersion}
                                revisionsTotal={revisionsTotal}
                                revisionsRemaining={revisionsRemaining}
                                // do not allow changing version when requesting a revision
                                interactable={!interactable}
                            />
                        </Hidden>
                    </div>

                    <DisplayControls>
                        <Buttonbar
                            hidden={images[deliveryVariation].length <= 1}
                            variant={'single-select'}
                            selectedValue={`${selectedSurface}`}
                            onSelectedValueChange={(val) => {
                                const idx = val ? +val : 0;
                                setDeliveryVariationQueryParams({
                                    selectedSurface: idx,
                                });
                            }}
                        >
                            {images[deliveryVariation].map((img, idx) => (
                                <ButtonbarButton key={`${img.title}-${idx}`} value={`${idx}`}>
                                    <ButtonbarButtonLabel>{img.title}</ButtonbarButtonLabel>
                                </ButtonbarButton>
                            ))}
                        </Buttonbar>
                        <Hidden xs>
                            <Zoom />
                        </Hidden>
                    </DisplayControls>
                </Header>
                <CanvasArea>
                    <TransformComponent
                        wrapperStyle={{
                            width: '100%',
                            height: '100%',
                        }}
                        contentStyle={{
                            width: '100%',
                            minHeight: '100%',
                            padding: paddingCanvas,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            flexDirection: 'column',
                            gap: `${tokensRaw.SwanSemSpace9}`,
                        }}
                    >
                        <PreviewImage
                            isDesignerView={isDesignerView}
                            deliveryVariation={deliveryVariation}
                            selectedSurface={selectedSurface}
                            label={currentImage.label}
                            workId={currentImage.workId}
                            src={currentImage.href}
                            alt={__('Image {{title}}', { title: currentImage.title })}
                            interactable={interactable}
                            handleAddUpdatePinComment={handleAddUpdatePinComment}
                            handleRemovePinComment={handleRemovePinComment}
                            pins={pins}
                        />
                    </TransformComponent>
                </CanvasArea>
                {hasMultipleVariations && (
                    <Hidden xs>
                        <ThumbnailBar
                            images={images}
                            pins={pins}
                            deliveryVariation={deliveryVariation}
                            setDeliveryVariationQueryParams={setDeliveryVariationQueryParams}
                        />
                    </Hidden>
                )}
            </TransformWrapper>
        </CanvasWrapper>
    );
};
