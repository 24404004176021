import { useEffect, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { KeepScale } from 'react-zoom-pan-pinch';
import { Typography } from '@vp/swan';
import styled from 'styled-components';
import { __ } from '@99designs/i18n';
import { PinComment, PinCommentBadge, getDataPopoverTarget } from '../PinComment/PinComment';
import { SaveAsCopy } from './SaveAsCopy';
import { useClickWithoutDrag } from './useClickWithoutDrag';

// Types
export interface PreviewImageProps {
    label: string;
    deliveryVariation: number;
    selectedSurface: number;
    src: string;
    alt: string;
    interactable?: boolean;
    handleAddUpdatePinComment?: (pin: PinComment) => Promise<void>;
    handleRemovePinComment?: (pin: PinComment) => Promise<void>;
    pins: PinComment[];
    workId: string;
    isDesignerView: boolean;
}

export interface PercentCoordinates {
    xPercent: number;
    yPercent: number;
}

// Styled components
const StyledImageContainer = styled.div`
    position: relative;
    max-width: 600px;
`;

const StyledImage = styled.img`
    display: inline-block;
    width: 100%;
    max-height: 100%;
`;

const KeepScaleLeftContainer = styled.div`
    position: absolute;
    bottom: 100%;
    left: 0;
    max-width: ${(props: { hasaMaxWidth: boolean }) => (props.hasaMaxWidth ? '50%' : '100%')};
`;

const KeepScaleRightContainer = styled.div`
    position: absolute;
    bottom: 100%;
    right: 0;
    max-width: 50%;
`;

const CopyButton = styled.button`
    background: none;
    border: none;
    color: inherit;
    svg {
        vertical-align: middle;
    }
`;

const IdDisplay: React.FC<{ workId: string }> = ({ workId }) => {
    const successMessage = __('ID copied to clipboard');
    const [showSuccess, setShowSuccess] = useState(false);
    const copyToClipboard = () => {
        navigator.clipboard.writeText(workId).then(() => {
            setShowSuccess(true);
            setTimeout(() => setShowSuccess(false), 1000);
        });
    };

    return (
        <KeepScaleRightContainer>
            <KeepScale>
                <Typography onClick={copyToClipboard} fontSize={'xsmall'} textColor={'subtle'}>
                    {showSuccess ? successMessage : __('ID:') + ' ' + workId}
                    <CopyButton
                        aria-label={__('Copy to clipboard')}
                        title={__('Copy to clipboard')}
                    >
                        <SaveAsCopy />
                    </CopyButton>
                </Typography>
            </KeepScale>
        </KeepScaleRightContainer>
    );
};

export function useImageInteraction(
    deliveryVariation: number,
    selectedSurface: number,
    pins: PinComment[],
    interactable?: boolean,
    handleAddUpdatePinComment?: (pin: PinComment) => Promise<void>,
    handleRemovePinComment?: (pin: PinComment) => Promise<void>
) {
    const { dragging, handleMouseDown, handleMouseMove } = useClickWithoutDrag();
    const imageRef = useRef<HTMLImageElement | null>(null);
    const [pinCoordinates, setPinCoordinates] = useState<PercentCoordinates | null>(null);
    const [searchParams] = useSearchParams();
    const commentId = searchParams.has('commentId') ? searchParams.get('commentId') : null;

    // Click handler to capture coordinates for a new pin comment
    const handleClick = (e: React.MouseEvent) => {
        const imgNode = imageRef.current;
        if (!commentId && !pinCoordinates && !dragging && interactable && imgNode) {
            const xPercent = (e.nativeEvent.offsetX / imgNode.width) * 100;
            const yPercent = (e.nativeEvent.offsetY / imgNode.height) * 100;
            setPinCoordinates({ xPercent, yPercent });
        }
    };

    useEffect(() => {
        if (commentId != null) {
            setPinCoordinates(null);
        }
    }, [commentId]);

    // Refresh if the preview changes
    useEffect(() => {
        setPinCoordinates(null);
    }, [deliveryVariation, selectedSurface, setPinCoordinates]);

    // Reset pin coordinates on close of the PinBadge popover
    const onNewPinClose = () => {
        setPinCoordinates(null);
    };

    const handleAddUpdate = async (pin: PinComment) => {
        if (handleAddUpdatePinComment) {
            await handleAddUpdatePinComment(pin);
        }
        setPinCoordinates(null);
    };

    const handleRemove = async (pin: PinComment) => {
        if (handleRemovePinComment) {
            await handleRemovePinComment(pin);
        }
        setPinCoordinates(null);
    };

    const visiblePinComments =
        pins?.filter(
            (pc) =>
                selectedSurface === pc.selectedSurface &&
                deliveryVariation === pc.deliveryVariationId
        ) ?? [];

    return {
        imageRef,
        handleClick,
        handleMouseDown,
        handleMouseMove,
        pinCoordinates,
        visiblePinComments,
        handleAddUpdate,
        handleRemove,
        onNewPinClose,
        commentId,
    };
}

/**
 * Renders a single design "surface", with in-built handlers to capture mouse
 * click events. These are used to calculate and position pin comments.
 */
export function PreviewImage({
    src,
    alt,
    interactable,
    handleAddUpdatePinComment,
    handleRemovePinComment,
    pins,
    deliveryVariation,
    selectedSurface,
    workId,
    isDesignerView,
    label,
}: PreviewImageProps) {
    const {
        imageRef,
        handleClick,
        handleMouseDown,
        handleMouseMove,
        pinCoordinates,
        visiblePinComments,
        handleAddUpdate,
        handleRemove,
        onNewPinClose,
        commentId,
    } = useImageInteraction(
        deliveryVariation,
        selectedSurface,
        pins,
        interactable,
        handleAddUpdatePinComment,
        handleRemovePinComment
    );

    return (
        <PreviewInner
            label={label}
            isDesignerView={isDesignerView}
            workId={workId}
            handleClick={handleClick}
            handleMouseDown={handleMouseDown}
            handleMouseMove={handleMouseMove}
            imageRef={imageRef}
            src={src}
            alt={alt}
            pinCoordinates={pinCoordinates}
            commentId={commentId}
            handleAddUpdate={handleAddUpdate}
            handleRemove={handleRemove}
            onNewPinClose={onNewPinClose}
            visiblePinComments={visiblePinComments}
            selectedSurface={selectedSurface}
            deliveryVariation={deliveryVariation}
            interactable={interactable}
        />
    );
}

export interface PreviewInnerProps {
    label: string | undefined;
    isDesignerView: boolean;
    workId: string;
    handleClick: (e: React.MouseEvent) => void;
    handleMouseDown: (e: React.MouseEvent) => void;
    handleMouseMove: (e: React.MouseEvent) => void;
    imageRef: React.RefObject<HTMLImageElement>;
    src: string;
    alt: string;
    pinCoordinates: PercentCoordinates | null;
    commentId: string | null;
    handleAddUpdate: (pin: PinComment) => Promise<void>;
    handleRemove: (pin: PinComment) => Promise<void>;
    onNewPinClose: () => void;
    visiblePinComments: PinComment[];
    selectedSurface: number;
    deliveryVariation: number;
    interactable: boolean | undefined;
}

export function PreviewInner({
    label,
    isDesignerView,
    workId,
    handleClick,
    handleMouseDown,
    handleMouseMove,
    imageRef,
    src,
    alt,
    pinCoordinates,
    commentId,
    handleAddUpdate,
    handleRemove,
    onNewPinClose,
    visiblePinComments,
    selectedSurface,
    deliveryVariation,
    interactable,
}: PreviewInnerProps) {
    return (
        <StyledImageContainer>
            {label && (
                <KeepScaleLeftContainer hasaMaxWidth={isDesignerView}>
                    <KeepScale style={{ transformOrigin: 'left bottom' }}>
                        <Typography fontSize={'xsmall'} textColor={'subtle'}>
                            {label}
                        </Typography>
                    </KeepScale>
                </KeepScaleLeftContainer>
            )}
            {/* if the screen in not interactable then we are in the designer view and the workId should be shown */}
            {isDesignerView && <IdDisplay workId={workId} />}

            <div
                onClick={handleClick}
                onMouseDown={handleMouseDown}
                onMouseMove={handleMouseMove}
                role="button"
            >
                <StyledImage ref={imageRef} src={src} alt={alt} />
            </div>
            {pinCoordinates && !commentId && (
                <PinCommentBadge
                    handleAddUpdate={handleAddUpdate}
                    handleRemove={handleRemove}
                    isNewComment={true}
                    isReadOnly={false}
                    key={`new-${getDataPopoverTarget(
                        pinCoordinates.xPercent,
                        pinCoordinates.yPercent
                    )}`}
                    onPinClose={onNewPinClose}
                    pin={{
                        ...pinCoordinates,
                        viewIndex: visiblePinComments.length + 1,
                        commentText: '',
                        selectedSurface: selectedSurface,
                        deliveryVariationId: deliveryVariation,
                    }}
                />
            )}
            {visiblePinComments.map((p) => (
                <PinCommentBadge
                    pin={p}
                    isReadOnly={!interactable}
                    key={getDataPopoverTarget(p.xPercent, p.yPercent)}
                    handleAddUpdate={handleAddUpdate}
                    handleRemove={handleRemove}
                />
            ))}
        </StyledImageContainer>
    );
}
