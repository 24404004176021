import { FlexBox, tokensRaw } from '@vp/swan';
import styled from 'styled-components';

const StepsContainer = styled(FlexBox)`
    overflow: hidden;
    background: white;

    @media (max-width: ${tokensRaw.SwanBaseBreakpointXsEnd}) {
        flex-direction: column;
    }
`;

export default StepsContainer;
