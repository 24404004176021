interface AnalyticsDataParams {
    collaborationPublicId: string;
    label: string;
    destinationPath: string;
    ctaType: string;
    ctaValue: string;
}

export const getAnalyticsData = ({
    collaborationPublicId,
    label,
    destinationPath,
    ctaType,
    ctaValue,
}: AnalyticsDataParams) => {
    return {
        label: label,
        category: 'Design Services',
        pageSection: 'Design Services',
        pageStage: 'My Account',
        pageName: 'DesignServices:Collaboration Activity',
        eventDetail: `/design/collaboration/${collaborationPublicId};${destinationPath};Collaboration Activity Event;${ctaValue}`,
        navigationDetail: ctaType,
    };
};
