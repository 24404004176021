import React, { PropsWithChildren } from 'react';
import { Column, FlexBox, GridContainer, Row, responsive, useScreenClass } from '@vp/swan';
import { BottomBar } from './BottomBar';
import { ProgressBar } from './ProgressBar';

const ResponsiveFlexBox = responsive(FlexBox);

const CenteredColumn: React.FC<{ children: React.ReactNode }> = ({ children }) => (
    <Column
        spanXs={12}
        spanSm={12}
        spanMd={12}
        offsetLg={3}
        offsetXl={4}
        offsetMd={0}
        span={4}
        spanLg={6}
    >
        {children}
    </Column>
);

export const StepLayout = ({
    children,
    NextAction,
    PreviousAction,
}: {
    children: React.ReactNode;
    NextAction?: React.ComponentType;
    PreviousAction?: React.ComponentType;
}) => {
    const screenClass = useScreenClass();
    return (
        <>
            <ShellInner>
                <GridContainer>
                    <Row>
                        <CenteredColumn>
                            {screenClass !== 'xs' && <ProgressBar />}
                            {children}
                        </CenteredColumn>
                    </Row>
                </GridContainer>
            </ShellInner>
            <BottomBar NextAction={NextAction} PreviousAction={PreviousAction} />
        </>
    );
};

function ShellInner(props: PropsWithChildren) {
    return (
        <ResponsiveFlexBox
            flexDirection="column"
            alignItems="center"
            justifyContent="flex-start"
            paddingTop={0}
            paddingBottom={8}
            style={{
                flex: 1,
                height: '100%',
                minWidth: 0,
                minHeight: 0,
                overflowY: 'auto',
            }}
            sm={{
                paddingTop: 8,
            }}
        >
            {props.children}
        </ResponsiveFlexBox>
    );
}
