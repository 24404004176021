import {
    BriefProvider,
    BriefQuery,
    FieldGroupVariant,
    fieldGroups,
    useBriefQuery,
} from '@99designs/feature-design-services-brief';
import { useCollaborationContext } from '../../context';
import { ErrorLoadingState } from '../../ui';

export type BriefLayoutData = {
    groups: ReturnType<typeof fieldGroups>;
    data: BriefQuery;
    collaborationId: string;
};

export const BriefLayout = ({
    children,
}: {
    children: (data: BriefLayoutData) => JSX.Element | undefined;
}) => {
    const { collaborationId, briefId } = useCollaborationContext();
    const { data, loading, error } = useBriefQuery({
        variables: {
            id: briefId,
        },
    });

    return (
        <ErrorLoadingState loading={loading} errors={error} data={data}>
            {(data) => {
                const groups = fieldGroups(data.brief, FieldGroupVariant.READONLY);

                return (
                    <BriefProvider>
                        {children({
                            groups,
                            data,
                            collaborationId,
                        })}
                    </BriefProvider>
                );
            }}
        </ErrorLoadingState>
    );
};
