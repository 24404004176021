import React, { ReactElement } from 'react';
import FormattedMessage from './FormattedMessage';
import formatMessage from './formatMessage';

interface FormattedReactMessageProps {
    message: string;
    values?: { [key: string]: string | number };
    replacements: { [key: string]: ReactElement };
}

function eachRegxMatched(
    msg: string,
    regx: RegExp,
    onMatched: (matched: string, value: string) => void
) {
    let matchedGroup: Array<string> | null;
    do {
        matchedGroup = regx.exec(msg);
        if (!matchedGroup) {
            break;
        }
        onMatched(matchedGroup[0], matchedGroup[1]);
    } while (matchedGroup);
}

export function FormattedReactMessage({
    message,
    replacements,
    values = {},
}: FormattedReactMessageProps) {
    const formattedMessageValues: { [key: string]: ReactElement | string | number } = { ...values };

    Object.keys(replacements).forEach(function (tag: string) {
        const element = replacements[tag];
        const tagRegx = new RegExp(`<${tag}>([^<]+)</${tag}>`, 'g');

        eachRegxMatched(message, tagRegx, function (matchWithTag: string, matchWithoutTag: string) {
            message = message.replace(matchWithTag, `{${tag}}`);
            formattedMessageValues[tag] = React.cloneElement(
                element,
                {},
                formatMessage(matchWithoutTag, values)
            );
        });
    });

    return <FormattedMessage message={message} values={formattedMessageValues} />;
}
