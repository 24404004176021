import React, { createContext, useCallback, useContext, useState } from 'react';

interface FieldProcessingContextType {
    processingFields: string[];
    setProcessingField: (fieldId: string, isProcessing: boolean) => void;
}

const FieldProcessingContext = createContext<FieldProcessingContextType | undefined>(undefined);

export function useFieldProcessing() {
    const context = useContext(FieldProcessingContext);
    if (!context) {
        return {
            processingFields: [],
            setProcessingField: () => {
                console.warn('useFieldProcessing must be used within a FieldProcessingProvider');
            },
        };
    }
    return context;
}

export function FieldProcessingProvider({ children }: { children: React.ReactNode }) {
    const [processingFields, setProcessingFields] = useState<string[]>([]);

    const setProcessingField = useCallback(
        (fieldId: string, isProcessing: boolean) => {
            setProcessingFields((prev) =>
                isProcessing ? [...prev, fieldId] : prev.filter((id) => id !== fieldId)
            );
        },
        [setProcessingFields]
    );

    return (
        <FieldProcessingContext.Provider value={{ processingFields, setProcessingField }}>
            {children}
        </FieldProcessingContext.Provider>
    );
}
