import Bugsnag from '@bugsnag/js';

async function downloadBlob(url: string) {
    const response = await fetch(url);
    if (!response.ok) {
        Bugsnag.notify(new Error('could not download file'), (report) => {
            report.addMetadata('url', { url });
            report.addMetadata('response', { response });
        });

        return;
    }

    return response.blob();
}

export async function download(url: string, filename: string) {
    const blob = await downloadBlob(url);
    if (!blob) {
        Bugsnag.notify(new Error('could not convert response to blob'), (report) => {
            report.addMetadata('url', { url });
        });

        return;
    }

    // JS way to download a file via DOM manipulation. ID is used for tests.

    const a = document.createElement('a');

    a.style.display = 'none';
    a.id = 'artificial-download-link';
    a.href = window.URL.createObjectURL(blob);
    a.download = filename;

    document.body.appendChild(a);
    a.click();

    // Clean up:

    window.URL.revokeObjectURL(url);
    document.body.removeChild(a);
}
