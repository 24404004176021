import { ReactNode, useEffect, useState } from 'react';
import { ApolloProvider } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import Bugsnag from '@bugsnag/js';
import { FlexBox, Spinner } from '@vp/swan';
import { createBrowserApolloClient } from '@99designs/graph-utils';
import { __ } from '@99designs/i18n';
import { useIdentityContext } from '../../components';
import { APP_TENANT } from '../../consts';

export function AuthenticatedApolloProvider({
    locale,
    environment,
    children,
    endpoint,
    hideLoader,
}: {
    locale: string;
    environment?: string;
    children: ReactNode;
    endpoint: string;
    hideLoader?: boolean;
}) {
    const { accessToken } = useIdentityContext();
    const authHeader = accessToken ? { Authorization: `Bearer ${accessToken}` } : undefined;

    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (accessToken) {
            setLoading(false);
        }
    }, [accessToken]);

    if (loading && !hideLoader) {
        return (
            <FlexBox justifyContent="center" marginBottom={'7'}>
                <Spinner size="super" accessibleText={__('Loading…')} />
            </FlexBox>
        );
    }

    const errorLink = onError(({ graphQLErrors, networkError, operation, response }) => {
        if (graphQLErrors)
            graphQLErrors.forEach(({ message, locations, path }) =>
                Bugsnag.notify(new Error(message), (event) => {
                    event.addMetadata('GraphQL', {
                        query: operation.query.loc?.source.body,
                        variables: operation.variables,
                        response: response,
                        locations: locations,
                        path: path,
                    });
                })
            );
        if (networkError) {
            Bugsnag.notify(networkError);
        }
    });

    const apolloClient = createBrowserApolloClient(
        endpoint,
        {
            headers: {
                locale,
                Environment: environment ?? (process.env.NODE_ENV || 'development'),
                tenant: APP_TENANT,
                ...authHeader,
            },
        },
        errorLink
    );

    return <ApolloProvider client={apolloClient}>{children}</ApolloProvider>;
}
