import React from 'react';

interface DrawerPortalProviderProps {
    children: React.ReactNode;
}

// Should be set at an app level so that all drawers can be rendered at the same level
export const DrawerPortalProvider: React.FC<DrawerPortalProviderProps> = ({ children }) => {
    return (
        <>
            <div id="drawer-app-portal" />
            {children}
        </>
    );
};
