import { Box, tokensRaw } from '@vp/swan';
import styled from 'styled-components';

export const ICON_SIZE = 68;

const StepIconWrapper = styled(Box)<{ $circleColor?: string }>`
    width: ${ICON_SIZE}px;
    height: ${ICON_SIZE}px;
    border-radius: 50%;
    box-shadow: 0 0 0 30px white;
    display: flex;
    justify-content: center;
    align-items: center;
    svg {
        fill: #5c5c5c;
        ${({ $circleColor }) =>
            $circleColor &&
            `
            circle {
                fill: ${$circleColor};
            }
        `}
    }

    &[data-active='true'] {
        svg {
            fill: black;
        }
    }

    @media (max-width: ${tokensRaw.SwanBaseBreakpointXsEnd}) {
        box-shadow: none;
    }
`;

export default StepIconWrapper;
