import { useTracking } from '@vp/react-tracking';
import { useState } from 'react';
import { Outlet, useParams } from 'react-router-dom';
import {
    ActionOptions,
    ActionProvider,
    CollaborationProvider,
    ErrorLoadingFullPage,
    Shell,
    TopbarActions,
    getCollaborationStatus,
    useCollaborationContext,
    useGetCollaborationQuery,
    useMenuProps,
    useNavigationLinks,
} from '@99designs/collaborate-views';
import { DrawerPortalProvider, SwanModeProvider } from '@99designs/design-services-layouts';
import { __ } from '@99designs/i18n';

type CollaborateParams = {
    collaborationId: string;
};

export function CollaborateLayout() {
    const { collaborationId } = useParams<CollaborateParams>() as CollaborateParams;
    const { data, error, loading } = useGetCollaborationQuery({
        variables: {
            collaborationPublicId: collaborationId,
        },
    });

    return (
        <ErrorLoadingFullPage loading={loading} error={error} data={data}>
            {(data) => (
                <CollaborationProvider
                    collaboration={data.getCollaboration}
                    collaborationId={collaborationId}
                >
                    <SwanModeProvider value={{ isCompactMode: true }}>
                        <DrawerPortalProvider>
                            <Collaborate />
                        </DrawerPortalProvider>
                    </SwanModeProvider>
                </CollaborationProvider>
            )}
        </ErrorLoadingFullPage>
    );
}

export function Collaborate() {
    const { permissions } = useCollaborationContext();
    const [overriddenActionOptions, setOverriddenActionOptions] = useState<ActionOptions | null>(
        null
    );

    return (
        <ActionProvider
            permissions={permissions}
            overriddenActionOptions={overriddenActionOptions}
            setOverriddenActionOptions={setOverriddenActionOptions}
        >
            <CollaborateShell />
        </ActionProvider>
    );
}

export function CollaborateShell() {
    const collaborateData = useCollaborationContext();
    const navigationLinks = useNavigationLinks();
    const menuProps = useMenuProps();
    const { tracking } = useTracking();
    const collaborationStatus = getCollaborationStatus(collaborateData.state);

    return (
        <Shell
            topbarProps={{
                status: collaborationStatus,
                navUrl: '/graphic-design/dashboard',
                navLabel: __('My Design Services'),
                onLinkMenuChange: () =>
                    tracking.track('Navigation Clicked', collaborateData.trackingProperties),
                menuProps,
                children: TopbarActions(),
            }}
            sidebarProps={{ links: navigationLinks }}
            bottomBarProps={{
                bottomBarLinks: navigationLinks,
                onMenuLinkClick: () =>
                    tracking.track('Navigation Clicked', collaborateData.trackingProperties),
            }}
        >
            <Outlet />
        </Shell>
    );
}
