import { SWAN_STYLE_KEY_MAP, SwanHead } from '@vp/swan';
import { swanBaseUrl } from './SwanConfiguration';

const eagerlyLoadedStyles = [
    SWAN_STYLE_KEY_MAP.accordion,
    SWAN_STYLE_KEY_MAP.alertBox,
    SWAN_STYLE_KEY_MAP.banner,
    SWAN_STYLE_KEY_MAP.button,
    SWAN_STYLE_KEY_MAP.carousel,
    SWAN_STYLE_KEY_MAP.core,
    SWAN_STYLE_KEY_MAP.grid,
    SWAN_STYLE_KEY_MAP.hidden,
    SWAN_STYLE_KEY_MAP.icon,
    SWAN_STYLE_KEY_MAP.listbox,
    SWAN_STYLE_KEY_MAP.modalDialog,
    SWAN_STYLE_KEY_MAP.tabs,
    SWAN_STYLE_KEY_MAP.thumbnailsHero,
    SWAN_STYLE_KEY_MAP.pagination,
    SWAN_STYLE_KEY_MAP.popover,
    SWAN_STYLE_KEY_MAP.progressiveImage,
    SWAN_STYLE_KEY_MAP.selectionSet,
    SWAN_STYLE_KEY_MAP.spinner,
    SWAN_STYLE_KEY_MAP.standardTile,
    SWAN_STYLE_KEY_MAP.toggleSwitch,
    SWAN_STYLE_KEY_MAP.utility,
    SWAN_STYLE_KEY_MAP.visible,
    SWAN_STYLE_KEY_MAP.zoomControls,
];

export function CustomSwanHead() {
    return <SwanHead swanBaseUrl={swanBaseUrl} styleSheetKeys={eagerlyLoadedStyles} />;
}
