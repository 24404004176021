import {
    BoundedContent,
    Card,
    FlexBox,
    SkeletonText,
    SkeletonTextHeading,
    SkeletonTextLine,
} from '@vp/swan';
import styled from 'styled-components';
import { HeroProps } from './Hero';

const StyledSkeletonTextContainer = styled(SkeletonText)`
    min-width: 50%;
`;

const StyledFlexBox = styled(FlexBox)`
    width: 100%;
`;

export function HeroSkeleton({ type = 'full' }: { type: HeroProps['type'] }) {
    return (
        <BoundedContent>
            <Card bordered p={'7'}>
                <StyledFlexBox justifyContent={'space-between'} gap="4">
                    <StyledSkeletonTextContainer>
                        <SkeletonTextHeading fontSize="x3large" />
                        <SkeletonTextLine />
                        <SkeletonTextLine />
                        <SkeletonTextLine />
                    </StyledSkeletonTextContainer>
                    {type === 'full' && (
                        <StyledSkeletonTextContainer>
                            <SkeletonTextLine fontSize="x4large" style={{ height: '400px' }} />
                        </StyledSkeletonTextContainer>
                    )}
                </StyledFlexBox>
            </Card>
        </BoundedContent>
    );
}
