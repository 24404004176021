import { forwardRef } from 'react';
import { createPortal } from 'react-dom';
import { Box, tokens, tokensRaw } from '@vp/swan';
import { AnimatePresence, MotionValue, motion } from 'framer-motion';
import styled from 'styled-components';

const Z_INDEX_DRAWER_OVERLAY = 1;
const Z_INDEX_DRAWER_CONTAINER = 2;

const MotionOverlayContainer = styled(motion.div)<{ $zIndex?: number }>`
    background-color: ${tokens.SwanSemColorBrandMidnight};
    opacity: 0.85;
    height: 100vh;
    left: 0;
    position: fixed;
    top: 0;
    width: 100vw;
    z-index: ${({ $zIndex }) =>
        $zIndex !== undefined
            ? $zIndex - 1
            : Z_INDEX_DRAWER_OVERLAY}; // Overlay should always directly be behind the drawer
`;

const MotionDrawerContainer = styled(motion.div)<{ $zIndex?: number; $open?: boolean }>`
    background-color: ${tokensRaw.SwanBaseColorWhite};
    border-radius: ${tokensRaw.SwanBaseBorderRadius400} ${tokensRaw.SwanBaseBorderRadius400} 0 0;
    bottom: 0;
    box-shadow: ${({ $open }) => ($open ? '0px -5px 5px 0px #00000008' : undefined)};
    height: auto;
    left: 0;
    overflow: hidden;
    position: fixed;
    width: 100%;
    z-index: ${({ $zIndex }) => $zIndex ?? Z_INDEX_DRAWER_CONTAINER};
`;

export const DrawerDragHandle = styled.button`
    appearance: none;
    background: none;
    border: none;
    cursor: grab;
    display: flex;
    justify-content: center;
    padding: ${tokensRaw.SwanBaseSpace150} ${tokensRaw.SwanBaseSpace200}
        ${tokensRaw.SwanBaseSpace050};
    touch-action: none;
    width: 100%;

    &:active {
        cursor: grabbing;
    }

    &:before {
        background-color: ${tokensRaw.SwanBaseColorGrey300};
        border-radius: ${tokensRaw.SwanSemBorderRadiusRounded};
        content: '';
        display: block;
        height: 4px;
        width: 40px;
    }
`;

export interface DrawerProps {
    children: React.ReactNode;
    open?: boolean;
    trigger?: React.ReactNode;
    foldLine?: React.RefObject<HTMLDivElement>;
    isDraggable?: boolean;
    asPageOverlay?: boolean;
    handleOpen?: () => void;
    handleClose?: () => void;
    handleDragging?: () => void;
    refresherDrawerHeight?: () => void;
    pin: {
        currentPin: number; // current position of the drawer
        ceiling: number; // the upper limit (top position) to which the drawer can be dragged
        floor: number; //  the lower limit (bottom position) to which the drawer can be dragged
        elasticTop: number; // the elasticity factor when dragging the drawer upwards - 0 meaning no elasticity
        elasticBottom: number; // the elasticity factor when dragging the drawer downwards - 0 meaning no elasticity
    };
    y: MotionValue<number>;
    zIndex?: number;
}

export const Drawer = forwardRef<HTMLDivElement, DrawerProps>(
    (
        {
            children,
            isDraggable,
            asPageOverlay,
            open,
            trigger,
            handleClose,
            handleDragging,
            pin,
            y,
            zIndex,
        },
        ref
    ) => {
        const portal = document.getElementById('drawer-app-portal') as HTMLElement;

        return createPortal(
            <Box>
                {/* eslint-disable-next-line react/jsx-no-useless-fragment */}
                {trigger && <>{trigger}</>}
                <AnimatePresence>
                    <>
                        {open && asPageOverlay && (
                            <MotionOverlayContainer
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 0.3 }}
                                exit={{ opacity: 0 }}
                                transition={{ ease: 'easeOut', duration: 0.15 }}
                                onMouseDown={() => {
                                    handleClose?.();
                                }}
                                $zIndex={zIndex}
                            />
                        )}
                        <MotionDrawerContainer
                            ref={ref}
                            onMouseDown={(e) => e.stopPropagation()}
                            initial={{ y: pin.currentPin }}
                            animate={{ y: pin.currentPin }}
                            transition={{ ease: 'easeOut', duration: 0.15 }}
                            style={{ y }}
                            dragMomentum={false}
                            $open={open}
                            $zIndex={zIndex}
                            {...(isDraggable && {
                                onDragEnd: handleDragging,
                                dragElastic: {
                                    top: pin.elasticTop,
                                    bottom: pin.elasticBottom,
                                },
                                drag: 'y',
                                dragConstraints: { top: pin.ceiling, bottom: pin.floor },
                            })}
                        >
                            {isDraggable && <DrawerDragHandle aria-label="Drag" />}
                            <Box
                                style={{
                                    // These values are required to allow scrolling for overflowing content in the drawer
                                    maxHeight: '80lvh',
                                    display: 'flex',
                                    flexDirection: 'column',
                                }}
                                role="dialog"
                                aria-hidden={!open} // Hide drawer content from screen readers when closed
                                tabIndex={open ? 0 : -1} // Make content focusable only when open
                            >
                                {children}
                            </Box>
                        </MotionDrawerContainer>
                    </>
                </AnimatePresence>
            </Box>,
            portal ?? document.body
        );
    }
);

Drawer.displayName = 'Drawer';
