export const Landscape = () => (
    <svg width="56" height="42" viewBox="0 0 56 42" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M35.1654 6.51518L55.0834 39.4829C55.4861 40.1494 55.0062 41 54.2275 41H1.82556C1.03566 41 0.557586 40.1273 0.982859 39.4616L16.1927 15.6549C16.5768 15.0537 17.4489 15.0365 17.8563 15.6222L23.0609 23.1036C23.4891 23.719 24.4171 23.6619 24.7665 22.9986L33.4248 6.56614C33.7875 5.87769 34.763 5.84913 35.1654 6.51518Z"
            fill="white"
            stroke="black"
        />
        <circle cx="16" cy="5" r="4" fill="white" stroke="black" />
    </svg>
);
