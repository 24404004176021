import { useEffect, useState } from 'react';
import {
    SelectionSet,
    SelectionSetInput,
    SelectionSetLabel,
    Typography,
    tokensRaw,
} from '@vp/swan';
import styled from 'styled-components';
import { __ } from '@99designs/i18n';
import { useBriefFormContext } from '../../../BriefContext/BriefFormContext';
import { RadioChoiceFieldProps } from './RadioChoiceField';
import { useUpdateRadioChoiceField } from './useUpdateRadioChoiceField';

const Label = styled.label`
    display: inline-block;
    padding-bottom: ${tokensRaw.SwanSemSpace2};
`;

export function SelectRadioChoiceField({
    id,
    register,
    required,
    setValue,
    choiceValue,
    options,
    immutable,
}: RadioChoiceFieldProps) {
    const DEFAULT_VALUE = options[0].value;
    const [selectedValue, setSelectedValue] = useState<string | null>(choiceValue || DEFAULT_VALUE);
    const disabled = !!immutable && choiceValue !== '';
    const { briefId } = useBriefFormContext();
    const updateMutation = useUpdateRadioChoiceField(id, briefId, selectedValue);

    useEffect(() => {
        setValue(id, selectedValue);

        if (selectedValue) {
            updateMutation(selectedValue);
        }
    }, [id, setValue, selectedValue, updateMutation]);

    return (
        <SelectionSet
            aria-label={id}
            variant="single-select"
            skin="simple-column"
            selectedValue={selectedValue}
            onSelectedValueChange={(newSelectedValue) => setSelectedValue(newSelectedValue)}
        >
            {options?.map(({ value, label, subtext }) => (
                <SelectionSetInput
                    key={value}
                    value={value}
                    {...register(id, {
                        required: required ? __('This field is required') : false,
                        disabled,
                    })}
                    disabled={disabled}
                >
                    <SelectionSetLabel>
                        <Label htmlFor={value}>
                            <Typography fontSize="standard" fontWeight="bold">
                                {label}
                            </Typography>
                        </Label>
                        <Typography fontSize="standard">{subtext}</Typography>
                    </SelectionSetLabel>
                </SelectionSetInput>
            ))}
        </SelectionSet>
    );
}
