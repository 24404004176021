import { FlexBox, tokens } from '@vp/swan';
import { Avatar, Box } from '@vp/swan';
import styled from 'styled-components';
import { VistaLogoString } from '../icons/VistaLogoString';

const radius = tokens.SwanSemSpace3;
const leftEdgeRadius = `${radius} ${radius} ${radius} 0`;
const rightEdgeRadius = `${radius} ${radius} 0 ${radius}`;
const leftEdgeMargin = `0 ${tokens.SwanSemSpace8} 0 0`;
const rightEdgeMargin = `0 0 0 ${tokens.SwanSemSpace8}`;

const StyledBubble = styled.div<{ color: string; alignmentDirection: string }>`
    background-color: ${({ color }) => color};
    border-radius: ${({ alignmentDirection }) =>
        alignmentDirection === 'left' ? leftEdgeRadius : rightEdgeRadius};
    padding: ${tokens.SwanSemSpace3} ${tokens.SwanSemSpace4};
    max-width: 600px;
    margin: ${({ alignmentDirection }) =>
        alignmentDirection === 'left' ? leftEdgeMargin : rightEdgeMargin};
    min-width: 200px;
    word-wrap: break-word;
`;

export interface CollaborationEventProps {
    children: React.ReactNode;
    color: string;
    showAvatar?: boolean;
    alignmentDirection?: 'left' | 'right';
}

export const CollaborationEvent: React.FC<CollaborationEventProps> = ({
    children,
    color,
    showAvatar = false,
    alignmentDirection = 'left',
}) => (
    <FlexBox
        alignItems={'end'}
        justifyContent={alignmentDirection === 'left' ? 'flex-start' : 'flex-end'}
        gap={3}
    >
        {showAvatar && (
            <Box>
                <Avatar alt="avatar" src={VistaLogoString} />
            </Box>
        )}
        <StyledBubble alignmentDirection={alignmentDirection} color={color}>
            {children}
        </StyledBubble>
    </FlexBox>
);
