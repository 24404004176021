import { AuthDevMode, AuthOptions } from '../../components/IdentityContext';
import { AUTH_INTERNAL_CLIENT_PARAM } from '../../consts';
import {
    createDirectAccessClient,
    createInternalAccessClient,
    createProductionProxyAccessClient,
} from './clients';

type AccessType = 'internal' | 'direct' | 'proxy';

export interface ClientOverride {
    developmentMode: AuthDevMode;
    options?: AuthOptions;
}

function getAccessTypeFromHost(hostname: string, directAccessRegexp: RegExp): AccessType {
    const shouldUseInternalAuthClient = new URLSearchParams(window.location.search).get(
        AUTH_INTERNAL_CLIENT_PARAM
    );
    if (shouldUseInternalAuthClient) {
        return 'internal';
    }

    const directAccessRegExp = hostname.includes('localhost')
        ? /^https?:\/\/localhost(:\d+)?/
        : directAccessRegexp;

    if (directAccessRegExp.test(window.location.origin)) {
        return 'direct';
    }

    return 'proxy';
}

export function buildAuthConfiguration(
    hostname: string,
    clientIDs: {
        internalAccess?: string;
        directAccess?: string;
    },
    directAccessRegexp = /^https?:\/\/(.+\.es\.vpsvc\.com)/
): ClientOverride {
    const accessType = getAccessTypeFromHost(hostname, directAccessRegexp);
    switch (accessType) {
        case 'internal':
            return createInternalAccessClient(clientIDs.internalAccess || '', 'oauth.cimpress.io');
        case 'direct':
            return createDirectAccessClient(clientIDs.directAccess || '', 'oauth.cimpress.io');
        case 'proxy':
            return createProductionProxyAccessClient();
    }
}

export function redirectToInternalLogin() {
    window.location.href = `/?${AUTH_INTERNAL_CLIENT_PARAM}=1`;
}
