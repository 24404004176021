import { useTracking } from '@vp/react-tracking';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Hidden, Icon, Spinner, Visible, useScreenClass } from '@vp/swan';
import { __ } from '@99designs/i18n';
import { useAlertContext, useCollaborationContext } from '../../context';
import { useDiscardRevisionRequestMutation } from '../../graphql/DiscardRevisionRequest.generated';

export const DiscardButton = ({
    revisionRequestId,
    width,
}: {
    revisionRequestId: number;
    width?: React.ComponentProps<typeof Button>['width'];
}) => {
    const { trackingProperties } = useCollaborationContext();
    const { tracking } = useTracking();
    const navigate = useNavigate();
    const [mutation, { loading }] = useDiscardRevisionRequestMutation();
    const { showError } = useAlertContext();
    const screenClass = useScreenClass();

    const back = async () => {
        try {
            const { errors, data } = await mutation({
                variables: {
                    revisionRequestId,
                },
            });
            if (errors != null || data?.discardRevisionRequest.success === false) {
                showError(__('Failed to discard revision request'));
                console.error(errors);
                return;
            } else {
                tracking.track('Collaboration Action Taken', {
                    type: 'Discard revision request',
                    ...trackingProperties,
                });
                navigate(`../`, { relative: 'path' });
            }
        } catch (error) {
            showError(__('Failed to discard revision request'));
            console.error(error);
        }
    };

    return (
        <Button
            onClick={back}
            disabled={loading}
            width={width}
            aria-label={__('Discard')}
            skin={screenClass === 'xs' ? 'tertiary' : undefined}
            buttonShape={screenClass === 'xs' ? 'round' : undefined}
        >
            {loading ? (
                <Spinner
                    size="tiny"
                    accessibleText={'Loading…'}
                    data-testid="upload-loading-spinner"
                    layout="vertical"
                    style={screenClass === 'xs' ? { marginRight: 0 } : undefined}
                />
            ) : (
                <Visible xs>
                    <Icon iconType="close" />
                </Visible>
            )}

            <Hidden xs>{__('Discard')}</Hidden>
        </Button>
    );
};
