import React from 'react';
import { Box, H3, Typography } from '@vp/swan';
import { __ } from '@99designs/i18n';

export interface TextSectionProps {
    heading: string;
    text: string;
}

export function TextSection(props: TextSectionProps) {
    return (
        <Box>
            <H3 fontSkin="title-subsection">{props.heading}</H3>
            <Typography fontSkin="body-standard" style={{ whiteSpace: 'pre-wrap' }}>
                {props.text}
            </Typography>
        </Box>
    );
}

export default TextSection;
