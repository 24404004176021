import React, { createContext, useContext } from 'react';
import { PageTracking } from '../tracking/types';

interface PageContextValue {
    pageData: Pick<PageTracking, 'pageName' | 'pageStage' | 'pagePath' | 'pageSection'>;
    locale: string;
}
interface PageContextProviderProps extends PageContextValue {
    children: React.ReactNode;
}

const context = createContext<PageContextValue>({
    pageData: { pageStage: 'Design', pageName: 'test', pagePath: '/path', pageSection: 'Section' },
    locale: 'en-US',
});

export function PageProvider(props: PageContextProviderProps) {
    const { children, pageData, locale } = props;

    return <context.Provider value={{ pageData, locale }}>{children}</context.Provider>;
}

export function usePage(): PageContextValue {
    const value = useContext(context);

    if (!value) {
        throw new Error('usePage must be used within a PageProvider');
    }

    return value;
}
