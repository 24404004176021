import { ClientOverride } from './buildAuthConfiguration';

/**
 * Used for logging in as internal uses in both local and production environments.
 */
export function createInternalAccessClient(clientID: string, domain?: string): ClientOverride {
    // Only set redirectUriOrigin on Vista-based domains; /auth-callback/ doesn't register when redirecting on localhost
    return {
        options: {
            connection: 'vistaprintcom-waad',
        },
        developmentMode: {
            clientID,
            domain,
            ...(process.env.NODE_ENV !== 'development' && {
                redirectUriOrigin:
                    typeof window !== 'undefined'
                        ? `${window.location.origin}/auth-callback/`
                        : undefined,
            }),
        },
    };
}

/**
 * Used for direct log in, e.g., at https://frontend.collaborate.es.vpsvc.com
 *
 * We need to use development mode so the auth callback is handled by the vp/auth package.
 */
export function createDirectAccessClient(clientID: string, domain?: string): ClientOverride {
    return {
        developmentMode: {
            clientID,
            domain,
        },
    };
}

export function createProductionProxyAccessClient(): ClientOverride {
    return {
        developmentMode: false,
    };
}
