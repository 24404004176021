import { useTracking } from '@vp/react-tracking';
import { FetchResult } from '@apollo/client';
import { tokensRaw } from '@vp/swan';
import { __ } from '@99designs/i18n';
import { useAlertContext } from '../../context';
import { usePreviewPanelDataQuery } from '../../graphql/PreviewPanelData.generated';
import { ImageRef, PinComment } from '../../ui';
import {
    RevisionRequestFragment,
    useGetRevisionRequestQuery,
} from '../RequestRevisionPanel/RequestRevision.generated';
import {
    useCreatePinCommentMutation,
    useDeleteCommentMutation,
    useEditCommentMutation,
} from './Comment.generated';

function useCommentErrorHandler() {
    const { showError } = useAlertContext();
    return (p: Promise<FetchResult>) =>
        p
            .then((result: FetchResult) => {
                if (result.errors != null) {
                    showError(__('Failed to save comment'));
                    console.error(result.errors);
                }
            })
            .catch((e) => {
                showError(__('Failed to save comment'));
                console.error(e);
            });
}

export function useCanvasProps(
    deliveryId: string,
    collabId: string,
    revisionRequestId: number | null,
    hasDelivery: boolean,
    trackingProperties: object,
    locale: string
) {
    const { tracking } = useTracking();
    const deliveryVersionId = Number(deliveryId);
    const revisionQueryResponse = useGetRevisionRequestQuery({
        variables: {
            collaborationPublicId: collabId,
            deliveryVersionId,
        },
        skip: revisionRequestId == null,
    });
    const previewPanelResponse = usePreviewPanelDataQuery({
        variables: {
            collaborationPublicId: collabId,
            deliveryVersionId,
            locale,
        },
        skip: !hasDelivery,
    });
    const [createPin] = useCreatePinCommentMutation();
    const [deletePin] = useDeleteCommentMutation();
    const [editPin] = useEditCommentMutation();
    const errorHandler = useCommentErrorHandler();

    const pins = formatPins(revisionQueryResponse.data?.revisionRequest.variationComments ?? []);

    const images =
        previewPanelResponse.data?.collaborationDelivery.variations.reduce(
            (carry, variation, index) => {
                carry[variation.id] = variation.surfaceViews.map((surfaceView): ImageRef => {
                    // Only add background color to images created by Cimpress' rendering service
                    const imageUrl = surfaceView.url.includes('rendering.mcp.cimpress.com')
                        ? `${surfaceView.url}&bgcolor=${tokensRaw.SwanBaseColorGrey100.replace(
                              /^#/,
                              ''
                          )}`
                        : surfaceView.url;

                    return {
                        href: imageUrl,
                        title: surfaceView.title,
                        workId: variation.workId,
                        label:
                            variation.workEntity == null
                                ? ''
                                : `${
                                      variation.workEntity.product.name ??
                                      variation.workEntity.workName
                                  } #${index + 1} • ${surfaceView.title}`,
                    };
                });
                return carry;
            },
            {} as { [key: number]: ImageRef[] }
        ) ?? {};

    const handleAddUpdatePinComment = async (pin: PinComment) => {
        if (revisionRequestId == null) {
            return;
        }
        if (pin.commentId) {
            await errorHandler(
                editPin({
                    variables: {
                        input: {
                            commentId: pin.commentId,
                            message: pin.commentText,
                        },
                    },
                })
            );
        } else {
            await errorHandler(
                createPin({
                    variables: {
                        input: {
                            deliveryVariationId: pin.deliveryVariationId,
                            revisionRequestId,
                            surfaceComment: {
                                surfaceIndexId: pin.selectedSurface,
                                surfaceX: Math.round(pin.xPercent),
                                surfaceY: Math.round(pin.yPercent),
                                message: pin.commentText,
                            },
                        },
                    },
                })
            );
            tracking.track('Collaboration Action Taken', {
                type: 'Saved pin comment',
                ...trackingProperties,
            });
        }

        await revisionQueryResponse.refetch();
    };

    const handleRemovePinComment = async (pin: PinComment) => {
        if (!pin.commentId) {
            return;
        }
        await deletePin({ variables: { commentId: pin.commentId } });
        await revisionQueryResponse.refetch();
    };

    const props = {
        pins,
        images,
        handleAddUpdatePinComment,
        handleRemovePinComment,
        hasRevisionRequest: previewPanelResponse.data?.collaborationDelivery.hasRevisionRequest,
    };

    return {
        loading: revisionQueryResponse?.loading || previewPanelResponse?.loading,
        errors: [
            ...(revisionQueryResponse.error ? [revisionQueryResponse.error] : []),
            ...(previewPanelResponse.error ? [previewPanelResponse.error] : []),
        ],
        props,
    };
}

export function formatPins(variationComments: RevisionRequestFragment['variationComments']) {
    return (
        variationComments
            .map(
                (pin, i): PinComment => ({
                    commentText: pin.comment.message,
                    viewIndex: i + 1,
                    xPercent: pin.comment.surfaceX,
                    yPercent: pin.comment.surfaceY,
                    selectedSurface: pin.comment.surfaceIndexId,
                    commentId: pin.commentId,
                    deliveryVariationId: pin.deliveryVariationId,
                })
            ) // Sort by comment ID to get deterministic output from map
            .sort((a, b) => {
                return (Number(a.commentId) ?? 0) - (Number(b.commentId) ?? 0);
            }) ?? []
    );
}
