import React, { useEffect, useRef } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { VisuallyHidden, tokens, useScreenClass } from '@vp/swan';
import styled from 'styled-components';
import {
    Drawer,
    DrawerContent,
    DrawerHeader,
    useDefaultDrawerProps,
} from '@99designs/design-services-layouts';
import { __ } from '@99designs/i18n';
import { COLLABORATE_BASE_PATH } from './constants';

const Container = styled.div`
    display: flex;
    flex-direction: row;
    height: 100%;
    width: 100%;
`;

const PanelLeft = styled.div`
    border-right: 1px solid ${tokens.SwanSemColorBorderStandard};
    flex: 1;
    max-width: 560px;
    min-width: 400px;
    overflow-y: auto;
`;

const PanelRight = styled.div`
    flex: 1.618; // Golden ratio between min and max width values
    overflow-y: auto;
    height: 100%;
`;

export interface ResponsiveDrawerLayoutProps {
    left: React.ReactNode;
    right: React.ReactNode;
    hidePageOverlay?: boolean;
    scrollPositionAtBottom?: boolean;
}

export const ResponsiveDrawerLayout: React.FC<ResponsiveDrawerLayoutProps> = ({
    left,
    right,
    hidePageOverlay,
    scrollPositionAtBottom,
}) => {
    const screenClass = useScreenClass();

    const navigate = useNavigate();
    const { collaborationId, deliveryVersionId } = useParams<{
        collaborationId: string;
        deliveryVersionId: string;
    }>();

    const deliveryVersionIdForRouting = deliveryVersionId || '1';
    const handleClose = () => {
        navigate(
            `${COLLABORATE_BASE_PATH}/${collaborationId}/delivery/${deliveryVersionIdForRouting}`
        );
    };

    const drawerRef = useRef<HTMLDivElement>(null);
    const drawer = useDefaultDrawerProps(drawerRef, {
        initialOpenState: true,
        onCloseCallback: () => {
            handleClose();
        },
    });

    const location = useLocation();

    // Each time the drawer closes, the route changes to the delivery route. If we want to stay on the same route when the drawer closes, we'll need to attach a trigger to the bottom bar to re-open the drawer.
    useEffect(() => {
        if (drawerRef.current) {
            // Open the drawer on route change
            drawer.handleOpen?.();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location.pathname]); // Trigger effect when pathname changes

    return (
        <Container>
            {screenClass === 'xs' ? (
                <>
                    {hidePageOverlay ? (
                        // Having this component visually hidden means that any logic from the Outlet component will still be fired (like page title and tracking), but will not render a drawer
                        <VisuallyHidden>{left}</VisuallyHidden>
                    ) : (
                        <Drawer {...drawer} ref={drawerRef} isDraggable asPageOverlay zIndex={5000}>
                            <DrawerHeader onClickBack={handleClose} />
                            <DrawerContent
                                padding="0"
                                style={{ overflowY: 'auto' }}
                                scrollPositionAtBottom={scrollPositionAtBottom}
                            >
                                {left}
                            </DrawerContent>
                        </Drawer>
                    )}
                    <Container>{right}</Container>
                </>
            ) : (
                <>
                    <PanelLeft>{left}</PanelLeft>
                    <PanelRight>{right}</PanelRight>
                </>
            )}
        </Container>
    );
};
