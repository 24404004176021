import { BoundedContent, Box, Link, Typography } from '@vp/swan';
import { __ } from '@99designs/i18n';
import Ghost from './Ghost.png';

export const NotFound: React.FC = () => {
    return (
        <BoundedContent>
            <Box marginTop={10} marginBottom={'7'} textAlign="center">
                <img alt="" src={Ghost} width={128} height={128} />
                <Box marginTop={8}>
                    <Typography fontSize="x2large" fontWeight="bold">
                        {__("Boo. That page didn't turn up - but we're here to help.")}
                    </Typography>
                </Box>
                <Box marginTop={8} marginBottom={10}>
                    <Link href="/">{__('Get back to our homepage')}</Link>
                </Box>
            </Box>
        </BoundedContent>
    );
};
