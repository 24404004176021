import React, { ReactNode, createContext, useContext } from 'react';

interface BriefFormContextProps {
    briefId?: string;
}

const BriefFormContext = createContext<BriefFormContextProps>({});

interface BriefFormProviderProps {
    children: ReactNode;
    briefId?: string;
}

export const BriefFormProvider: React.FC<BriefFormProviderProps> = ({ children, briefId }) => {
    return <BriefFormContext.Provider value={{ briefId }}>{children}</BriefFormContext.Provider>;
};

export const useBriefFormContext = () => {
    return useContext(BriefFormContext);
};
