import React from 'react';
import { Card, FlexBox, Typography } from '@vp/swan';
import { __ } from '@99designs/i18n';
import { Pins } from '../icons/Pins';

export const PinCommentsEmptyState: React.FC = () => {
    return (
        <Card paddingX="6" paddingY="9" backgroundColor="strong" bordered>
            <FlexBox flexDirection="column" alignItems="center" gap="4">
                <Pins />
                <Typography fontSkin="body-small" textAlign="center">
                    {__(
                        'Click anywhere on the design to leave a comment. Be specific with your feedback. The more detail you provide the better.'
                    )}
                </Typography>
            </FlexBox>
        </Card>
    );
};
