import { Button, Typography } from '@vp/swan';
import styled from 'styled-components';
import { __ } from '@99designs/i18n';

export const StatusCircle = styled.span<{ color: string }>`
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: ${(props) => props.color};
    display: inline-block;
`;

export interface StatusProps {
    color: string;
    label: string;
}

export const Status = ({ color, label }: StatusProps) => {
    return (
        <Button skin="tertiary" fontWeight="normal">
            <StatusCircle color={color} />
            <Typography marginLeft={'4'}>{label}</Typography>
        </Button>
    );
};
