import React from 'react';
import { Divider, Section } from '@vp/swan';

interface DrawerHeaderProps {
    children: React.ReactNode;
}

export const DrawerFooter: React.FC<DrawerHeaderProps> = ({ children, ...props }) => {
    return (
        <>
            <Divider />
            <Section padding="5" {...props}>
                {children}
            </Section>
        </>
    );
};
