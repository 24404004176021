import { useRef } from 'react';
import { Button, Icon, VisuallyHidden, tokensRaw } from '@vp/swan';
import styled from 'styled-components';
import { getVariantUrl } from '@99designs/design-services-common';
import { useDefaultDrawerProps } from '@99designs/design-services-layouts';
import { CollaborationFile } from '@99designs/graph-utils/types';
import { __ } from '@99designs/i18n';
import { FileDeleteConfirmationDrawer } from './DeleteConfirmationDrawer';
import { FilePreviewDrawer } from './PreviewDrawer';

const ImagePreviewContainer = styled.div`
    align-items: center;
    background-color: ${tokensRaw.SwanBaseColorGrey100};
    border-radius: ${tokensRaw.SwanBaseBorderRadius100};
    display: flex;
    height: 48px;
    justify-content: center;
    overflow: hidden;
    width: 48px;

    img {
        object-fit: contain;
    }
`;

export interface FileWithDrawerProps {
    file: CollaborationFile;
    removeUploadedFile?: (file: CollaborationFile) => void;
    showDownload?: boolean;
    showDelete?: boolean;
}

export function FileWithDrawer({
    file,
    showDownload,
    showDelete,
    removeUploadedFile,
}: FileWithDrawerProps) {
    const isPreviewable = !file.name.toLowerCase().endsWith('.pdf');

    const uploadedFileDrawerRef = useRef<HTMLDivElement>(null);
    const uploadedFileDrawer = useDefaultDrawerProps(uploadedFileDrawerRef, {
        initialOpenState: false,
    });

    const deleteConfirmationDrawerRef = useRef<HTMLDivElement>(null);
    const deleteConfirmationDrawer = useDefaultDrawerProps(deleteConfirmationDrawerRef, {
        initialOpenState: false,
    });

    return (
        <div>
            <Button
                skin="unstyled"
                onClick={() => uploadedFileDrawer.handleOpen?.()}
                padding="0"
                style={{ width: '100%', height: '100%' }}
            >
                <ImagePreviewContainer>
                    {isPreviewable ? (
                        <img
                            src={getVariantUrl(file.externalUploadId, 'preview')}
                            height={'100%'}
                            width={'100%'}
                            alt={file.name}
                            style={{ objectFit: 'contain', borderRadius: tokensRaw.SwanSemSpace3 }}
                        />
                    ) : (
                        <>
                            <Icon iconType="fileGeneric" size="20p" />
                            <VisuallyHidden>{file.name}</VisuallyHidden>
                        </>
                    )}
                </ImagePreviewContainer>
            </Button>

            <FilePreviewDrawer
                ref={uploadedFileDrawerRef}
                file={file}
                onDelete={() => deleteConfirmationDrawer.handleOpen?.()}
                drawerProps={uploadedFileDrawer}
                isPreviewable={isPreviewable}
                showDownload={showDownload}
                showDelete={showDelete}
            />

            {removeUploadedFile && showDelete && (
                <FileDeleteConfirmationDrawer
                    ref={deleteConfirmationDrawerRef}
                    file={file}
                    drawerProps={deleteConfirmationDrawer}
                    removeUploadedFile={removeUploadedFile}
                />
            )}
        </div>
    );
}
