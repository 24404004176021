import { createViewContext } from '../ViewContext';

type View =
    | { view: 'editGeneralComment' }
    | { view: 'viewGeneralComment' }
    | { view: 'editRevision' }
    | { view: 'viewRevision' }
    | {
          view: 'editPinnedComment';
          data?: {
              comment: string;
              onUpdate: (comment?: string) => void;
              onClose: () => void;
          };
      }
    | {
          view: 'viewPinnedComment';
          data?: {
              title: string;
              comment: string;
              interactable: boolean;
              onEdit: () => void;
              onDelete: () => void;
              onBack: () => void;
          };
      };

export const { ViewProvider, useViewNavigation } = createViewContext<View>();
