import { UseFormRegister, UseFormSetValue } from 'react-hook-form';
import { Typography } from '@vp/swan';
import { useFieldViewSkinContext } from '../../../../context/FieldViewContext';
import { Field_RadioChoiceField_Fragment } from '../../brief.generated';
import { SelectRadioChoiceField } from './SelectRadioChoiceField';
import { ToggleRadioChoiceField } from './ToggleRadioChoiceField';

export type RadioChoiceFieldProps = Field_RadioChoiceField_Fragment & {
    register: UseFormRegister<any>;
    setValue: UseFormSetValue<any>;
};

export function Input(props: RadioChoiceFieldProps) {
    if (props.view === 'RADIO_CHOICE_FIELD_VIEW_SELECT') {
        return <SelectRadioChoiceField {...props} />;
    }

    if (props.view === 'RADIO_CHOICE_FIELD_VIEW_TOGGLE') {
        return <ToggleRadioChoiceField {...props} />;
    }

    throw new Error('Unknown view type');
}

interface RadioChoiceFieldViewProps {
    options: Field_RadioChoiceField_Fragment['options'];
    choiceValue: Field_RadioChoiceField_Fragment['choiceValue'];
}

export function View({ options, choiceValue }: RadioChoiceFieldViewProps) {
    const skin = useFieldViewSkinContext();

    const option = options.find((o) => o.value === choiceValue);
    if (!option) return null;

    return <Typography fontSize={skin.fontSize}>{option.label}</Typography>;
}
