import React from 'react';
import {
    Button,
    Icon,
    IconProps,
    Menu,
    MenuItem,
    MenuList,
    MenuPopover,
    MenuTrigger,
} from '@vp/swan';

export type LinkMenuProps = {
    label: string;
    links: {
        label: string;
        path: string;
        iconType?: IconProps['iconType'];
    }[];
    onChange?: (value: string) => void;
};

export const LinkMenu: React.FC<LinkMenuProps> = ({ label, links, onChange }) => {
    return (
        <Menu
            onChange={(e: string | number) => {
                if (onChange) {
                    onChange(e.toString());
                }
                window.location.pathname = e.toString();
            }}
        >
            <MenuTrigger>
                <Button aria-label={label} skin="tertiary">
                    {label}
                    <Icon iconType="caretDown" />
                </Button>
            </MenuTrigger>
            <MenuPopover>
                <MenuList aria-label={label}>
                    {links.map((link) => (
                        <MenuItem key={link.path}>{link.label}</MenuItem>
                    ))}
                </MenuList>
            </MenuPopover>
        </Menu>
    );
};
