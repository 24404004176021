/* eslint-disable */

import * as Apollo from '@apollo/client';
import { DocumentNode } from 'graphql';
import * as Types from '@99designs/graph-utils/types';

export type WorkEntityFieldQueryVariables = Types.Exact<{
    workId: Types.Scalars['String'];
}>;

export type WorkEntityFieldQuery = {
    __typename: 'Query';
    workEntityViews: {
        __typename: 'GetAllViewsResponse';
        views: Array<{
            __typename: 'View';
            height: Types.Maybe<number>;
            width: number;
            name: string;
            sceneType: string;
            _links: {
                __typename: 'ViewLink';
                image: { __typename: 'ViewLinkImage'; href: string; title: string };
            };
        }>;
    };
};

export const WorkEntityFieldDocument: DocumentNode = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'WorkEntityField' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'workId' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'workEntityViews' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'workId' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'workId' },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'views' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'height' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'width' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'name' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'sceneType' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: '_links' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'image' },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'href',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'title',
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
};

/**
 * __useWorkEntityFieldQuery__
 *
 * To run a query within a React component, call `useWorkEntityFieldQuery` and pass it any options that fit your needs.
 * When your component renders, `useWorkEntityFieldQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWorkEntityFieldQuery({
 *   variables: {
 *      workId: // value for 'workId'
 *   },
 * });
 */
export function useWorkEntityFieldQuery(
    baseOptions: Apollo.QueryHookOptions<WorkEntityFieldQuery, WorkEntityFieldQueryVariables>
) {
    return Apollo.useQuery<WorkEntityFieldQuery, WorkEntityFieldQueryVariables>(
        WorkEntityFieldDocument,
        baseOptions
    );
}
export function useWorkEntityFieldLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<WorkEntityFieldQuery, WorkEntityFieldQueryVariables>
) {
    return Apollo.useLazyQuery<WorkEntityFieldQuery, WorkEntityFieldQueryVariables>(
        WorkEntityFieldDocument,
        baseOptions
    );
}
export type WorkEntityFieldQueryHookResult = ReturnType<typeof useWorkEntityFieldQuery>;
export type WorkEntityFieldLazyQueryHookResult = ReturnType<typeof useWorkEntityFieldLazyQuery>;
export type WorkEntityFieldQueryResult = Apollo.QueryResult<
    WorkEntityFieldQuery,
    WorkEntityFieldQueryVariables
>;
