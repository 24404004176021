import { useEffect } from 'react';
import { UseFormRegister, UseFormSetValue } from 'react-hook-form';
import { Box, List, TextInput, Typography } from '@vp/swan';
import { __ } from '@99designs/i18n';
import { useFieldViewSkinContext } from '../../../context/FieldViewContext';
import { SubText } from '../SubText';
import { Field_MultiInputField_Fragment } from '../brief.generated';

export type MultiInputFieldProps = Field_MultiInputField_Fragment & {
    numberOfInputs?: number;
    register: UseFormRegister<any>;
    setValue: UseFormSetValue<any>;
};

export function Input({
    id,
    required,
    placeholder,
    multiInputValue,
    register,
    numberOfInputs = 3,
    setValue,
    subtext,
}: MultiInputFieldProps) {
    useEffect(() => {
        // react-hook-form will set the default value as empty strings which ends up populating
        // the db with empty strings. Although this is valid it is unnecessary.
        if (multiInputValue.length) {
            multiInputValue.forEach((v, i) => setValue(`${id}.${i}`, v));
        } else {
            setValue(id, []);
        }
    }, [setValue, multiInputValue, id]);

    if (required) {
        // There are no obvious solutions on how to handle required fields with react-hook-form
        // for field sets. This is a temporary solution since there are no required fields
        // for the logo-essential product.
        throw new Error('MultiInputField does not support required fields');
    }

    return (
        <div role="group">
            {[...Array(numberOfInputs).keys()].map((i) => {
                const isLastInput = i === numberOfInputs - 1;
                return (
                    <Box key={`${id}-${i}`} marginBottom={isLastInput ? 0 : '6'}>
                        <TextInput
                            {...(multiInputValue && multiInputValue[i]
                                ? { defaultValue: multiInputValue[i] }
                                : {})}
                            placeholder={placeholder ?? ''}
                            {...register(`${id}.${i}`)}
                        />
                    </Box>
                );
            })}
            {subtext && <SubText>{subtext}</SubText>}
        </div>
    );
}

type MultiInputFieldViewProps = {
    multiInputValue: Field_MultiInputField_Fragment['multiInputValue'];
};

export function View({ multiInputValue: urls }: MultiInputFieldViewProps) {
    const skin = useFieldViewSkinContext();

    return (
        <Typography fontSize={skin.fontSize}>
            <List>
                {urls.map((url) => (
                    <li key={url}>{url}</li>
                ))}
            </List>
        </Typography>
    );
}
