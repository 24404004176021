import memoizeIntlConstructor from 'intl-format-cache';
import IntlMessageFormat from 'intl-messageformat';

// Creating instances of Intl formats is an expensive operation.
const createMessageFormatter = memoizeIntlConstructor(IntlMessageFormat);

export type Values = { [key: string]: number | string };

// Format a message replacing ICU placeholders with their values.
export default function formatMessage(message: string, values: Values = {}) {
    const msg = createMessageFormatter(message, IntlMessageFormat.defaultLocale);
    return msg.format(values);
}
