import React from 'react';
import { Button, FlexBox, Icon, TextArea, tokensRaw } from '@vp/swan';
import { DrawerContent, DrawerFooter } from '@99designs/design-services-layouts';
import { __ } from '@99designs/i18n';
import { useViewNavigation } from '../DrawerRevisionRequestViewContext';

export interface EditCommentProps {
    comment: string | undefined;
    onUpdate: (comment?: string) => void;
    placeholderText: string;
    onClose?: () => void;
}

export const EditComment: React.FC<EditCommentProps> = ({
    comment,
    onUpdate,
    placeholderText,
    onClose,
}) => {
    const [commentValue, setCommentValue] = React.useState<string | undefined>(comment);
    const { goBack } = useViewNavigation();

    return (
        <>
            <DrawerContent padding="5">
                <TextArea
                    value={commentValue}
                    data-testid="edit-comment"
                    onChange={(event) => setCommentValue(event.target.value)}
                    placeholder={placeholderText}
                    autoFocus={true}
                    rows={4}
                    fullWidth
                    resize="vertical"
                    style={{
                        fontSize: tokensRaw.SwanBaseFontSize100, // using raw token here as we need this font size to always be 16px or greater as mobile will zoom in on input if the text is too small for accessibility reasons.
                        maxHeight: '30lvh',
                    }}
                />
            </DrawerContent>

            <DrawerFooter>
                <FlexBox justifyContent="flex-end" gap="3">
                    <Button
                        onClick={() => {
                            goBack();
                            setCommentValue(comment);
                            onClose?.();
                        }}
                        aria-label={__('Discard')}
                        skin="tertiary"
                    >
                        <Icon iconType="close" />
                    </Button>
                    <Button
                        onClick={() => {
                            goBack();
                            if (commentValue !== comment) {
                                onUpdate(commentValue);
                            }
                            onClose?.();
                        }}
                        skin="primary"
                    >
                        {__('Save')}
                    </Button>
                </FlexBox>
            </DrawerFooter>
        </>
    );
};
