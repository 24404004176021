import { useParams } from 'react-router-dom';

export function useDeliveryVariationRouteParams(): {
    deliveryVersionId: number;
    collaborationId: string;
} {
    const { deliveryVersionId, collaborationId } = useParams();
    if (!deliveryVersionId || !collaborationId) {
        throw new Error('No delivery version id provided');
    }

    return {
        deliveryVersionId: Number(deliveryVersionId),
        collaborationId,
    };
}
