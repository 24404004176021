import Bugsnag from '@bugsnag/js';
import { getCurrentCulture as vpGetCurrentCulture } from '@vp/culture-resolution';

export function getCurrentCulture() {
    try {
        return vpGetCurrentCulture();
    } catch (e) {
        Bugsnag.notify('Failed to get current culture');
        return 'en-US';
    }
}
