import React, { PropsWithChildren } from 'react';
import {
    Box,
    Button,
    Column,
    FlexBox,
    FluidImage,
    GridContainer,
    H1,
    Row,
    Typography,
    responsive,
} from '@vp/swan';
import { Maybe } from '@99designs/graph-utils/types';
import { __ } from '@99designs/i18n';
import { useStepContext } from '@99designs/swan-wizard';
import { StepProductCard } from './StepProductCard';
import { BriefProductFragment } from './brief.generated';
import Intro from './intro.png';

const ResponsiveFlex = responsive(FlexBox);

export const IntroStep = ({ product }: { product: Maybe<BriefProductFragment> }) => {
    const { next } = useStepContext();
    return (
        <GridContainer>
            <Row
                style={{
                    height: '88vh',
                    display: 'flex',
                    alignItems: 'center',
                }}
            >
                <IntroColumn isOffset>
                    <ResponsiveFlex
                        alignItems="center"
                        justifyContent="center"
                        md={{
                            justifyContent: 'flex-end',
                            alignItems: 'center',
                        }}
                        lg={{
                            justifyContent: 'flex-end',
                            alignItems: 'center',
                        }}
                        xl={{
                            justifyContent: 'flex-end',
                            alignItems: 'center',
                        }}
                    >
                        <FluidImage
                            src={typeof Intro === 'string' ? Intro : (Intro as { src: string }).src}
                            alt="Intro layout"
                            paddingRight={8}
                            style={{
                                maxWidth: '500px',
                            }}
                        />
                    </ResponsiveFlex>
                </IntroColumn>
                <IntroColumn>
                    <ResponsiveFlex
                        justifyContent="center"
                        sm={{
                            justifyContent: 'center',
                        }}
                        md={{
                            justifyContent: 'flex-start',
                        }}
                        lg={{
                            justifyContent: 'flex-start',
                        }}
                        xl={{
                            justifyContent: 'flex-start',
                        }}
                    >
                        <Box
                            mb={4}
                            px={2}
                            textAlign={{ xs: 'center', md: 'left' }}
                            style={{ maxWidth: '500px' }}
                        >
                            <H1 mb={5}>{__('Tell us about your design project')}</H1>
                            <Typography mb={6}>
                                {__(
                                    'Answer a few short questions to help us design the perfect solution'
                                )}
                            </Typography>
                            <Box textAlign={'left'}>
                                {product && <StepProductCard product={product} />}
                            </Box>
                            <Button mt={6} width="full-width" skin="primary" onClick={next}>
                                {__('Get started')}
                            </Button>
                        </Box>
                    </ResponsiveFlex>
                </IntroColumn>
            </Row>
        </GridContainer>
    );
};

function IntroColumn({ children, isOffset }: PropsWithChildren & { isOffset?: boolean }) {
    return (
        <Column
            span={12}
            spanSm={12}
            spanMd={6}
            spanLg={4}
            spanXl={4}
            offset={0}
            offsetLg={isOffset ? 2 : null}
            offsetXl={isOffset ? 2 : null}
        >
            {children}
        </Column>
    );
}
