import { useEffect, useState } from 'react';
import { UseFormRegister, UseFormSetValue } from 'react-hook-form';
import { Dropdown, DropdownOption, Typography } from '@vp/swan';
import { __ } from '@99designs/i18n';
import { useFieldViewSkinContext } from '../../../../context/FieldViewContext';
import { useBriefFormContext } from '../../../BriefContext/BriefFormContext';
import { FormInput } from '../../BriefForm';
import { SubText } from '../../SubText';
import { Field_SelectChoiceField_Fragment } from '../../brief.generated';
import { useUpdateSelectChoiceField } from './useUpdateSelectChoiceField';

export type SelectFieldFieldProps = Field_SelectChoiceField_Fragment & {
    register: UseFormRegister<FormInput>;
    setValue: UseFormSetValue<FormInput>;
};

export function Input({
    id,
    register,
    required,
    placeholder,
    options,
    choiceValue,
    subtext,
    setValue,
}: SelectFieldFieldProps) {
    const DEFAULT_VALUE = placeholder ? '' : options[0].value;
    const [selectedValue, setSelectedValue] = useState<string>(choiceValue || DEFAULT_VALUE);
    const { briefId } = useBriefFormContext();
    const updateMutation = useUpdateSelectChoiceField(id, briefId, choiceValue);

    useEffect(() => {
        setValue(id, selectedValue);
        if (selectedValue) {
            updateMutation(selectedValue);
        }
    }, [id, setValue, selectedValue, updateMutation]);

    return (
        <>
            <Dropdown
                defaultValue={selectedValue}
                {...register(id, {
                    required: required ? __('This field is required') : false,
                    onChange: (e) => setSelectedValue(e.target.value),
                })}
                fullWidth
            >
                <DropdownOption key={`${id}-empty`} value="" hidden>
                    {placeholder || ''}
                </DropdownOption>
                {options.map((i) => {
                    return (
                        <DropdownOption key={i.value} value={i.value}>
                            {i.label}
                        </DropdownOption>
                    );
                })}
            </Dropdown>
            {subtext && <SubText>{subtext}</SubText>}
        </>
    );
}

interface SelectFieldViewProps {
    options: Field_SelectChoiceField_Fragment['options'];
    choiceValue: Field_SelectChoiceField_Fragment['choiceValue'];
}

export function View({ options, choiceValue }: SelectFieldViewProps) {
    const skin = useFieldViewSkinContext();

    const option = options.find((o) => o.value === choiceValue);
    if (!option) return null;

    return <Typography fontSize={skin.fontSize}>{option.label}</Typography>;
}
